/* CSS Document */


/* 
    Created on   : 27/07/2022.
    Theme Name   : Jano - Creative Multipurpose Bootstrap 5 Template
    Version      : 1.0.
    Developed by : (me@heloshape.com) / (www.me.heloshape.com)
   
*/


/**
* Importing necessary  Styles.
**/


/* ----- Fonts ---- */

@import url('../fonts/recoleta/stylesheet.css');
@import url('../fonts/gordita/stylesheet.css');
@import url('../fonts/eustache/stylesheet.css');
@import url('../fonts/noteworthy/style.css');

/*----Bootstrap css ----- */

@import url('../vendor/bootstrap/css/bootstrap.min.css');

/*---- Navbar Css------*/

@import url('navbar.css');

/*----Framework css-----*/

@import url('framework.css');

/*----- Bootstrap Icons ----------*/

@import url('../fonts/bootstrap-icons/font-css.css');

/*---- Font Awesome Icons -------*/

@import url('../fonts/font-awesome/css/all.min.css');

/*----------- Fancybox css -------*/

@import url('../vendor/fancybox/dist/jquery.fancybox.min.css');

/*----------- Slick Slider -------*/

@import url('../vendor/slick/slick.css');

/*----Animate css----*/

@import url('../vendor/wow/animate.css');
@import url('custom-animation.css');

/*----Nice Select----*/

@import url('../vendor/nice-select/nice-select.css');

/*========================================================================================

*************************** Start Styling Your theme from here ***************************

==========================================================================================*/


/*** 
====================================================================
  Theme Color and Root Prefix
====================================================================

 ***/

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #fff;
}

::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 0;
}

:root {
    --text-color: #48494E;
    --heading: #090F32;
    --prime-one: #FF8C24;
    --prime-two: #FF733B;
    --prime-three: #6A45FF;
    --prime-four: #FFCD45;
    --prime-five: #007BFF;
    --prime-six: #FFD338;
    --prime-seven: linear-gradient(-90deg, #FF7DF2 0%, #FF275B 100%);
    --prime-eight: #FFED4E;
    --prime-nine: #FF1292;
    --prime-ten: #FF3294;
    --prime-eleven: #FFCE4C;
    --prime-twelve: #FFE500;
}

::selection {
    background-color: #2a2a2a;
    color: #fff;
}


/*** 
====================================================================
  Loading Transition
====================================================================

 ***/

.ctn-preloader {
    align-items: center;
    -webkit-align-items: center;
    display: flex;
    display: -ms-flexbox;
    height: 100%;
    justify-content: center;
    -webkit-justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999999;
    background: #fff;
}

.ctn-preloader .animation-preloader {
    position: absolute;
    z-index: 100;
    text-align: center;
}

.ctn-preloader .animation-preloader .icon {
    display: inline-block;
    position: relative;
}

.ctn-preloader .animation-preloader .icon img {
    animation: rotated 12s infinite linear;
}

.ctn-preloader .animation-preloader .txt-loading {
    user-select: none;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:before {
    animation: letters-loading 4s infinite;
    color: var(--prime-ten);
    content: attr(data-text-preloader);
    left: 0;
    opacity: 0;
    top: 0;
    line-height: 30px;
    position: absolute;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading {
    font-family: 'Recoleta';
    font-weight: 700;
    letter-spacing: 12px;
    display: inline-block;
    color: rgb(255 50 148 / 20%);
    position: relative;
    font-size: 35px;
    line-height: 30px;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(2):before {
    animation-delay: 0.2s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(3):before {
    animation-delay: 0.4s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(4):before {
    animation-delay: 0.6s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(5):before {
    animation-delay: 0.8s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(6):before {
    animation-delay: 1s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(7):before {
    animation-delay: 1.2s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(8):before {
    animation-delay: 1.4s;
}

.loaded .animation-preloader {
    opacity: 0;
    transition: 0.3s ease-out;
}

@keyframes spinner {
    to {
        transform: rotateZ(360deg);
    }
}

@keyframes letters-loading {
    0%,
    75%,
    100% {
        opacity: 0;
        transform: rotateY(-90deg);
    }
    25%,
    50% {
        opacity: 1;
        transform: rotateY(0deg);
    }
}

@media screen and (max-width: 500px) {
    .ctn-preloader .animation-preloader .txt-loading .letters-loading {
        font-size: 40px;
        letter-spacing: 10px;
    }
}


/*^^^^^^^^^^^^^^^^^^^^ Global Prefix ^^^^^^^^^^^^^^^^^^^^^*/

.main-page-wrapper {
    overflow-x: hidden;
}

body {
    font-family: 'gordita';
    font-weight: normal;
    font-size: 16px;
    position: relative;
    color: var(--text-color);
    background-color: #fff;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
    color: var(--heading);
}

.h1,
h1 {
    font-size: 80px;
    line-height: 1.18em;
    /*done*/
}

.h2,
h2 {
    font-size: 58px;
    line-height: 1.29em;
    letter-spacing: -1px;
    /*done*/
}

.h3,
h3 {
    font-size: 32px;
    /*done*/
}

.h4,
h4 {
    font-size: 24px;
    line-height: 1.54em;
    /*done*/
}

.h5,
h5 {
    font-size: 22px;
    line-height: 1.45em;
    /*done*/
}

.h6,
h6 {
    font-size: 18px;
    /*done*/
}

p {
    line-height: 1.87em;
}

::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
}

:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
}

::placeholder {
    color: rgba(0, 0, 0, 0.6);
}


/*^^^^^^^^^^^^^^^^^^^^ Prefix Classes ^^^^^^^^^^^^^^^^^^^^^*/

.tran3s,
.svg path {
    transition: all 0.3s ease-in-out;
}

.tran4s {
    transition: all 0.4s ease-in-out;
}

.tran5s {
    transition: all 0.5s ease-in-out;
}

.tran6s {
    transition: all 0.6s ease-in-out;
}

.fs-13 {
    font-size: 13px;
}

.fs-14 {
    font-size: 14px;
}

.fs-15 {
    font-size: 15px;
}

.fs-16 {
    font-size: 16px;
}

.fs-17 {
    font-size: 17px;
}

.fs-18 {
    font-size: 18px;
}

.fs-20 {
    font-size: 20px;
}

.fw-500 {
    font-weight: 500;
}

.tx-dark {
    color: #000;
}

.p-30 {
    padding: 30px;
}

.font-recoleta {
    font-family: 'Recoleta';
}

.text-lg {
    font-size: 24px;
    line-height: 1.67em;
}

.style-none {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.shapes {
    position: absolute;
    z-index: -1;
}

.border-top {
    border-top: 1px solid #f1f1f1 !important;
}

.border-bottom {
    border-bottom: 1px solid #f1f1f1 !important;
}

.zn2 {
    z-index: 2;
}

#contact-form .input-group-meta {
    position: relative;
}

#contact-form .form-group .help-block {
    position: absolute;
    left: 0;
    bottom: -17px;
    font-size: 13px;
    line-height: 20px;
    color: #ff4d44;
}

#contact-form .form-group .help-block ul {
    margin-bottom: 0;
}

.pattern-bg-one {
    background-color: #FFEFE3;
    background-image: url(../images/assets/bg-6.png);
    background-repeat: repeat;
    background-position: center;
}

.dark-bg-one {
    background: #151937;
}


/*^^^^^^^^^^^^^^^^^^^^ Scroll Top ^^^^^^^^^^^^^^^^^^^^^*/

.scroll-top {
    width: 35px;
    height: 35px;
    line-height: 35px;
    position: fixed;
    bottom: 20px;
    right: 5px;
    z-index: 99;
    text-align: center;
    color: #fff;
    font-size: 25px;
    cursor: pointer;
    border-radius: 50%;
    background: var(--prime-ten);
    display: none;
    transition: all 0.3s ease-in-out;
}

.scroll-top:after {
    position: absolute;
    z-index: -1;
    content: '';
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 1;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
}


/*^^^^^^^^^^^^^^^^^^^^ Section Title ^^^^^^^^^^^^^^^^^^^^^^*/

.title-style-one .sc-title {
    font-size: 16px;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.3);
    margin-bottom: 8px;
}

.title-style-one .main-title span {
    display: inline-block;
    position: relative;
    z-index: 1;
}

.title-style-one .main-title span:before {
    content: '';
    position: absolute;
    left: 4px;
    bottom: 10px;
    width: 98%;
    height: 12px;
    background: rgba(255, 139, 37, 0.4);
    z-index: -1;
}

.title-style-two .sc-title {
    font-size: 20px;
    padding-bottom: 5px;
    color: #000;
}

.title-style-two .main-title {
    font-size: 48px;
    line-height: 1.20em;
}

.title-style-three .sc-title {
    font-size: 14px;
    letter-spacing: 1px;
    padding-bottom: 10px;
    color: rgba(0, 0, 0, 0.3);
}

.title-style-three .main-title {
    font-family: 'Recoleta';
    font-size: 64px;
    line-height: 1.15em;
    margin: 0;
    letter-spacing: 0px;
}

.title-style-three .main-title>span {
    z-index: 1;
}

.title-style-three .main-title .mark-bg {
    position: absolute;
    left: -5px;
    top: 50%;
    transform: translateY(-50%);
    width: 98%;
    height: 50px;
    z-index: -1;
}

.title-style-three .icon {
    width: 90px;
    height: 90px;
    margin: 0 auto 45px;
}

.title-style-four .main-title {
    letter-spacing: -1.5px;
}

.title-style-four .main-title span {
    display: inline-block;
    position: relative;
    z-index: 1;
}

.title-style-four .main-title span:before {
    content: '';
    position: absolute;
    left: 3px;
    bottom: 13px;
    width: 97%;
    height: 12px;
    background: #FF5BD7;
    opacity: 0.5;
    z-index: -1;
}

.title-style-four .sc-title {
    font-size: 20px;
    padding-bottom: 30px;
    color: rgba(0, 0, 0, 0.3);
}

.title-style-four .sc-title-two {
    font-size: 15px;
    letter-spacing: 3px;
    text-transform: uppercase;
    padding-bottom: 22px;
}

.title-style-five .sc-title {
    font-size: 16px;
    color: #000;
    letter-spacing: 4px;
    padding-bottom: 20px;
}

.title-style-five .sc-title-two {
    font-size: 17px;
    color: var(--prime-ten);
    padding-left: 40px;
    margin-bottom: 20px;
}

.title-style-five .sc-title-two:before {
    content: '';
    position: absolute;
    width: 24px;
    height: 1px;
    background: var(--prime-ten);
    left: 0;
    top: 14px;
}

.title-style-five .main-title {
    font-size: 72px;
    line-height: 1.25em;
}

.title-style-five .main-title>span {
    z-index: 1;
}

.title-style-five .main-title>span .line-shape {
    left: 0;
    bottom: -5%;
}

.title-style-five .main-title .mark-bg {
    position: absolute;
    left: 0;
    bottom: 17px;
    width: 100%;
    height: 13px;
    z-index: -1;
}

.title-style-six .sc-title {
    font-family: 'Noteworthy Light';
    font-size: 20px;
    letter-spacing: 3px;
    padding-bottom: 6px;
    text-transform: uppercase;
}

.title-style-six .sc-title-two {
    font-size: 15px;
    letter-spacing: 2px;
    padding-bottom: 8px;
    color: var(--prime-ten);
}

.title-style-six .main-title {
    font-size: 62px;
    line-height: 1.32em;
}

.title-style-seven .main-title {
    font-size: 68px;
    line-height: 1.35em;
    letter-spacing: -2px;
}

.title-style-seven .main-title span img {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: -1;
    max-width: 113%;
    transform: translate(-50%, -50%);
}

.title-style-eight .sc-title {
    font-size: 13px;
    letter-spacing: 3px;
    color: rgba(0, 0, 0, 0.5);
    padding-bottom: 15px;
}

.title-style-eight .main-title {
    font-size: 68px;
    line-height: 1.25em;
    letter-spacing: -1px;
}

.title-style-eight .main-title span {
    line-height: 64px;
}

.title-style-eight .main-title span:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: var(--prime-six);
}

.title-style-nine .sc-title {
    font-size: 18px;
    padding-bottom: 16px;
    color: rgba(255, 255, 255, 0.3);
}

.title-style-ten .sc-title {
    font-size: 15px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #000;
    padding-bottom: 16px;
}

.title-style-ten .main-title {
    letter-spacing: 0;
    line-height: 1.17em;
}

.title-style-ten .main-title img {
    position: absolute;
    bottom: 9px;
    left: 0;
    z-index: -1;
}

.title-style-eleven .main-title {
    font-size: 64px;
    line-height: 1.25em;
    letter-spacing: -2px;
}

.title-style-eleven .sc-title {
    font-size: 15px;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.3);
    margin-bottom: 15px;
}

.title-style-twelve .sc-title {
    font-size: 17px;
    color: #000;
    padding-left: 40px;
    margin-bottom: 12px;
}

.title-style-twelve .sc-title:before {
    content: '';
    position: absolute;
    width: 24px;
    height: 1px;
    background: #000;
    left: 0;
    top: 14px;
}

.title-style-twelve .main-title {
    font-size: 62px;
    line-height: 1.15em;
}

.title-style-thirteen .main-title {
    font-size: 55px;
    letter-spacing: 0px;
    line-height: 1.17em;
}

.title-style-fourteen .sc-title {
    font-size: 15px;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: #000;
    padding-bottom: 16px;
}

.title-style-fourteen .main-title {
    font-size: 86px;
    letter-spacing: 0;
    line-height: 1.17em;
}

.title-style-fourteen .main-title img {
    position: absolute;
    bottom: 9px;
    left: 0;
    z-index: -1;
}


/*^^^^^^^^^^^^^^^^^^^^^ Theme Button ^^^^^^^^^^^^^^^^^^^^^^*/

.ripple-btn {
    position: relative;
}

.ripple-btn:before {
    content: '';
    position: absolute;
    width: 100px;
    height: 100px;
    left: 50%;
    top: 50%;
    margin: -50px 0 0 -50px;
    transform: scale(0);
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
}

.ripple-btn:hover:before {
    transform: scale(1);
    opacity: 0;
    transition: all 0.5s ease-in-out;
}

body .btn-one {
    color: #fff;
    line-height: 50px;
    border-radius: 5px;
    padding: 0 32px;
    position: relative;
    background: #023e8a;
    transition: all 0.3s ease-in-out;
}

body .btn-one:hover {
    background: black;
}

body .btn-two {
    color: #fff;
    line-height: 60px;
    border-radius: 30px;
    padding: 0 50px;
    position: relative;
    background: #000;
    transition: all 0.3s ease-in-out;
}

body .btn-two:hover {
    background: var(--prime-one);
}

body .btn-three {
    color: #fff;
    line-height: 52px;
    border-radius: 28px;
    padding: 0 40px;
    position: relative;
    background: var(--prime-two);
    transition: all 0.3s ease-in-out;
}

body .btn-three:hover {
    background: #090F32;
}

body .btn-four {
    color: #fff;
    line-height: 52px;
    border-radius: 7px;
    padding: 0 40px;
    position: relative;
    background: var(--prime-two);
    transition: all 0.3s ease-in-out;
}

body .btn-four:hover {
    background: #090F32;
}

body .btn-five {
    color: var(--prime-two);
}

body .btn-five i {
    display: inline-block;
    font-size: 14px;
}

body .btn-five:hover {
    color: #090F32;
}

body .btn-six {
    line-height: 50px;
    border-radius: 4px;
    border: 2px solid var(--prime-two);
    color: var(--prime-two);
    padding: 0 40px;
    transition: all 0.3s ease-in-out;
}

body .btn-six:hover {
    background: var(--prime-two);
    color: #fff;
}

body .btn-seven {
    color: #fff;
    line-height: 50px;
    border-radius: 25px;
    padding: 0 40px;
    position: relative;
    background: #1B1B1B;
    transition: all 0.3s ease-in-out;
}

body .btn-seven:hover {
    background: var(--prime-three);
}

body .btn-eight {
    color: #fff;
    line-height: 60px;
    border-radius: 30px;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1.65px;
    padding: 0 55px;
    position: relative;
    background: #1B1B1B;
    transition: all 0.3s ease-in-out;
}

body .btn-eight:hover {
    background: var(--prime-three);
}

body .btn-nine {
    color: #000;
    line-height: 55px;
    border-radius: 28px;
    font-size: 16px;
    padding: 0 36px;
    position: relative;
    background: var(--prime-four);
    transition: all 0.3s ease-in-out;
}

body .btn-nine.border3 {
    border-radius: 3px;
}

body .btn-nine:hover {
    background: #000;
    color: #fff;
}

body .btn-ten {
    color: #000;
    padding: 0 24px;
    line-height: 50px;
    z-index: 1;
}

body .btn-ten:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 70px;
    border-radius: 25px;
    background: var(--prime-four);
    z-index: -1;
    transition: all 0.3s ease-in-out;
}

body .btn-ten:hover:before {
    width: 100%;
}

body .btn-ten i {
    font-size: 0.9em;
    margin-left: 2px;
}

body .btn-eleven {
    line-height: 50px;
    color: #fff;
    border-radius: 30px;
    padding: 0 42px;
    background: var(--prime-five);
}

body .btn-eleven:hover {
    background: var(--prime-two);
    color: #fff;
}

body .btn-twelve {
    line-height: 43px;
    border: 1px solid var(--prime-two);
    color: var(--prime-two);
    border-radius: 23px;
    padding: 0 45px;
}

body .btn-twelve:hover {
    background: var(--prime-two);
    color: #fff;
}

body .btn-thirteen {
    line-height: 55px;
    color: #fff;
    background: var(--prime-five);
    border-radius: 5px;
    padding: 0 40px;
}

body .btn-thirteen:hover {
    background: var(--prime-two);
}

body .btn-fourteen {
    line-height: 60px;
    font-size: 18px;
    color: #000;
    padding: 0 30px;
    background: var(--prime-six);
    position: relative;
}

body .btn-fourteen:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 8px;
    left: 8px;
    z-index: -1;
    background: #000;
    transition: all 0.3s ease-in-out;
}

body .btn-fourteen:hover {
    background: #000;
    color: #fff;
}

body .btn-fourteen:hover:before {
    background: var(--prime-six);
}

body .btn-fifteen {
    font-size: 18px;
    color: #000;
    letter-spacing: -0.5px;
    line-height: initial;
}

body .btn-fifteen:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -4px;
    height: 2px;
    width: 100%;
    background: #000;
    transition: all 0.3s ease-in-out;
}

body .btn-fifteen:hover:before {
    background: var(--prime-six);
}

body .btn-sixteen {
    position: relative;
    font-size: 18px;
    line-height: 53px;
    border-radius: 30px;
    color: #fff;
    letter-spacing: -0.5px;
    background: var(--prime-seven);
    padding: 0 40px;
    z-index: 1;
}

body .btn-sixteen:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    background: linear-gradient(90deg, #FF7DF2 0%, #FF275B 100%);
    opacity: 0;
    z-index: -1;
    transition: opacity 0.3s ease-in-out;
}

body .btn-sixteen:hover:before {
    opacity: 1;
}

body .btn-sixteen.border7,
body .btn-sixteen.border7:before {
    border-radius: 7px;
}

body .btn-seventeen {
    font-size: 18px;
    line-height: 50px;
    padding: 0 40px;
    border-radius: 7px;
    position: relative;
    z-index: 1;
    background: linear-gradient(270.78deg, #FFA7A9 4.19%, #FF5EA3 96.11%);
    -webkit-text-fill-color: transparent;
}

body .btn-seventeen:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 7px;
    border: 2px solid transparent;
    background: linear-gradient(0deg, #FE3B7D, #FF66C9) border-box;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    z-index: -1;
    transition: all 0.3s ease-in-out;
}

body .btn-eighteen {
    font-size: 17px;
    line-height: initial;
}

body .btn-eighteen i {
    font-size: 15px;
}

body .btn-eighteen:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -4px;
    height: 2px;
    width: 100%;
    background: #000;
}

body .btn-eighteen a:hover {
    color: var(--prime-nine);
}

body .btn-nineteen {
    padding: 5px 20px;
    border-radius: 30px;
    background: #000000;
    color: #fff;
    font-size: 17px;
}

body .btn-nineteen:hover {
    background: var(--prime-nine);
}

body .btn-twenty {
    line-height: 50px;
    padding: 0 35px;
    border-radius: 30px;
    border: 2px solid #000;
    color: #000;
    font-size: 17px;
}

body .btn-twenty:hover {
    background: var(--prime-eight);
    border-color: var(--prime-eight);
}

body .btn-twentyOne {
    line-height: 50px;
    padding: 10px;
    border-radius: 30px;
    /* background: var(--prime-ten); */
    background: #55CBCD;
    color: #fff;
    letter-spacing: -0.36px;
}

body .btn-twentyOne:hover {
    background: #000;
}

body .btn-twentyTwo {
    line-height: 55px;
    padding: 0 42px;
    border-radius: 7px;
    background: var(--prime-ten);
    color: #fff;
    letter-spacing: -0.2px;
}

body .btn-twentyTwo:hover {
    background: #000;
}

body .btn-twentyThree {
    width: 122px;
    height: 122px;
    background: #000;
    border-radius: 0 50% 0 0;
    transition: all 0.3s ease-in-out;
}

body .btn-twentyThree:hover {
    border-radius: 50%;
    background: var(--prime-ten);
}

body .btn-twentyFour {
    color: #000;
    padding: 16px 36px;
    border: 2px solid #000;
    background: var(--prime-eleven);
}

body .btn-twentyFour::before {
    position: absolute;
    content: '';
    top: -16px;
    height: 14px;
    width: calc(100% + 3px);
    left: 5px;
    transform: skewX(-45deg);
    border: 2px solid #000;
    border-bottom: none;
    transition: all 0.3s ease-in-out;
}

body .btn-twentyFour::after {
    position: absolute;
    content: '';
    right: -16px;
    height: calc(100% + 3px);
    width: 15px;
    top: -8px;
    transform: skewY(-45deg);
    border: 2px solid #000;
    border-left: none;
    border-top: none;
    transition: all 0.3s ease-in-out;
}

body .btn-twentyFour:hover:after,
body .btn-twentyFour:hover:before {
    background: #f3c755;
    border-color: #f3c755;
}

body .btn-twentyFour:hover {
    border-color: transparent;
}

body .btn-twentyFive {
    line-height: 51px;
    padding: 0 27px;
    font-size: 18px;
    color: var(--prime-eleven);
    border-radius: 7px;
    border: 2px solid var(--prime-eleven);
}

body .btn-twentyFive:hover {
    background: var(--prime-eleven);
    color: #000;
}

body .btn-twentySix {
    line-height: 48px;
    padding: 0 40px;
    color: #000;
    z-index: 1;
}

body .btn-twentySix:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 2px solid #363636;
    transition: all 0.3s ease-in-out;
}

body .btn-twentySix:after {
    content: '';
    position: absolute;
    width: calc(100% + 1px);
    height: calc(100% + 1px);
    top: 5px;
    left: 5px;
    z-index: -1;
    background: var(--prime-six);
    transition: all 0.3s ease-in-out;
}

body .btn-twentySix:hover:before {
    border-color: var(--prime-six);
}

body .btn-twentySix:hover:after {
    top: 0;
    left: 0;
}


/*^^^^^^^^^^^^^^^^^^^^^^^ Pagination ^^^^^^^^^^^^^^^^^^^^^^*/

.page-pagination-one ul li {
    font-weight: 500;
    font-size: 20px;
    color: #3E3E3E;
    margin-right: 22px;
}

.page-pagination-one ul li a {
    transition: all 0.3s ease;
}

.page-pagination-one ul.justify-content-center li {
    margin: 0 10px;
}

.page-pagination-one ul li:hover a,
.page-pagination-one ul li.active a {
    color: var(--prime-ten);
}

.page-pagination-one ul li.arrow {
    font-size: 28px;
    color: #000;
}

.page-pagination-one ul li:nth-child(6) a {
    font-size: 18px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Theme Menu ^^^^^^^^^^^^^^^^^^^^^^^^*/

.theme-menu-one.theme-main-menu {
    padding-top: 50px;
}

.theme-main-menu {
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
    padding: 20px 60px;
    transition: all 0.4s ease-out;
}

.theme-main-menu.sticky-menu.fixed {
    position: fixed;
    z-index: 990;
    padding-top: 12px;
    padding-bottom: 12px;
    background: #fff;
    box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.1);
}

.theme-main-menu .logo {
    min-height: 50px;
    display: flex;
    align-items: center;
}

.theme-main-menu .contact-btn-one {
    line-height: 52px;
    border-radius: 6px;
    background: #000;
    padding: 0 35px;
}

.theme-main-menu .contact-btn-one:hover {
    background: var(--prime-one);
}

.theme-main-menu .contact-btn-two {
    line-height: 48px;
    border: 1px solid var(--prime-two);
    color: var(--prime-two);
    border-radius: 30px;
    padding: 0 35px;
}

.theme-main-menu .contact-btn-two:hover {
    background: var(--prime-two);
    color: #fff;
}

.theme-main-menu .contact-btn-three {
    line-height: 50px;
    color: #fff;
    border-radius: 30px;
    padding: 0 35px;
    background: var(--prime-five);
}

.theme-main-menu .contact-btn-three:hover {
    background: var(--prime-two);
    color: #fff;
}

.theme-main-menu .right-widget .contact-btn-four {
    color: #000;
    position: relative;
    z-index: 1;
    height: 70px;
}

.theme-main-menu .right-widget .contact-btn-four em {
    position: relative;
    display: inline-block;
    font-style: normal;
}

.theme-main-menu .right-widget .contact-btn-four em:after,
.theme-main-menu .right-widget .contact-btn-four span:after {
    content: '';
    position: absolute;
    width: 107%;
    height: 2px;
    left: 0;
    bottom: 0;
    background: #000;
}

.theme-main-menu .right-widget .contact-btn-four span {
    display: inline-block;
    color: #fff;
    position: relative;
    padding-left: 5px;
}

.theme-main-menu .right-widget .contact-btn-four span:before {
    content: '';
    position: absolute;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: #000;
    z-index: -1;
    top: 50%;
    transform: translateY(-50%);
    right: -36px;
    transition: all 0.3s ease-in-out;
}

.theme-main-menu .right-widget .contact-btn-four:hover span:before {
    background: var(--prime-ten);
}

.theme-main-menu .right-widget .contact-btn-four span:after {
    background: #fff;
}

.theme-main-menu .right-widget .contact-btn-four i {
    color: #fff;
    margin-left: 3px;
}

.theme-main-menu .login-btn-one {
    line-height: 48px;
    border: 1px solid #E4E4E4;
    color: #000;
    border-radius: 30px;
    padding: 0 35px;
}

.theme-main-menu .login-btn-one:hover {
    background: var(--prime-two);
    color: #fff;
}

.theme-main-menu .login-btn-two {
    font-size: 18px;
    color: #000;
}

.theme-main-menu .login-btn-three {
    width: 55px;
    height: 55px;
    border: 1px solid #E9E9E9;
    text-align: center;
    line-height: 53px;
    color: var(--prime-ten);
    font-size: 25px;
}

.theme-main-menu.white-vr .login-btn-three {
    background: #fff;
}

.theme-main-menu .login-btn-three:hover {
    background: var(--prime-ten);
    border-color: var(--prime-ten);
    color: #fff;
}

.theme-main-menu .login-btn-four {
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    color: #fff;
    background: #000;
    font-size: 25px;
}

.theme-main-menu .login-btn-four:hover {
    background: var(--prime-twelve);
    color: #000;
}

.theme-main-menu .signup-btn-one {
    font-size: 18px;
    color: #000;
}

.theme-main-menu .login-btn-two:hover,
.theme-main-menu .signup-btn-one:hover span {
    text-decoration: underline;
}

.theme-main-menu .signup-btn-one:before {
    content: '';
    position: absolute;
    height: 13px;
    width: 2px;
    background: #000;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.theme-main-menu .signup-btn-two {
    line-height: 50px;
    border-radius: 28px;
    letter-spacing: -0.36px;
    color: var(--prime-eight);
    border: 2px solid var(--prime-eight);
    padding: 0 26px;
}

.theme-main-menu .signup-btn-two:hover {
    background: var(--prime-eight);
    color: #000;
}

.theme-main-menu .signup-btn-three {
    line-height: 45px;
    border-radius: 28px;
    letter-spacing: -0.36px;
    color: #fff;
    background: #000;
    padding: 0 28px;
}

.theme-main-menu .signup-btn-three:hover {
    background: var(--prime-eleven);
    color: #000;
}

.theme-main-menu .signup-btn-four {
    line-height: 50px;
    border-radius: 28px;
    letter-spacing: -0.36px;
    color: #fff;
    background: #000;
    padding: 0 28px;
}

.theme-main-menu .signup-btn-four:hover {
    background: var(--prime-twelve);
    color: #000;
}

.theme-main-menu .call-btn-one {
    color: #fff;
}

.theme-main-menu .call-btn-one:hover {
    text-decoration: underline;
}

.theme-main-menu .lang-dropdown:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 45px;
    background: rgba(216, 216, 216, 0.2);
    left: -25px;
    top: 50%;
    transform: translateY(-50%);
}

.theme-main-menu .lang-dropdown button {
    font-weight: 500;
    color: #fff;
    font-size: 17px;
    border: none;
    padding: 0 15px 0 0;
    line-height: initial;
    position: relative;
}

.theme-main-menu .lang-dropdown button:after {
    display: none;
}

.theme-main-menu .lang-dropdown button:before {
    content: "\f282";
    font-family: bootstrap-icons !important;
    position: absolute;
    right: 0;
    font-size: 13px;
    line-height: 23px;
}

.theme-main-menu .lang-dropdown .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.05);
    padding: 0;
    min-width: 130px;
}

.theme-main-menu .lang-dropdown .dropdown-item {
    line-height: 35px;
    padding: 0 15px;
    font-size: 15px;
    transition: all 0.3s ease-in-out;
}

.theme-main-menu .lang-dropdown .dropdown-item:hover {
    background: var(--prime-four);
    color: #fff;
}

.theme-main-menu .donate-btn {
    font-size: 17px;
    line-height: 55px;
    border-radius: 5px;
    color: #000;
    padding: 0 40px;
    background: var(--prime-four);
}

.theme-main-menu .donate-btn:hover {
    background: #fff;
}

.theme-main-menu .start-btn-one {
    line-height: 52px;
    border: 2px solid #363636;
    padding: 0 36px;
    color: #000;
}

.theme-main-menu .start-btn-one:before {
    content: '';
    position: absolute;
    width: calc(100% + 3px);
    height: calc(100% + 3px);
    top: 3px;
    left: 3px;
    z-index: -1;
    background: var(--prime-six);
    transition: all 0.3s ease-in-out;
}

.theme-main-menu .start-btn-one:hover {
    border-color: var(--prime-six);
}

.theme-main-menu .start-btn-one:hover:before {
    top: 0;
    left: 0;
}

.theme-main-menu .start-btn-two {
    font-size: 18px;
    line-height: 55px;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.17);
    padding: 0 36px;
    color: #fff;
}

.theme-main-menu .start-btn-two:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 15px;
    background: var(--prime-seven);
    z-index: -1;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.theme-main-menu .start-btn-two:hover:before {
    opacity: 1;
}

.theme-main-menu .header-search-one {
    width: 420px;
    height: 45px;
    margin-left: 7.5vw;
}

.theme-main-menu .header-search-one input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: 1px solid #000;
    border-radius: 30px;
    font-size: 15px;
    color: #000;
    padding: 0 15px 0 50px;
}

.theme-main-menu .header-search-one .icon {
    left: 18px;
    top: 50%;
    transform: translateY(-50%);
}

.theme-main-menu .header-search-two {
    width: 420px;
    height: 45px;
    margin-left: 7.5vw;
}

.theme-main-menu .header-search-two input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    border-bottom: 2px solid #000;
    font-size: 15px;
    color: #000;
    font-style: italic;
    padding: 0 15px 0 35px;
}

.theme-main-menu .header-search-two .icon {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.theme-main-menu .go-back-btn {
    color: #000;
}

.theme-main-menu .go-back-btn:hover {
    color: var(--prime-ten);
}


/***************** White Version ********************/

.white-vr .navbar .navbar-nav .nav-link,
.white-vr.theme-main-menu .signup-btn-one,
.white-vr.theme-main-menu .login-btn-two {
    color: #fff;
}

.white-vr .navbar .navbar-nav .nav-item:hover .nav-link {
    color: #fff;
    text-decoration: underline;
}

.white-vr.theme-main-menu .signup-btn-one:before {
    background: #fff;
}

.white-vr.theme-main-menu.sticky-menu.fixed {
    background: #090F32;
}


/*^^^^^^^^^^^^^^^^^^^^^ Theme Menu Four ^^^^^^^^^^^^^^^^^^^^^^^^*/

.theme-menu-four .inner-content {
    max-width: 1550px;
    margin: 0 auto;
}

.theme-menu-four .navbar-nav .nav-link.active {
    color: #815AFF;
}

.theme-main-menu .sidebar-nav-button span {
    display: block;
    position: relative;
    width: 40px;
    height: 3px;
    background: #000000;
    border-radius: 3px;
    margin-bottom: 20px;
}

.theme-main-menu .sidebar-nav-button span:before,
.theme-main-menu .sidebar-nav-button span:after {
    content: '';
    position: absolute;
    height: 3px;
    background: #000000;
    border-radius: 3px;
    left: 0;
}

.theme-main-menu .sidebar-nav-button span:before {
    top: 10px;
    width: 25px;
}

.theme-main-menu .sidebar-nav-button span:after {
    top: 20px;
    width: 15px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Theme Menu Five ^^^^^^^^^^^^^^^^^^^^^^^^*/

.theme-main-menu.theme-menu-five {
    border-bottom: 1px solid rgba(216, 216, 216, 0.2);
}


/*^^^^^^^^^^^^^^^^^^^^^ Theme Menu Six ^^^^^^^^^^^^^^^^^^^^^^^^*/

.theme-menu-six.theme-main-menu {
    padding-top: 50px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Theme Menu Seven ^^^^^^^^^^^^^^^^^^^^^^^^*/

.theme-menu-seven.white-vr.theme-main-menu.sticky-menu.fixed {
    background: #101010;
    ;
}


/*^^^^^^^^^^^^^^^^^^^^^ Theme Menu Nine ^^^^^^^^^^^^^^^^^^^^^^^^*/

.theme-menu-nine.theme-main-menu {
    padding-top: 60px;
}

.theme-menu-nine .notice-banner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background: var(--prime-eleven);
    color: #000;
    padding: 10px 12px;
}

.theme-menu-nine .notice-banner a:hover {
    color: #000;
    text-decoration: underline;
}

.theme-menu-nine.sticky-menu.fixed .notice-banner {
    opacity: 0;
    visibility: hidden;
}


/*^^^^^^^^^^^^^^^^^^^^^ sidebar Menu ^^^^^^^^^^^^^^^^^^^^^^^^*/

.offcanvas.sidebar-nav {
    width: 400px;
    border: none;
    padding: 40px 40px 20px;
    max-height: 100%;
    overflow-y: auto;
}

.offcanvas.sidebar-nav .offcanvas-header {
    padding: 0;
}

.sidebar-nav .offcanvas-header .close-btn {
    font-size: 20px;
    color: #000;
}

.sidebar-nav .offcanvas-header .close-btn:hover {
    color: var(--prime-ten);
}

.sidebar-nav .sidebar-nav-item {
    padding: 75px 0 70px;
}

.sidebar-nav .sidebar-nav-item .nav-item>a {
    font-size: 20px;
    line-height: 2.5em;
    color: var(--heading);
    font-weight: 500;
    position: relative;
    padding-right: 25px;
    transition: all 0.3s ease-in-out;
}

.sidebar-nav .sidebar-nav-item .nav-item:hover>a,
.sidebar-nav .sidebar-nav-item .nav-item.active>a {
    color: white;
}

.sidebar-nav .sidebar-nav-item .dropdown-toggle::after {
    display: none;
}

.sidebar-nav .sidebar-nav-item .dropdown-toggle::before {
    content: "+";
    position: absolute;
    margin: 0;
    border: none;
    font-size: 21px;
    right: 0;
    top: 0;
    transition: transform 0.3s ease-in-out;
}

.sidebar-nav .sidebar-nav-item .dropdown-toggle.show::before {
    transform: rotate(180deg);
    content: "-";
}

.sidebar-nav .sidebar-nav-item .dropdown-menu {
    position: static !important;
    border: none;
    background: #eefbfa;
    transform: scale(1) !important;
    padding: 10px 0;
}

.sidebar-nav .sidebar-nav-item .dropdown-menu a {
    font-size: 17px;
    line-height: 44px;
    color: #000;
    padding: 0 15px;
    background: transparent;
    text-transform: capitalize;
    transition: all 0.3s ease-in-out;
}

.sidebar-nav .sidebar-nav-item .dropdown-menu a:hover {
    color: var(--prime-ten);
}

.sidebar-nav .social-icon a {
    font-size: 19px;
    margin-right: 22px;
    color: rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease-in-out;
}

.sidebar-nav .social-icon a:hover {
    color: var(--prime-ten);
}


/*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner One ^^^^^^^^^^^^^^^^^^^^^^^^*/

.hero-banner-one {
    position: relative;
}

.hero-banner-one .bg-wrapper {
    background-color: #F5FFF5;
    background-image: url(../images/assets/bg-1.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 240px 0 140px;
    position: relative;
    z-index: 5;
}

.hero-banner-one .hero-heading {
    letter-spacing: -1px;
}

.hero-banner-one .hero-heading span {
    z-index: 1;
}

.hero-banner-one .hero-heading span:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 10px;
    width: 100%;
    height: 18px;
    background: rgba(255, 121, 0, 0.51);
    mix-blend-mode: normal;
    opacity: 0.5;
    z-index: -1;
}

.hero-banner-one form {
    height: 85px;
    max-width: 570px;
    border-radius: 10px;
}

.hero-banner-one form label {
    color: rgba(52, 52, 52, 0.5);
    left: 35px;
    top: 15px;
}

.hero-banner-one form input {
    top: 42px;
    left: 0;
    font-weight: 500;
    padding: 0 170px 0 35px;
    border: none;
    background: transparent;
}

.hero-banner-one form button {
    top: 10px;
    right: 10px;
    bottom: 10px;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    letter-spacing: 0.94px;
    width: 160px;
    border-radius: 10px;
    background: var(--prime-one);
}

.hero-banner-one form button:hover {
    background: #212121;
}

.hero-banner-one .pr-info span {
    font-size: 1.6em;
    color: var(--prime-one);
}

.hero-banner-one .illustration-holder {
    position: absolute;
    right: 6.7%;
    bottom: 0;
    width: 40.44%;
    max-width: 750px;
    z-index: 1;
}

.hero-banner-one .illustration-holder .shape-one {
    left: 11.5%;
    bottom: 7%;
    width: 80%;
}

.hero-banner-one .illustration-holder .shape-two {
    left: 11%;
    top: 16%;
    animation: jumpTwo 3.5s infinite linear;
}

.hero-banner-one .illustration-holder .shape-three {
    left: -6%;
    top: 52%;
    animation: jumpThree 3.5s infinite linear;
}

.hero-banner-one .illustration-holder .shape-four {
    right: -4%;
    top: 50%;
    animation: jumpThree 3.5s infinite linear;
}

.hero-banner-one .illustration-holder .shape-five {
    right: 13%;
    top: 30%;
    animation: jumpTwo 3.5s infinite linear;
}

.hero-banner-one .illustration-holder .shape-six {
    top: 32%;
    left: 0%;
    width: 15.74%;
    animation: jumpFour 3.5s infinite linear;
}

.hero-banner-one .illustration-holder .shape-seven {
    top: 14%;
    right: 0%;
    width: 11.6%;
    animation: jumpFive 3.5s infinite linear;
}

.hero-banner-one .shape-eight {
    left: 10%;
    bottom: -25px;
    z-index: 0;
    animation: prXOne 3.5s infinite linear;
}

.hero-banner-one .card-one {
    bottom: -50px;
    right: 3%;
    width: 300px;
    border-radius: 12px;
    box-shadow: 0px 30px 80px rgba(0, 40, 100, 0.0520002);
    padding: 25px 10px 20px 85px;
    z-index: 2;
}

.hero-banner-one .card-one .icon {
    width: 44px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    left: 20px;
    top: 30px;
    background: #8E44FF;
}

.hero-banner-one .card-one h6 {
    line-height: 1.35em;
    letter-spacing: -0.5px;
}

.hero-banner-one .card-one .more-btn {
    letter-spacing: 0.88px;
    color: rgba(0, 0, 0, 0.5);
}

.hero-banner-one .card-one .more-btn:hover {
    color: var(--prime-one);
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section One ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-one .inner-content {
    max-width: 1644px;
    margin: 0 auto;
    padding-left: 12px;
    padding-right: 12px;
}

.card-style-one .icon {
    width: 75px;
    height: 75px;
    border-radius: 15px;
}

.card-style-one h5 a:hover {
    color: var(--prime-one);
}

.card-style-one:before {
    content: '';
    position: absolute;
    left: -30px;
    top: -30px;
    right: 0;
    bottom: -30px;
    background: #FFFFFF;
    box-shadow: 0px 30px 70px rgba(10, 22, 37, 0.0555514);
    border-radius: 5px;
    z-index: -1;
    opacity: 0;
    transform: scale(1, 0.9);
    transform-origin: 0 0;
    transition: all 0.3s ease-in-out;
}

.card-style-one:hover:before {
    opacity: 1;
    transform: scale(1.0);
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Two ^^^^^^^^^^^^^^^^^^^^^^^^*/

.block-style-one .list-item li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 15px;
}

.block-style-one .list-item li:before {
    content: "\f633";
    font-family: bootstrap-icons!important;
    font-size: 13px;
    position: absolute;
    left: 0;
    top: 6px;
    color: var(--prime-one);
}

.block-style-one .rating li {
    color: #FFBE18;
    margin-right: 5px;
}

.fancy-feature-two .more-btn {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 250px;
    line-height: 75px;
    background: #000;
    text-align: center;
    color: #fff;
}

.fancy-feature-two .more-btn i {
    font-size: 22px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
}

.fancy-feature-two .more-btn:hover {
    background: var(--prime-one);
}

.fancy-feature-two .img-meta .shape-one {
    top: -9%;
    left: -3%;
    z-index: 0;
    width: 24%;
    animation: jumpTwo 3.5s infinite linear;
}

.fancy-feature-two .img-meta .shape-two {
    top: -10%;
    left: 64%;
}

.fancy-feature-two .img-meta .shape-three {
    width: 57px;
    height: 57px;
    background: #FF8C24;
    border-radius: 13px;
    transform: rotate(15deg);
    right: -5%;
    top: 10%;
    z-index: 0;
    animation: rotated 28s infinite linear;
}

.fancy-feature-two .img-meta .shape-four {
    top: 52%;
    right: -11%;
}

.fancy-feature-two .img-meta .shape-five {
    bottom: 2%;
    left: 9%;
}

.fancy-feature-two .img-meta .shape-six {
    width: 80px;
    height: 80px;
    background: #17BD37;
    border-radius: 15px;
    transform: rotate(-15deg);
    left: 0;
    top: 44%;
    z-index: 0;
    animation: rotatedTwo 35s infinite linear;
}

.fancy-feature-two .img-meta .shape-seven {
    top: 25%;
    left: -10%;
    animation: jumpFour 3.5s infinite linear;
}

.fancy-feature-two .img-meta .card-one {
    position: absolute;
    width: 330px;
    background: #FFFFFF;
    box-shadow: 0px 30px 60px rgba(14, 36, 58, 0.06);
    padding: 25px 15px 25px 45px;
    right: -17%;
    bottom: 0;
}

.fancy-feature-two .img-meta .card-one .icon {
    position: absolute;
    width: 54px;
    height: 54px;
    background: #A024FF;
    box-shadow: 0px 15px 30px rgba(11, 13, 36, 0.208646);
    z-index: 1;
    top: -25px;
    left: -25px;
}


/*---Image Two ---*/

.fancy-feature-two .img-meta-two .shape-one {
    top: -14%;
    left: 42%;
    animation: jumpThree 3.5s infinite linear;
}

.fancy-feature-two .img-meta-two .shape-two {
    top: -7%;
    right: -9%;
    z-index: 0;
    width: 24%;
    animation: jumpTwo 3.5s infinite linear;
}

.fancy-feature-two .img-meta-two .shape-three {
    top: 40%;
    right: -15%;
    animation: jumpFive 3.5s infinite linear;
}

.fancy-feature-two .img-meta-two .shape-four {
    bottom: 1%;
    left: 27%;
    animation: jumpThree 3.5s infinite linear;
}

.fancy-feature-two .img-meta-two .shape-five {
    top: 32%;
    left: -19%;
    animation: jumpFour 3.5s infinite linear;
}

.fancy-feature-two .img-meta-two .card-one {
    position: absolute;
    width: 285px;
    background: #FFFFFF;
    box-shadow: 0px 30px 60px rgba(14, 36, 58, 0.06);
    border-radius: 10px;
    padding: 25px 15px 25px 45px;
    right: -20%;
    bottom: 0;
}

.fancy-feature-two .img-meta-two .card-one .icon {
    position: absolute;
    width: 60px;
    height: 60px;
    background: #FF7A2B;
    box-shadow: 0px 10px 20px rgba(255, 96, 55, 0.320437);
    z-index: 1;
    top: -30px;
    left: -30px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feedback Section One ^^^^^^^^^^^^^^^^^^^^^^^^*/

.feedback-section-one .bg-wrapper {
    background-color: #E8FAF3;
    z-index: 1;
}

.feedback-section-one .bg-wrapper:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 45%;
    background: url(../images/assets/bg-2.png) no-repeat center;
    background-size: cover;
    z-index: -1;
    opacity: 0.9;
}

.feedback-section-one .bg-wrapper:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 19%;
    background: url(../images/assets/bg-2.png) no-repeat center;
    background-size: cover;
    z-index: -1;
    opacity: 0.9;
}

.feedback-section-one .shape-one {
    left: 10%;
    top: -4%;
    animation: jumpFour 3.5s infinite linear;
}

.feedback-section-one .shape-two {
    width: 80px;
    height: 80px;
    background: #FF8C24;
    border-radius: 15px;
    right: 10%;
    top: -4%;
    animation: rotated 35s infinite linear;
}

.feedback-section-one .shape-three {
    width: 57px;
    height: 57px;
    background: #17BD37;
    border-radius: 13px;
    left: 13%;
    bottom: -1%;
    animation: rotated 35s infinite linear;
}

.slick-arrow-one li {
    width: 50px;
    height: 50px;
    line-height: 50px;
    background: #fff;
    color: rgba(0, 0, 0, 0.6);
    margin-left: 15px;
    cursor: pointer;
}

.slick-arrow-one li:hover {
    background: #FFFFFF;
    box-shadow: 0px 15px 30px rgba(92, 59, 28, 0.1);
    color: #000;
}

.feedback-section-one .inner-content {
    width: 136vw;
    transform: translateX(-18vw);
}

.feedback_slider_one .item {
    margin: 0 20px;
}

.feedback-block-one {
    background: #fff;
    border-radius: 15px;
}

.bg-white .feedback-block-one {
    border: 1px solid #E1E1E1;
}

.bg-white .slick-center .feedback-block-one {
    background: #F7F7F7;
    border-color: #F7F7F7;
}

.feedback-block-one .img-meta {
    width: 42%;
    max-height: 385px;
    overflow: hidden;
}

.feedback-block-one .img-meta:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 51%;
    z-index: 1;
    border-radius: 0 0 0 15px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.0117614) 0%, rgba(255, 255, 255, 0.701752) 43.58%, #FFFFFF 100%);
}

.feedback-block-one .text-wrapper {
    width: calc(100% - 42%);
    padding: 0 25px 50px;
}

.feedback-block-one .text-wrapper .icon {
    width: 55px;
    height: 55px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feedback Section One ^^^^^^^^^^^^^^^^^^^^^^^^*/

.card-style-two .icon {
    height: 65px;
}

.card-style-two .icon img {
    max-height: 100%;
}

.card-style-two span {
    letter-spacing: 2px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Blog Section One ^^^^^^^^^^^^^^^^^^^^^^^^*/

.blog-section-one .bg-wrapper {
    background-color: #E8FAF3;
    z-index: 1;
}

.blog-section-one .shape-one {
    width: 49px;
    height: 49px;
    background: #17BD37;
    border-radius: 9px;
    right: 12%;
    top: -2%;
    animation: rotated 30s infinite linear;
}

.blog-section-one .shape-two {
    bottom: -6%;
    right: 11%;
    z-index: 0;
    animation: jumpTwo 3.2s infinite linear;
}

.blog-section-one .shape-three {
    width: 70px;
    height: 70px;
    background: #FF8C24;
    border-radius: 13px;
    left: 11%;
    bottom: -3%;
    animation: rotated 35s infinite linear;
}

.blog-meta-one {
    background: #fff;
    padding: 22px;
    border-radius: 5px;
}

.blog-meta-one .post-img {
    border-radius: 5px;
    overflow: hidden;
}

.blog-meta-one .post-tag {
    line-height: 25px;
    border-radius: 3px;
    padding: 0 12px;
    letter-spacing: 1px;
    font-size: 11px;
}

.blog-meta-one:hover {
    transform: translateY(-5px);
    box-shadow: 0 25px 60px rgba(21, 21, 21, 0.04);
}

.blog-meta-one .blog-title:hover h4 {
    color: var(--prime-one);
}


/*^^^^^^^^^^^^^^^^^^^^^ Fancy Short Banner One ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-short-banner-one .bg-wrapper {
    padding: 80px 12px;
    background: url(../images/assets/bg-3.png) no-repeat center;
    background-size: cover;
    border-radius: 40px;
    z-index: 1;
}

.fancy-short-banner-one .inner-wrapper {
    max-width: 1170px;
    margin: 0 auto;
}

.fancy-short-banner-one .subscribe-form {
    max-width: 500px;
    margin-left: auto;
}

.fancy-short-banner-one .subscribe-form form {
    height: 70px;
    position: relative;
    text-align: left;
    box-shadow: 0 10px 20px rgba(70, 40, 13, 0.05);
}

.fancy-short-banner-one .subscribe-form form input {
    width: 100%;
    height: 100%;
    border-radius: 7px;
    border: none;
    padding: 0 200px 0 20px;
}

.fancy-short-banner-one form button {
    top: 8px;
    right: 8px;
    bottom: 8px;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    width: 160px;
    border-radius: 6px;
    background: var(--prime-one);
}

.fancy-short-banner-one form button:hover {
    background: #212121;
}

.fancy-short-banner-one .subscribe-form p {
    color: #979797;
}

.fancy-short-banner-one .shape-one {
    width: 60px;
    height: 60px;
    background: #17BD37;
    border-radius: 13px;
    right: -2%;
    top: -9%;
    animation: rotated 30s infinite linear;
}


/*^^^^^^^^^^^^^^^^^^^^^ Footer One ^^^^^^^^^^^^^^^^^^^^^^^^*/

.footer-style-one {
    padding-top: 150px;
}

.theme-basic-footer .footer-title {
    font-size: 24px;
    margin-bottom: 20px;
}

.theme-basic-footer .footer-nav-link a {
    font-size: 17px;
    line-height: 40px;
    transition: all 0.3s ease-in-out;
}

.footer-style-one .shape-one {
    width: 85px;
    height: 85px;
    background: #FF8C24;
    border-radius: 15px;
    left: -2%;
    bottom: 10%;
    animation: rotated 35s infinite linear;
}

.footer-style-one .footer-nav-link a:hover,
.footer-style-one .email:hover,
.footer-style-one .mobile:hover {
    color: var(--prime-one);
}

.footer-style-one .social-icon a {
    width: 35px;
    height: 35px;
    border: 1px solid #D7D7D7;
    border-radius: 5px;
    text-align: center;
    line-height: 33px;
    color: rgba(0, 0, 0, 0.5);
    margin-right: 10px;
    transition: all 0.3s ease-in-out;
}

.footer-style-one .social-icon a:hover {
    background: #000;
    color: #fff;
}

.footer-style-one .bottom-footer {
    margin-top: 20px;
    padding: 25px 0;
}

.theme-basic-footer .form-widget h6 {
    font-size: 20px;
}

.theme-basic-footer .form-widget form {
    height: 70px;
}

.theme-basic-footer .form-widget form input {
    width: 100%;
    height: 100%;
    border: none;
    padding: 0 132px 0 25px;
}

.theme-basic-footer .form-widget form button {
    top: 6px;
    right: 6px;
    bottom: 6px;
    width: 125px;
    color: #fff;
    background: #161616;
}

.theme-basic-footer .form-widget form button:hover {
    background: var(--prime-six);
    color: #000;
}


/*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner Two ^^^^^^^^^^^^^^^^^^^^^^^^*/

.hero-banner-two {
    z-index: 1;
}

.hero-banner-two .shape-left {
    top: 12%;
    left: 8%;
    width: 11.9%;
    max-width: 248px;
}

.hero-banner-two .shape-right {
    top: 13%;
    right: 9%;
    width: 9.1%;
    max-width: 193px;
}

.hero-banner-two .shape-left-two {
    top: 12%;
    left: 8%;
    width: 11.9%;
    max-width: 146px;
}

.hero-banner-two .shape-right-two {
    top: 13%;
    right: 9%;
    width: 9.1%;
    max-width: 112px;
}

.hero-banner-two .hero-heading {
    font-size: 82px;
    line-height: 1.09em;
}

.hero-banner-two .hero-heading span {
    z-index: 1;
}

.hero-banner-two .hero-heading span:before {
    content: '';
    position: absolute;
    left: 31%;
    top: 50%;
    transform: translateY(-50%);
    width: 73%;
    height: 81%;
    background: #FFEBE5;
    z-index: -1;
}

.hero-banner-two .hero-heading .line-shape {
    left: 3%;
    top: 45%;
}

.hero-banner-two .hero-heading .line-shape-two {
    left: 0%;
    bottom: -15%;
}

.hero-banner-two .video-icon i {
    width: 52px;
    height: 52px;
    border: 1px solid #eaeaeb;
    line-height: 50px;
    border-radius: 50%;
    font-size: 20px;
    padding-left: 5px;
    transition: all 0.3s ease-in-out;
    color: var(--prime-two);
}

.hero-banner-two .video-icon span {
    color: var(--prime-two);
}

.hero-banner-two .video-icon:hover i {
    background: #090F32;
    border-color: #090F32;
    color: #fff;
}

.hero-banner-two .illustration-holder .bg-wrapper {
    background: #F3F7F8;
    padding: 4%;
    border-radius: 20px;
    z-index: 1;
}

.hero-banner-two .illustration-holder .main-screen {
    border-radius: 25px;
}

.hero-banner-two .illustration-holder .main-screen-two {
    border-radius: 25px 25px 0 0;
}

.hero-banner-two .illustration-holder .shape-one {
    top: -10%;
    left: -7%;
    width: 13.5%;
    z-index: 0;
    animation: rotatedHalf 25s infinite linear;
}

.hero-banner-two .illustration-holder .shape-two {
    bottom: -16%;
    right: -11%;
    width: 28.3%;
    animation: rotatedHalfTwo 25s infinite linear;
}

.hero-banner-two .illustration-holder .shape-three {
    top: 7%;
    right: -10%;
    width: 12%;
    z-index: 0;
}

.hero-banner-two .illustration-holder .shape-four {
    bottom: 9%;
    left: -10%;
    width: 12%;
    z-index: 0;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Three ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-three .screen-holder-one {
    width: 432px;
    height: 470px;
    border-radius: 25px;
    background: #F3F7F8;
    position: relative;
    z-index: 1;
    margin: 0 10% 0 auto;
}

.fancy-feature-three .screen-holder-one .round-bg {
    background: #fff;
    border-radius: 50%;
    box-shadow: 0px 20px 50px rgba(27, 75, 132, 0.03);
}

.fancy-feature-three .screen-holder-one .shapes {
    z-index: 1;
}

.fancy-feature-three .screen-holder-one .logo-one {
    top: -45px;
    right: 37%;
    animation: jumpTwo 6s infinite linear;
}

.fancy-feature-three .screen-holder-one .logo-two {
    top: 23%;
    left: -59px;
    animation: jumpThree 6s infinite linear;
}

.fancy-feature-three .screen-holder-one .logo-three {
    top: 72%;
    left: -46px;
    animation: jumpTwo 6s infinite linear;
}

.fancy-feature-three .screen-holder-one .logo-four {
    bottom: -75px;
    left: 58%;
    animation: jumpThree 6s infinite linear;
}

.fancy-feature-three .screen-holder-one .logo-five {
    top: 21%;
    right: -39px;
    animation: jumpThree 6s infinite linear;
}

.fancy-feature-three .screen-holder-one .shape-one {
    top: 44%;
    right: -15%;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Four ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-four .nav-tabs {
    border: 1px solid #f3f3f3;
    border-radius: 15px;
    overflow: hidden;
    text-align: center;
}

.fancy-feature-four .nav-tabs li {
    width: 33.33333%;
    border-right: 1px solid #f3f3f3;
    position: relative;
}

.fancy-feature-four .nav-tabs li:before {
    content: "\f285";
    font-family: bootstrap-icons;
    width: 40px;
    height: 40px;
    border: 1px solid #D9D9D9;
    border-radius: 50%;
    background: #fff;
    text-align: center;
    line-height: 38px;
    font-size: 14px;
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.fancy-feature-four .nav-tabs li:last-child {
    border: none;
}

.fancy-feature-four .nav-tabs li:last-child:before {
    display: none;
}

.fancy-feature-four .nav-tabs li .nav-link {
    width: 100%;
    margin: 0;
    border: none;
    border-bottom: 5px solid transparent;
    border-radius: 0;
    font-size: 22px;
    line-height: 1.41em;
    color: #000;
    padding: 29px 20%;
}

.fancy-feature-four .nav-tabs .nav-item.show .nav-link,
.fancy-feature-four .nav-tabs .nav-link.active {
    background: #FBFBFB;
    border-bottom-color: #FFD35B;
}

.fancy-feature-four .tab-pane {
    background: #F3F7F8;
    border-radius: 20px;
    padding: 4%;
}

.fancy-feature-four .tab-pane .main-screen {
    border-radius: 25px;
}

.fancy-feature-four .tab-content .shape-one {
    width: 126px;
    height: 126px;
    background: rgba(0, 236, 183, 0.66);
    top: -75px;
    right: -40px;
    z-index: 0;
    animation: jumpFive 3s infinite linear;
}

.fancy-feature-four .tab-content .shape-two {
    width: 285px;
    height: 285px;
    background: #998EFF;
    bottom: -10%;
    left: -6%;
    animation: fade-in 8s infinite linear;
}

.fancy-feature-four .tab-content .shape-three {
    right: -13%;
    top: 50%;
    z-index: 0;
    animation: jumpTwo 3s infinite linear;
}

.fancy-feature-four .tab-content .shape-four {
    left: -13%;
    top: 13%;
    z-index: 0;
    animation: jumpThree 3s infinite linear;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Five ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-five .tags-wrapper {
    background: #F3F7F8;
    padding: 45px 65px 50px;
    border-radius: 25px;
}

.fancy-feature-five .tags-wrapper ul {
    margin: 0 -10px;
}

.fancy-feature-five .tags-wrapper li {
    font-size: 24px;
    color: #000;
    padding: 0 10px;
    margin: 10px 0;
}

.fancy-feature-five .tags-wrapper li a {
    background: #fff;
    color: #000;
    font-size: 18px;
    border-radius: 10px;
    padding: 22px 30px;
    transition: all 0.3s ease-in-out;
}

.fancy-feature-five .tags-wrapper li a:hover {
    box-shadow: 0 15px 40px rgba(0, 0, 0, .05);
}

.fancy-feature-five .tags-wrapper li a span {
    display: inline-block;
    line-height: 25px;
    padding: 0 3px;
    border-radius: 5px;
}

.fancy-feature-five .tags-wrapper .shape-one {
    top: 1%;
    right: 1%;
    width: 25%;
    z-index: 0;
    animation: jumpThree 3s infinite linear;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Six ^^^^^^^^^^^^^^^^^^^^^^^^*/

.card-style-three {
    border: 1px solid #f1f1f1;
    border-radius: 10px;
    padding: 42px 10px 30px 45px;
    background: #fff;
    transition: all 0.3s ease-in-out;
    width: 100%;
}

.card-style-three:hover {
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.04);
    border-color: #fff;
}

.card-style-three .icon {
    height: 65px;
}

.card-style-three .icon img {
    max-height: 100%;
}

.fancy-feature-six .shape-one {
    width: 126px;
    height: 126px;
    background: #FFC961;
    top: 4%;
    left: -50px;
    animation: jumpThree 3s infinite linear;
}

.fancy-feature-six .shape-two {
    top: 30%;
    right: 3%;
}

.fancy-feature-six .shape-three {
    width: 300px;
    height: 300px;
    background: #FF35D9;
    opacity: 0.7;
    bottom: 0;
    right: -150px;
    animation: jumpTwo 3s infinite linear;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feedback Section Two ^^^^^^^^^^^^^^^^^^^^^^^^*/

.feedback-section-two .circle-bg {
    top: 0;
    left: 0;
    width: 100%;
    z-index: -2;
}

.feedback-section-two .circle-bg [class*="cp-"] {
    z-index: 1;
}

.feedback-section-two .circle-bg .cp-one {
    top: 19%;
    left: 16%;
}

.feedback-section-two .circle-bg .cp-two {
    top: 53%;
    left: 11%;
}

.feedback-section-two .circle-bg .cp-three {
    bottom: 5%;
    left: 25%;
}

.feedback-section-two .circle-bg .cp-four {
    top: 14%;
    right: 21%;
}

.feedback-section-two .circle-bg .cp-five {
    top: 57%;
    right: 10%;
}

.feedback-section-two .circle-bg .cp-six {
    bottom: 9%;
    right: 24%;
}

.feedback-block-two p {
    font-size: 28px;
    line-height: 1.85em;
}

.slick-arrow-two li {
    font-size: 26px;
    margin: 0 12px;
    cursor: pointer;
}

.slick-arrow-two li:hover {
    transform: scale(1.1);
}


/*^^^^^^^^^^^^^^^^^^^^^ Partner Section One ^^^^^^^^^^^^^^^^^^^^^^^^*/

.partner-section-one .img-box a {
    display: block;
    height: 100%;
}

.partner-section-one .img-box img {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
}

.partner-section-one .img-box {
    background: #fff;
    border-radius: 50%;
    box-shadow: 15px 30px 50px rgba(23, 32, 90, 0.06);
    ;
    width: 175px;
    height: 175px;
    margin: 0 auto 25px;
    transition: all 0.3s ease-in-out;
}

.partner-section-one .img-box:hover {
    transform: scale(1.1);
}

.partner-section-one .img-box.bx-b {
    width: 121px;
    height: 121px;
}

.partner-section-one .img-box.bx-d {
    width: 151px;
    height: 151px;
}

.partner-section-one .img-box.bx-f {
    width: 135px;
    height: 135px;
}

.partner-section-one .img-box.bx-g {
    width: 197px;
    height: 197px;
}

.partner-section-one .img-box.bx-h {
    width: 138px;
    height: 138px;
}

.partner-section-one .shape-one {
    top: 18%;
    right: 9%;
    animation: jumpTwo 4s infinite linear;
}

.partner-section-one .shape-two {
    bottom: -11%;
    left: 12%;
    animation: jumpThree 4s infinite linear;
}


/*^^^^^^^^^^^^^^^^^^^^^ Pricing Section One ^^^^^^^^^^^^^^^^^^^^^^^^*/

.pricing-nav-one .nav-item .nav-link {
    font-size: 18px;
    line-height: 58px;
    border: none;
    padding: 0 15px;
    width: 150px;
    text-align: center;
    background: #f9f9f9;
    color: #000;
    transition: all 0.3s ease-in-out;
}

.pricing-nav-one .nav-item:nth-child(1) .nav-link {
    border-right: none;
    border-radius: 45px 0 0 45px;
}

.pricing-nav-one .nav-item:nth-child(2) .nav-link {
    border-left: none;
    border-radius: 0 45px 45px 0;
}

.pricing-nav-one .nav-item .nav-link.active {
    background: var(--prime-two);
    color: #fff;
}

.pricing-table-area-one .pr-table-wrapper {
    border: 1px solid #ededed;
    border-radius: 15px;
    text-align: center;
}

.pricing-table-area-one .pr-column {
    border-right: 1px solid #ededed;
}

.pricing-table-area-one .pr-column:last-child {
    border-right: none;
}

.pricing-table-area-one .pr-column .plan {
    display: inline-block;
    border: 1px solid #CFCFCF;
    border-radius: 17px;
    line-height: 28px;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1.5px;
    margin: 29px 0 22px;
    padding: 0 15px;
}

.pricing-table-area-one .pr-column .price {
    font-size: 58px;
    letter-spacing: -1px;
    color: #000;
    line-height: initial;
    margin-bottom: 3px;
}

.pricing-table-area-one .pr-column .pr-header {
    padding-bottom: 35px;
    border-bottom: 1px solid #ededed;
}

.pricing-table-area-one .pr-body .bi {
    font-size: 25px;
    color: rgba(151, 151, 151, 0.75);
}

.pricing-table-area-one .pr-body .bi.availabe {
    color: var(--prime-two);
}

.pricing-table-area-one .pr-body li {
    border-bottom: 1px solid #ededed;
    padding: 22px 0;
}

.pricing-table-area-one .trial-button {
    width: 160px;
    line-height: 46px;
    border-radius: 5px;
    background: #F4F4F4;
    color: #000;
}

.pricing-table-area-one .trial-button:hover {
    background: var(--prime-two);
    color: #fff;
}

.pricing-table-area-one .pr-footer {
    padding: 45px 0;
}

.pricing-table-area-one .pr-list-wrapper {
    border: 1px solid #ededed;
    border-radius: 15px 0 0 15px;
    border-right: none;
    top: 228px;
    left: 0;
    height: 100%;
}

.pricing-table-area-one .pr-list-wrapper li {
    font-size: 17px;
    border-bottom: 1px solid #ededed;
    padding: 28px 0 28px 25px;
}

.pricing-table-area-one .pr-list-wrapper li:last-child {
    border-bottom: none;
}

.pricing-section-one .shape-one {
    width: 293px;
    height: 293px;
    background: #57F2CF;
    top: 24%;
    left: -180px;
    animation: jumpTwo 4s infinite linear;
}

.pricing-section-one .shape-two {
    bottom: 0;
    left: 20%;
    animation: jumpFour 4s infinite linear;
}

.pricing-section-one .shape-three {
    top: 35%;
    right: 3%;
    animation: jumpThree 4s infinite linear;
}


/*^^^^^^^^^^^^^^^^^^^^^ Fancy Short Banner Two ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-short-banner-two .bg-wrapper {
    background: #F3F7F8;
    border-radius: 10px;
    padding: 70px 12px;
}

.fancy-short-banner-two .bg-wrapper .inner-wrapper {
    max-width: 1110px;
}

.fancy-short-banner-two h6 {
    color: var(--prime-two);
}

.fancy-short-banner-two .shape-one {
    width: 121px;
    height: 121px;
    background: #623CFF;
    opacity: 0.65;
    bottom: -50px;
    right: -50px;
    animation: jumpThree 4s infinite linear;
}


/*^^^^^^^^^^^^^^^^^^^^^ Fancy Short Banner Three ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-short-banner-three.bg {
    background: #F5F5F5;
    z-index: 1;
}

.fancy-short-banner-three.bg:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 26px;
    background: url(../images/shape/shape_87.svg) no-repeat center top;
    background-size: cover;
    left: 0;
    top: -25px;
    z-index: -1;
}

.fancy-short-banner-three.bg:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 40px;
    background: url(../images/shape/shape_86.svg) no-repeat center bottom;
    background-size: cover;
    left: 0;
    bottom: -38px;
    z-index: -2;
}

.fancy-short-banner-three.bg .shape-one {
    width: 30px;
    height: 30px;
    left: 33%;
    top: -32px;
    background: #007BFF;
}

.fancy-short-banner-three.bg .shape-two {
    width: 14px;
    height: 14px;
    left: 19%;
    bottom: -32px;
    background: #FFB900;
}

.fancy-short-banner-three.bg .shape-three {
    width: 8px;
    height: 8px;
    right: 23%;
    top: 70%;
    background: #02DFEF;
    animation: jumpThree 5s infinite linear;
}

.fancy-short-banner-three.bg .shape-four {
    bottom: 13%;
    left: 0;
    width: 16.5%;
}

.fancy-short-banner-three.bg .shape-five {
    bottom: -20px;
    right: 0;
    width: 26.4%;
}

.fancy-short-banner-three .subscribe-form {
    max-width: 620px;
}

.fancy-short-banner-three .subscribe-form form {
    height: 70px;
}

.fancy-short-banner-three .subscribe-form input {
    width: 100%;
    height: 100%;
    border: none;
    background: #F5F5F5;
    border-radius: 35px;
    padding: 0 180px 0 40px;
}

.fancy-short-banner-three.bg .subscribe-form input {
    background: #fff;
}

.fancy-short-banner-three .subscribe-form button {
    width: 180px;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 0 35px 35px 0;
    background: var(--prime-two);
    color: #fff;
}

.fancy-short-banner-three .subscribe-form button:hover {
    background: #090F32;
}


/*^^^^^^^^^^^^^^^^^^^^^ Footer Two ^^^^^^^^^^^^^^^^^^^^^^^^*/

.footer-style-two {
    padding: 0 10%;
}

.footer-style-two .top-footer {
    border: 1px solid #E5E5E5;
    border-radius: 20px;
    padding: 100px 12px 60px;
    z-index: 1;
}

.footer-style-two .inner-wrapper {
    max-width: 1170px;
}

.footer-style-two .email:hover {
    text-decoration: underline;
    color: #000;
}

.footer-style-two .mobile {
    color: var(--prime-two);
}

.footer-style-two .mobile:hover {
    text-decoration: underline;
}

.footer-style-two .footer-nav-link a:hover {
    color: var(--prime-two);
    text-decoration: underline;
}

.footer-style-two .bottom-footer {
    padding: 40px 0 25px;
    z-index: 2;
}

.footer-style-two .bottom-footer .footer-nav a {
    font-size: 15px;
    font-weight: 500;
    color: #000;
    margin-right: 22px;
}

.footer-style-two .bottom-footer .footer-nav a:hover {
    text-decoration: underline;
}

.footer-style-two .bottom-footer .copyright {
    font-size: 15px;
}

.footer-style-two .bottom-footer .social-icon a {
    font-size: 19px;
    margin-left: 19px;
    color: #333333;
    transition: all 0.3s ease-in-out;
}

.footer-style-two .bottom-footer .social-icon a:hover {
    color: var(--prime-two);
}

.footer-style-two .shape-one {
    width: 240px;
    height: 240px;
    background: #FFBF44;
    opacity: 0.7;
    top: -120px;
    left: -120px;
}

.footer-style-two .shape-two {
    width: 136px;
    height: 136px;
    background: #15B9FF;
    opacity: 0.65;
    right: -70px;
    bottom: -55px;
}

.footer-style-two .shape-three {
    right: -4%;
    top: -14%;
}

.footer-style-two .shape-four {
    left: -6%;
    bottom: 16%;
}


/*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner Three ^^^^^^^^^^^^^^^^^^^^^^^^*/

.hero-banner-three:before {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    height: 66%;
    background: url(../images/assets/newbg.png) no-repeat bottom center;
    background-size: cover;
    z-index: -1;
}

.hero-banner-three .slogan {
    line-height: initial;
    border-radius: 20px;
    padding: 5px 10px 5px 15px;
    background: rgba(255, 255, 255, 0.22);
    color: #000;
    font-size: 16px;
}

.hero-banner-three .slogan i {
    font-size: 12px;
    width: 30px;
    line-height: 22px;
    background: rgba(255, 255, 255, 0.4);
    text-align: center;
    border-radius: 20px;
    display: inline-block;
    vertical-align: 2px;
    margin-left: 22px;
    transition: all 0.3s ease-in-out;
}

.hero-banner-three .slogan:hover i {
    background: var(--prime-three);
    color: #fff;
}

.hero-banner-three .hero-heading {
    font-size: 90px;
    line-height: 1.22em;
    letter-spacing: -2px;
}

.hero-banner-three .download-btn button {
    line-height: 60px;
    padding: 0 40px;
    color: #fff;
    background: #262626;
    border-radius: 50px;
}

.hero-banner-three .download-btn button:after {
    content: url(../images/icon/icon_21.svg);
    border: none;
    margin: 0 0 0 8px;
    vertical-align: 0;
    transition: all 0.2s ease-in-out;
}

.hero-banner-three .download-btn button.show:after {
    transform: rotate(180deg);
}

.hero-banner-three .download-btn button.show,
.hero-banner-three .download-btn button:hover {
    background: var(--prime-three);
}

.hero-banner-three .download-btn .dropdown-menu {
    width: 100%;
    border-radius: 10px;
    padding: 10px 20px;
    border: none;
    margin: -2px 0 0 0 !important;
    box-shadow: 0 20px 35px rgba(0, 0, 0, 0.04);
}

.hero-banner-three .download-btn .dropdown-menu a {
    font-size: 15px;
    color: #000;
    padding: 12px 15px;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
}

.hero-banner-three .download-btn .dropdown-menu a:hover,
.hero-banner-three .download-btn .dropdown-menu a:focus {
    background: rgba(0, 0, 0, 0.02);
}

.hero-banner-three .video-icon i {
    width: 60px;
    height: 60px;
    border: 1px solid #373737;
    line-height: 58px;
    border-radius: 50%;
    text-align: center;
    font-size: 36px;
    padding-left: 5px;
    transition: all 0.3s ease-in-out;
    color: rgba(0, 0, 0, 0.8);
}

.hero-banner-three .video-icon span {
    color: rgba(0, 0, 0, 0.3);
    letter-spacing: 2.5px;
    margin-bottom: 4px;
}

.hero-banner-three .video-icon:hover i {
    background: var(--prime-three);
    border-color: var(--prime-three);
    color: #fff;
}

.hero-banner-three h2 {
    font-size: 38px;
    letter-spacing: 0px;
}

.hero-banner-three .mobile-screen {
    position: absolute;
    top: 18%;
    right: 3%;
    width: 37.15%;
}

.hero-banner-three .mobile-screen .screen-one {
    /* box-shadow: -40px 40px 80px rgba(0, 60, 130, 0.03); */
    border-radius: 32px;
}

.hero-banner-three .mobile-screen .img-one {
    box-shadow: -40px 40px 80px rgba(0, 60, 130, 0.03);
    border-radius: 32px;
    height: 300px;
    justify-content: center;
    align-self: center;
    margin-left: 50px
}

.hero-banner-three .mobile-screen .screen-two {
    /* box-shadow: -20px 40px 80px rgba(0, 60, 130, 0.03); */
    border-radius: 32px;
}

.hero-banner-three .mobile-screen .card-one {
    background: #fff;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    padding: 28px 15px;
}

.hero-banner-three .mobile-screen .card-one .icon {
    width: 45px;
    height: 45px;
    color: #fff;
    font-size: 17px;
    background: #fff;
}

.hero-banner-three .mobile-screen .card-one h6 {
    width: calc(100% - 45px);
    line-height: 27px;
    letter-spacing: -0.5px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Seven ^^^^^^^^^^^^^^^^^^^^^^^^*/

.card-style-four {
    padding: 45px 25px 30px;
}

.card-style-four .icon {
    width: 75px;
    height: 75px;
    border-radius: 15px;
}

.card-style-four h4 a:hover {
    color: var(--prime-three);
}

.card-style-four:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0;
    bottom: 0px;
    background: #FFFFFF;
    box-shadow: 0px 25px 60px rgba(10, 22, 37, 0.0555514);
    border-radius: 5px;
    z-index: -1;
    opacity: 0;
    transform: scale(1, 0.9);
    transform-origin: 0 0;
    transition: all 0.3s ease-in-out;
}

.card-style-four:hover:before {
    opacity: 1;
    transform: scale(1.0);
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Eight ^^^^^^^^^^^^^^^^^^^^^^^^*/

.block-style-three .list-item li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 15px;
}

.block-style-three .list-item li:before {
    content: "\f633";
    font-family: bootstrap-icons!important;
    font-size: 13px;
    position: absolute;
    left: 0;
    top: 6px;
    color: var(--prime-three);
}

.block-style-three .list-item.text-white li:before {
    color: rgba(255, 255, 255, 0.75);
}

.platform-button-group a {
    width: 190px;
    height: 58px;
    padding: 0 5px 0 25px;
    margin: 10px 25px 0 0;
    background: #1B1B1B;
    border: 1px solid transparent;
    border-radius: 6px;
    color: #fff;
    text-align: left;
    transition: all 0.3s ease-in-out;
}

.platform-button-group a:hover {
    transform: translateY(-5px);
    box-shadow: -5px 10px 30px rgba(0, 0, 0, 0.05);
}

.platform-button-group a .icon {
    margin-right: 14px;
}

.platform-button-group a span {
    font-size: 10px;
    color: rgba(255, 255, 255, 0.6);
    display: block;
    line-height: initial;
}

.platform-button-group a strong {
    font-weight: 500;
    font-size: 18px;
    display: block;
}

.platform-button-group a.windows-button {
    background: #fff;
    border-color: #DADADA;
}

.platform-button-group a.windows-button span {
    color: #999999;
}

.platform-button-group a.windows-button strong {
    color: #000;
}

.fancy-feature-eight .illustration-holder {
    position: absolute;
    width: 41%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.fancy-feature-eight .illustration-holder .main-img {
    border-radius: 0 25px 25px 0;
    /* box-shadow: 0 18px 27px rgba(0, 0, 0, 0.03); */
}

.fancy-feature-eight .illustration-holder .screen-one {
    z-index: 1;
    right: -8%;
    bottom: -4%;
    border-radius: 15px;
    width: 41.4%;
    /* box-shadow: -30px 50px 100px rgba(0, 20, 63, 0.1); */
}

.fancy-feature-eight .illustration-holder .shape-one {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Nine ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-nine .screen-container {
    max-width: 630px;
}

.fancy-feature-nine .screen-container .bg-round-shape {
    right: 13%;
    top: 50%;
    transform: translateY(-50%);
}

.card-style-five {
    padding: 65px 45px 65px;
    background: #FFFFFF;
    box-shadow: 0px 30px 60px rgba(6, 24, 67, 0.05);
    border-radius: 10px;
    text-align: center;
    margin: 40px 0;
}

.card-style-five .icon {
    padding-top: 80px;
    justify-content: center;
    height: 30px;
}

.card-style-five h4 {
    font-size: 24px;
    line-height: 1.5em;
    letter-spacing: -0.5px;
    padding-top: 45px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Ten ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-ten .circle-one {
    width: 1215px;
    height: 1215px;
    border: 1px dashed #e5e5e5;
    padding: 13%;
    z-index: 1;
}

.fancy-feature-ten .circle-two {
    height: 100%;
    border: 1px dashed #e5e5e5;
    z-index: 1;
    padding: 15%;
}

.fancy-feature-ten .circle-three {
    height: 100%;
    background: #F9FBFF;
}

.fancy-feature-ten .circle-two .icon-one {
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
}

.fancy-feature-ten .circle-two .icon-two {
    top: 26%;
    right: -50px;
}

.fancy-feature-ten .circle-two .icon-three {
    bottom: 15%;
    right: 6%;
}

.fancy-feature-ten .circle-two .icon-four {
    bottom: 15%;
    left: 7%;
}

.fancy-feature-ten .circle-two .icon-five {
    top: 26%;
    left: -50px;
}

.fancy-feature-ten .block-bg {
    width: 250px;
    height: 125px;
    background: #fff;
    border-radius: 20px;
    padding: 0 35px;
    text-align: center;
    font-size: 22px;
    line-height: 33px;
    z-index: 2;
}

.fancy-feature-ten .block-bg:hover {
    box-shadow: 0 25px 35px rgba(7, 9, 24, 0.03);
    transform: translateY(-10px);
}

.fancy-feature-ten .block-one {
    top: -40px;
    left: calc(50% - 125px);
}

.fancy-feature-ten .block-two {
    top: 22%;
    right: -4%;
}

.fancy-feature-ten .block-three {
    bottom: 33%;
    right: -3%;
}

.fancy-feature-ten .block-four {
    bottom: 32%;
    left: -2%;
}

.fancy-feature-ten .block-five {
    top: 22%;
    left: -5%;
}

.fancy-feature-ten .mobile-screen {
    position: absolute;
    z-index: 5;
    width: 31.29%;
    left: 50%;
    bottom: 7%;
    transform: translateX(-50%);
    box-shadow: 0 25px 100px rgba(7, 9, 24, 0.05);
    border-radius: 40px;
}

.fancy-feature-ten .fancy-shape {
    top: 13%;
    left: 8%;
    width: 83%;
    animation: rotated 90s infinite linear;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feedback Section Three ^^^^^^^^^^^^^^^^^^^^^^^^*/


/* .feedback-section-three .slider-wrapper {
    width: calc(1320px + 30vw);
    margin: 0 -20px;
} */

.feedback_slider_three .item {
    margin: 0 20px;
}

.feedback-block-three {
    background: #FFFFFF;
    box-shadow: 0px 30px 60px rgba(19, 45, 73, 0.04);
    border-radius: 10px;
    margin: 40px 0 135px;
    padding: 55px 40px;
}

.feedback-block-three .rating li {
    font-size: 16px;
    color: #FFC12B;
    margin-right: 8px;
}

.feedback-block-three p {
    font-size: 20px;
    line-height: 35px;
    margin: 22px 0 40px;
}

.feedback-block-three .name span {
    font-size: 16px;
    color: #C1C1C1;
    margin-top: 3px;
}

.feedback-block-three .avatar {
    width: 45px;
    height: 45px;
}

.feedback_slider_three .slick-dots {
    text-align: center;
}

.slick-dots li {
    display: inline-block;
}

.slick-dots li button {
    text-indent: -50000px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.1);
    margin: 0 5px;
    transition: all 0.3s ease-in-out;
}

.slick-dots li.slick-active button {
    background: var(--prime-three);
    transform: scale(1.7);
}

.feedback-section-three .shape-holder {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 41%;
}

.feedback-section-three .shape-holder [class*="avatar-"] {
    box-shadow: 0 20px 40px rgba(36, 11, 74, 0.1);
    z-index: 1;
}

.feedback-section-three .shape-holder .avatar-one {
    bottom: 17%;
    left: 14%;
    animation: jumpFour 3.5s infinite linear;
}

.feedback-section-three .shape-holder .avatar-two {
    top: -1%;
    right: 20%;
    animation: jumpTwo 3.5s infinite linear;
}

.feedback-section-three .shape-holder .avatar-three {
    top: 21%;
    left: 14%;
    animation: jumpFour 3.5s infinite linear;
}

.feedback-section-three .shape-holder .avatar-four {
    bottom: 3%;
    right: 26%;
    animation: jumpTwo 3.5s infinite linear;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Eleven ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-eleven.bg-color {
    background: #F3F7F8;
}

.fancy-feature-eleven .shape-one {
    top: -9%;
    left: 30%;
    animation: jumpThree 3.5s infinite linear;
}

.fancy-feature-eleven .shape-two {
    top: 48%;
    left: 3%;
    animation: jumpFour 3.5s infinite linear;
}

.fancy-feature-eleven .shape-three {
    bottom: -10%;
    left: 28%;
    animation: jumpTwo 3.5s infinite linear;
}

.accordion-style-one .accordion-item {
    background: #FFFFFF;
    border: 1px solid #E9E9E9;
    border-radius: 10px;
    margin-bottom: 25px;
}

.bg-color .accordion-style-one .accordion-item {
    border: none;
}

.accordion-style-one .accordion-button {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.62em;
    padding: 25px 28px 25px 26px;
    box-shadow: none;
    border-radius: 0;
    color: var(--heading);
    background: transparent;
    transition: all 0.3s ease-in-out;
}

.accordion-style-one .accordion-body {
    padding: 0 40px 30px 26px;
}

.accordion-style-one .accordion-body p {
    font-size: 18px;
    line-height: 34px;
}

.accordion-style-one .accordion-button::after {
    content: url(../images/icon/icon_36.svg);
    background-image: none;
    width: auto;
    height: auto;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feedback Section Three ^^^^^^^^^^^^^^^^^^^^^^^^*/

.pricing-section-two {
    background: #F8FAFE;
    z-index: 1;
}

.pricing-nav-two .nav-item .nav-link {
    font-size: 18px;
    line-height: 58px;
    border: 1px solid #DDDDDD;
    padding: 0 15px;
    width: 150px;
    text-align: center;
    color: #000;
    transition: all 0.3s ease-in-out;
}

.pricing-nav-two .nav-item:nth-child(1) .nav-link {
    border-right: none;
    border-radius: 45px 0 0 45px;
}

.pricing-nav-two .nav-item:nth-child(2) .nav-link {
    border-left: none;
    border-radius: 0 45px 45px 0;
}

.pricing-nav-two .nav-item .nav-link.active {
    background: #1B1B1B;
    color: #fff;
    border-color: #1B1B1B;
}

.pricing-table-area-two .pr-column {
    background: #fff;
    border-radius: 15px;
    text-align: center;
    padding: 40px 40px 45px;
    position: relative;
}

.pricing-table-area-two .pr-column.active {
    box-shadow: 0 40px 80px rgba(3, 17, 54, 0.04);
}

.pricing-table-area-two .price {
    font-size: 58px;
    line-height: initial;
}

.pricing-table-area-two .plan {
    font-size: 28px;
}

.pricing-table-area-two .pr-header {
    border-bottom: 1px solid #DDDDDD;
}

.pricing-table-area-two .pr-body li {
    font-size: 18px;
    padding-left: 32px;
    margin-bottom: 15px;
    position: relative;
}

.pricing-table-area-two .pr-body li:before {
    content: "\f272";
    font-family: bootstrap-icons;
    font-weight: 700;
    position: absolute;
    left: 0;
    top: 0;
    color: var(--prime-three);
}

.pricing-table-area-two .signup-btn {
    line-height: 46px;
    border: 2px solid #E4E4E4;
    color: #000;
    border-radius: 5px;
}

.pricing-table-area-two .signup-btn:hover {
    background: var(--prime-three);
    border-color: var(--prime-three);
    color: #fff;
}

.pricing-table-area-two .active .signup-btn {
    background: #1B1B1B;
    border-color: #1B1B1B;
    color: #fff;
}

.pricing-table-area-two .pr-column.active:before {
    content: url(../images/shape/popular-tag.svg);
    position: absolute;
    top: -62px;
    right: -96px;
}

.pricing-section-two .shape-one {
    top: 12%;
    left: 8%;
    animation: jumpThree 3.5s infinite linear;
}

.pricing-section-two .shape-two {
    top: 31%;
    left: 17%;
    animation: jumpFour 3.5s infinite linear;
}

.pricing-section-two .shape-three {
    top: 11%;
    right: 12%;
    animation: jumpThree 3.5s infinite linear;
}

.pricing-section-two .shape-four {
    top: 30%;
    right: 16%;
    animation: jumpFour 3.5s infinite linear;
}


/*^^^^^^^^^^^^^^^^^^^^^ Partner Section Two ^^^^^^^^^^^^^^^^^^^^^^^^*/

.partner-section-two .logo-wrapper {
    position: absolute;
    right: -0.01%;
    top: 50%;
    transform: translateY(-50%);
    max-width: 63%;
}

.partner-section-two .logo-wrapper .logo {
    border-radius: 50%;
    margin: 12px 5%;
}

.partner-section-two .logo-wrapper .logo:nth-child(1) {
    width: 175px;
    height: 175px;
    background: #FFE3E3;
    animation: jumpTwo 9s infinite linear;
}

.partner-section-two .logo-wrapper .logo:nth-child(2) {
    width: 120px;
    height: 120px;
    background: #E8F8FF;
    animation: jumpThree 9s infinite linear;
}

.partner-section-two .logo-wrapper .logo:nth-child(3) {
    width: 175px;
    height: 175px;
    background: #EAFFE7;
    animation: jumpTwo 9s infinite linear;
}

.partner-section-two .logo-wrapper .logo:nth-child(4) {
    width: 150px;
    height: 150px;
    background: #F3F1FF;
    animation: jumpThree 9s infinite linear;
}

.partner-section-two .logo-wrapper .logo:nth-child(5) {
    width: 135px;
    height: 135px;
    background: #F0F5EF;
    animation: jumpThree 9s infinite linear;
}

.partner-section-two .logo-wrapper .logo:nth-child(6) {
    width: 195px;
    height: 195px;
    background: #FFF9E5;
    animation: jumpTwo 9s infinite linear;
}

.partner-section-two .logo-wrapper .logo:nth-child(7) {
    width: 135px;
    height: 135px;
    background: #E5FFFE;
    animation: jumpThree 9s infinite linear;
}

.partner-section-two .logo-wrapper .pic:nth-child(1) {
    width: 175px;
    height: auto;
    background: #FFE3E3;
    animation: jumpTwo 9s infinite linear;
}

.partner-section-two .logo-wrapper .pic:nth-child(2) {
    width: 120px;
    height: auto;
    background: #E8F8FF;
    animation: jumpThree 9s infinite linear;
}

.partner-section-two .logo-wrapper .pic:nth-child(3) {
    width: 175px;
    height: 175px;
    background: #EAFFE7;
    animation: jumpTwo 9s infinite linear;
}

.partner-section-two .logo-wrapper .pic:nth-child(4) {
    width: 150px;
    height: 150px;
    background: #F3F1FF;
    animation: jumpThree 9s infinite linear;
}

.partner-section-two .logo-wrapper .pic:nth-child(5) {
    width: 135px;
    height: 135px;
    background: #F0F5EF;
    animation: jumpThree 9s infinite linear;
}

.partner-section-two .logo-wrapper .pic:nth-child(6) {
    width: 195px;
    height: 195px;
    background: #FFF9E5;
    animation: jumpTwo 9s infinite linear;
}

.partner-section-two .logo-wrapper .pic:nth-child(7) {
    width: 135px;
    height: 135px;
    background: #E5FFFE;
    animation: jumpThree 9s infinite linear;
}

.partner-section-two .shape-one {
    top: -30%;
    left: 36%;
    animation: jumpThree 3.5s infinite linear;
}

.partner-section-two .shape-two {
    top: 55%;
    left: 8%;
    animation: jumpFour 3.5s infinite linear;
}

.partner-section-two .shape-three {
    bottom: -32%;
    left: 34%;
    animation: jumpTwo 3.5s infinite linear;
}


/*^^^^^^^^^^^^^^^^^^^^^ Extra for news stuff ^^^^^^^^^^^^^^^^^^^^^^^^*/

.partner-section-two .illustration-holder {
    position: absolute;
    width: 55%;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.partner-section-two .illustration-holder .main-img {
    border-radius: 0 25px 25px 0;
    margin-right: -10px;
}

.partner-section-two .illustration-holder .screen-one {
    z-index: 1;
    left: -8%;
    bottom: -4%;
    border-radius: 15px;
    width: 41.4%;
}

.partner-section-two .illustration-holder .shape-one {
    right: 0;
    transform: translateY(-50%);
}


/*^^^^^^^^^^^^^^^^^^^^^ Fancy Short Banner Four ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-short-banner-four {
    z-index: 5;
    margin-bottom: -170px;
}

.address-block-one {
    position: relative;
    padding: 0 20px 40px 140px;
    background: #FFFFFF;
    border-radius: 3px;
    overflow: hidden;
    width: 100%;
}

.address-block-one h4 {
    font-size: 24px;
    margin-bottom: 11px;
}

.address-block-one .icon {
    left: -2px;
    top: 12px;
}

.address-block-one .text-meta p a {
    font-size: 24px;
    color: var(--prime-three);
}

.address-block-one .text-meta p a:hover {
    text-decoration: underline;
}


/*^^^^^^^^^^^^^^^^^^^^^ Footer Three ^^^^^^^^^^^^^^^^^^^^^^^^*/

.footer-style-three {
    background: #623BFF;
    z-index: 1;
    padding: 285px 0 210px;
}

.footer-style-three:before {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    z-index: 0;
    border: 0 solid transparent;
    border-left-width: 100vw;
    border-left-color: #fff;
    border-bottom-width: 9rem;
    top: 0;
}

.footer-style-three .subscribe-form {
    max-width: 620px;
}

.footer-style-three .subscribe-form form {
    height: 70px;
}

.footer-style-three .subscribe-form input {
    width: 100%;
    height: 100%;
    border: none;
    background: #fff;
    border-radius: 35px;
    padding: 0 180px 0 40px;
}

.footer-style-three .subscribe-form button {
    width: 180px;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 0 35px 35px 0;
    background: #FF43A1;
    color: #fff;
}

.footer-style-three .subscribe-form button:hover {
    background: var(--prime-two);
}

.footer-style-three .bottom-footer h4 {
    font-size: 22px;
    letter-spacing: -0.5px;
    margin-bottom: 25px;
}

.footer-style-three .bottom-footer .social-icon a {
    width: 37px;
    height: 37px;
    border: 1px solid #fff;
    border-radius: 50%;
    line-height: 35px;
    color: #fff;
    margin: 0 6px;
    transition: all 0.3s ease-in-out;
}

.footer-style-three .bottom-footer .social-icon a:hover {
    background: var(--prime-two);
    border-color: var(--prime-two);
}

.footer-style-three .bottom-footer .mail {
    color: rgba(255, 255, 255, 0.95);
}

.footer-style-three .bottom-footer .mail:hover {
    text-decoration: underline;
    color: #fff;
}

.footer-style-three .bg-illustration {
    bottom: 0;
    left: 0;
    animation: fade-in 2.5s infinite linear;
}


/*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner Four ^^^^^^^^^^^^^^^^^^^^^^^^*/

.hero-banner-four {
    max-width: 1574px;
    margin: 250px auto 0;
    z-index: 1;
}

.hero-banner-four .avatar-img {
    width: 90px;
    height: 90px;
}

.hero-banner-four .hero-heading {
    font-size: 120px;
    line-height: 1.08em;
    letter-spacing: -1px;
}

.hero-banner-four .fancy-img-block {
    position: absolute;
    z-index: -1;
    width: 24%;
}

.hero-banner-four .fancy-block-one {
    top: 0;
    left: 12px;
}

.hero-banner-four .fancy-block-one .main-img {
    border-radius: 0 200px 0 200px;
}

.hero-banner-four .fancy-block-two .main-img {
    border-radius: 200px 0 200px 0;
}

.hero-banner-four .fancy-block-two {
    bottom: 0;
    right: 12px;
}

.hero-banner-four .fancy-img-block .r-shape-one {
    width: 67px;
    height: 134px;
    left: 0;
    top: 0;
    background: #811EFF;
    border-radius: 0 100px 100px 0;
}

.hero-banner-four .fancy-img-block .r-shape-two {
    width: 55px;
    height: 55px;
    left: 47%;
    bottom: -10px;
    background: #00E9AF;
    animation: jumpTwo 4s infinite linear;
}

.hero-banner-four .fancy-img-block .r-shape-three {
    width: 46px;
    height: 46px;
    left: 53%;
    top: -23px;
    background: #FF5A8A;
    animation: jumpThree 4s infinite linear;
}

.hero-banner-four .fancy-img-block .r-shape-four {
    width: 157px;
    height: 157px;
    right: -6%;
    bottom: 0;
    background: #FFC962;
    animation: jumpFour 3.5s infinite linear;
}

.hero-banner-four .shape-one {
    top: -8%;
    left: 27%;
    animation: jumpThree 4s infinite linear;
}

.hero-banner-four .shape-two {
    top: 1%;
    right: 15%;
    animation: jumpTwo 4s infinite linear;
}

.hero-banner-four .shape-three {
    bottom: -3%;
    left: 15%;
    animation: jumpFour 4s infinite linear;
}

.hero-banner-four .shape-four {
    bottom: -11%;
    right: 26%;
    animation: jumpTwo 4s infinite linear;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Twelve ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-twelve .main-count {
    font-size: 100px;
    letter-spacing: -1px;
    color: #815AFF;
}

.fancy-feature-twelve .title {
    font-size: 36px;
    line-height: 1.5em;
}

.fancy-feature-twelve .block-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 48px;
    width: 64vw;
}

.card-style-six {
    padding: 95px 15px 80px;
    border-radius: 15px;
}

.card-style-six .icon {
    height: 85px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Portfolio Gallery One ^^^^^^^^^^^^^^^^^^^^^^^^*/

.portfolio-gallery-one .shape-one {
    top: 0;
    left: 24%;
}

.portfolio-gallery-one .shape-two {
    top: 190px;
    left: 4%;
}

.portfolio-gallery-one .shape-three {
    top: 190px;
    left: 41%;
}

.g-control-nav-one li {
    font-size: 20px;
    letter-spacing: -0.05px;
    color: #C8C8C8;
    display: inline-block;
    padding: 0 5px;
    margin-left: 45px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.g-control-nav-one li.is-checked {
    font-weight: 500;
    color: #000;
}


/*2 column*/

.grid-2column {
    margin: 0 -20px;
}

.grid-2column .isotop-item,
.grid-2column .grid-sizer {
    width: 50%;
    padding: 0 20px;
}


/*3 column*/

.grid-3column {
    margin: 0 -20px;
}

.grid-3column .isotop-item,
.grid-3column .grid-sizer {
    width: 33.3333%;
    padding: 0 20px;
}

.portfolio-block-one {
    position: relative;
    z-index: 5;
}

.portfolio-block-one .title {
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 20px;
    box-shadow: 0px 15px 40px rgba(0, 41, 121, 0.04);
    border-radius: 15px;
    background: #fff;
    padding: 35px 15px 40px;
    z-index: 2;
    text-align: center;
    opacity: 0;
    visibility: hidden;
}

.portfolio-block-one .title .tag {
    font-size: 17px;
    margin-bottom: 5px;
    color: rgba(0, 40, 78, 0.5);
}

.portfolio-block-one .title .pj-name {
    font-size: 22px;
    color: var(--heading);
}

.portfolio-block-one:hover .title {
    transform: translateY(-10px);
    opacity: 1;
    visibility: visible;
}

.portfolio-block-one .title:hover .pj-name {
    color: var(--prime-three);
}

.portfolio-block-one .hover-state {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1;
    opacity: 0;
    visibility: hidden;
}

.portfolio-block-one .hover-state a {
    display: block;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    line-height: 55px;
    text-align: center;
    color: #fff;
    font-size: 35px;
    background: rgba(255, 255, 255, 0.3);
    margin: 30% auto 0;
    transform: translateY(30px);
    transition-delay: 0.05s;
}

.portfolio-block-one .hover-state a:hover {
    background: var(--prime-two);
}

.portfolio-block-one:hover .hover-state {
    opacity: 1;
    visibility: visible;
}

.portfolio-block-one:hover .hover-state a {
    transform: translateY(0);
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Thirteen ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-thirteen .skils-list {
    margin: 0 -10px;
}

.fancy-feature-thirteen .skils-list li {
    margin: 20px 10px 0;
    font-size: 24px;
    color: #000;
}

.fancy-feature-thirteen .skils-list li span {
    display: block;
    line-height: 75px;
    padding: 0 30px;
    border-radius: 10px;
    font-size: 18px;
}

.fancy-feature-thirteen .text-xxl {
    font-size: 28px;
    line-height: 1.64em;
}

.fancy-feature-thirteen .tx-sp1 {
    font-size: 25px;
    margin-bottom: -15px;
}

.fancy-feature-thirteen .tx-sp1 span {
    font-size: 72px;
}

.fancy-feature-thirteen .tx-sp2 {
    font-size: 14px;
    letter-spacing: 1px;
    color: rgba(0, 0, 0, 0.35);
}

.fancy-feature-thirteen .tools-list li+li {
    margin-top: 22px;
}

.fancy-feature-thirteen .img-meta .text-meta {
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translateY(-50%);
}

.fancy-feature-thirteen .img-meta .text-meta .tm-heading {
    font-family: 'eustacheregular';
    font-size: 62px;
    letter-spacing: 2px;
    color: #FFC962;
}

.fancy-feature-thirteen .img-meta .text-meta h3 {
    font-size: 60px;
    line-height: 1.2em;
    letter-spacing: -1px;
}

.fancy-feature-thirteen .img-meta .text-meta h3 a {
    font-size: 0.65em;
}

.fancy-feature-thirteen .img-meta .text-meta h3 a:hover {
    color: #FFC962;
}

.fancy-feature-thirteen .img-meta .shape-one {
    width: 52px;
    height: 52px;
    left: 15%;
    top: 12%;
    background: #31DAFF;
    z-index: 1;
}

.fancy-feature-thirteen .img-meta .shape-two {
    width: 132px;
    height: 132px;
    right: 3%;
    bottom: 10%;
    background: #FFC962;
    z-index: 1;
}

.fancy-feature-thirteen .fancy-text {
    font-size: 200px;
    font-weight: 100;
    letter-spacing: -14px;
    color: rgba(0, 0, 0, 0.04);
    position: absolute;
    right: 5%;
    top: -5%;
}


/*^^^^^^^^^^^^^^^^^^^^^ Counter Section One ^^^^^^^^^^^^^^^^^^^^^^^^*/

.counter-section-one {
    background-image: url(../images/assets/bg-5.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #F2F4F8;
    z-index: 1;
}

.counter-section-one .shape-one {
    top: 12%;
    left: 7%;
}

.counter-section-one .shape-two {
    bottom: 17%;
    left: 6%;
}

.counter-section-one .shape-three {
    top: 16%;
    right: 5%;
}

.counter-section-one .shape-four {
    bottom: 16%;
    right: 5%;
}

.counter-block-one .icon-bg {
    width: 230px;
    height: 310px;
    background: #fff;
    border-radius: 120px;
    margin: 0 auto;
    padding-top: 70px;
}

.counter-block-one .icon-img {
    height: 114px;
}

.counter-block-one .icon-title {
    letter-spacing: 1px;
}

.counter-block-one .main-count {
    font-size: 60px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feedback Section Four ^^^^^^^^^^^^^^^^^^^^^^^^*/

.feedback-section-four .slider-wrapper {
    margin: 0 -20px;
}

.feedback-section-four .slider-wrapper .item {
    margin: 0 20px;
}

.feedback-block-four {
    padding: 40px 48px 45px;
    border: 1px solid #EAEAEA;
    border-radius: 12px;
    overflow: hidden;
}

.feedback-block-four .icon {
    width: 52px;
    height: 52px;
    background: #000;
}

.feedback-block-four p {
    font-size: 20px;
    line-height: 38px;
    margin: 32px 0 80px;
}

.feedback-block-four .rating li {
    font-size: 17px;
    color: #FFC12B;
    margin-left: 10px;
}

.feedback-block-four .ribbon {
    height: 7px;
    left: -1px;
    right: -1px;
    bottom: -1px;
}

.feedback-section-four .slick-dots {
    text-align: center;
    margin: 80px 0 0;
}

.feedback-section-four .slick-dots li.slick-active button {
    background: #000;
    transform: none;
}


/*^^^^^^^^^^^^^^^^^^^^^ Fancy Short Banner Five ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-short-banner-five .bg-wrapper {
    background: #FFF5EC;
    z-index: 2;
}

.fancy-short-banner-five .shape-one {
    top: 10%;
    left: 5%;
}

.fancy-short-banner-five .shape-two {
    bottom: 7%;
    left: 40%;
}

.fancy-short-banner-five .shape-three {
    bottom: 41%;
    right: 4%;
}

.fancy-short-banner-five .r-shape-one {
    width: 67px;
    height: 134px;
    left: 0;
    bottom: 0;
    background: #811EFF;
    border-radius: 0 100px 100px 0;
}

.fancy-short-banner-five .r-shape-two {
    width: 108px;
    height: 108px;
    background: #FFD74A;
    top: -60px;
    right: -60px;
    z-index: -1;
}

.fancy-short-banner-five .innner-content {
    border-radius: 20px;
    padding: 85px 90px 85px;
}

.fancy-short-banner-five .title-xl {
    font-size: 100px;
    line-height: 1.1em;
}

.fancy-short-banner-five .title-xl span {
    z-index: 1;
}

.fancy-short-banner-five .title-xl span:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 17px;
    width: 100%;
    height: 15px;
    z-index: -1;
    background: #FFDEFA;
}

.form-style-one .input-group-meta input,
.form-style-one .input-group-meta textarea {
    width: 100%;
    max-width: 100%;
    border: none;
    border-bottom: 2px solid #000;
    color: #000;
    font-size: 18px;
}

.form-style-one .input-group-meta input {
    height: 62px;
}

.form-style-one .input-group-meta textarea {
    height: 122px;
    max-height: 122px;
}

.form-style-one .send-btn {
    line-height: 60px;
    font-size: 14px;
    letter-spacing: 0.88px;
    background: #000;
}

.form-style-one .send-btn:hover {
    background: var(--prime-three);
}


/*^^^^^^^^^^^^^^^^^^^^^ Footer Four ^^^^^^^^^^^^^^^^^^^^^^^^*/

.footer-style-four {
    padding: 70px 0 40px;
}

.footer-style-four .social-icon a {
    font-size: 22px;
    margin: 0 13px;
    color: rgba(0, 0, 0, 0.7);
    transition: all 0.3s ease-in-out;
}

.footer-style-four .social-icon a:hover {
    color: var(--prime-two);
}

.footer-style-four .mail {
    font-size: 20px;
    color: #000;
}

.footer-style-four .mail .icon {
    width: 42px;
    height: 42px;
    color: #fff;
    font-size: 20px;
    margin-right: 15px;
    background: #000;
}


/*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner Five ^^^^^^^^^^^^^^^^^^^^^^^^*/

.hero-banner-five {
    background: url(../images/assets/hero_01.png) no-repeat center bottom;
    background-size: cover;
    z-index: 1;
    padding: 300px 0 300px;
}

.hero-banner-five .hero-heading {
    font-family: 'eustacheregular';
    font-size: 170px;
    line-height: 0.73em;
    letter-spacing: -2px;
}

.hero-banner-five .hero-heading span img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    max-width: initial;
}

.hero-banner-five .donate-btn {
    font-size: 17px;
    line-height: 60px;
    border-radius: 30px;
    color: #000;
    padding: 0 48px;
    background: var(--prime-four);
}

.hero-banner-five .donate-btn:hover {
    background: #fff;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Fourteen ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-fourteen {
    z-index: 2;
    margin-top: -85px;
}

.card-style-seven {
    background: #fff;
    border-radius: 10px;
    padding: 55px 15px 42px;
}

.card-style-seven:hover {
    transform: translateY(-5px);
}

.card-style-seven .icon {
    height: 70px;
}

.card-style-seven .icon img {
    max-height: 100%;
}

.card-style-seven .read-btn {
    color: #A4A4A4;
    font-size: 16px;
}

.card-style-seven .read-btn:hover {
    color: var(--prime-four);
    text-decoration: underline;
}

.card-style-seven .ribbon {
    width: 100%;
    height: 5px;
    left: 0;
    bottom: 0;
    border-radius: 3px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Fifteen ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-fifteen {
    z-index: 1;
}

.fancy-feature-fifteen .sp-text1 {
    line-height: 2.1em;
}

.fancy-feature-fifteen .video-icon {
    width: 105px;
    height: 105px;
    background: var(--prime-four);
    color: #fff;
    font-size: 70px;
    padding-left: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.fancy-feature-fifteen .video-icon:hover {
    background: var(--prime-three);
}

.counter-block-two .main-count {
    font-size: 60px;
    margin-bottom: -10px;
}

.counter-block-two .bg-img {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.fancy-feature-fifteen .shape-one {
    left: 0;
    top: 25%;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Sixteen ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-sixteen {
    background: #F2ECE9;
}

.fancy-feature-sixteen .inner-wrapper {
    max-width: 1744px;
    margin: 0 auto;
    padding-left: 12px;
    padding-right: 12px;
}

.card-style-eight {
    border-radius: 15px;
    padding: 15px;
    background: #fff;
}

.card-style-eight .img-meta {
    border-radius: 10px;
    overflow: hidden;
    z-index: 1;
}

.card-style-eight .img-meta:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 60%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.586528) 100%);
}

.card-style-eight .img-meta .donation-goal {
    left: 20px;
    right: 20px;
    bottom: 30px;
    z-index: 1;
}

.card-style-eight .img-meta .ribbon {
    height: 15px;
    border-radius: 0 30px 30px 0;
    bottom: 0;
    left: 0;
}

.fancy-feature-sixteen .ins-title {
    font-family: 'Noteworthy Light';
    font-size: 24px;
    letter-spacing: 2px;
    color: #FF5858;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Seventeen ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-seventeen {
    z-index: 1;
}

.fancy-feature-seventeen .shape-one {
    top: 29%;
    right: 21%;
    z-index: 1;
}

.fancy-feature-seventeen .shape-two {
    bottom: 7%;
    right: 14%;
    z-index: 1;
}

.fancy-feature-seventeen .shape-three {
    top: 38%;
    left: 0;
}

.fancy-feature-seventeen .shape-three .text {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.fancy-feature-seventeen .shape-three .sp-text1 {
    font-family: 'Noteworthy Light';
    font-size: 36px;
    letter-spacing: 2.5px;
}

.fancy-feature-seventeen .shape-four {
    right: 0;
    top: -5%;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Eighteen ^^^^^^^^^^^^^^^^^^^^^^^^*/

.card-style-nine {
    height: 430px;
    width: 33.3333%;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
}

.card-style-nine:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: -1;
}

.card-style-nine.center-item:after {
    content: '';
    position: absolute;
    left: 25px;
    right: 25px;
    top: 25px;
    bottom: 25px;
    background: rgba(255, 221, 78, 0.85);
    z-index: -1;
}

.card-style-nine .icon {
    height: 90px;
}

.card-style-nine .icon img {
    max-height: 100%;
}

.card-style-nine .title {
    font-family: 'Noteworthy Light';
    font-size: 42px;
    letter-spacing: 4.67px;
}

.card-style-nine .btn-more {
    width: 55px;
    height: 55px;
    line-height: 55px;
    color: #000;
    font-size: 36px;
    background: var(--prime-four);
}

.card-style-nine .btn-more:hover {
    background: #fff;
}


/*^^^^^^^^^^^^^^^^^^^^^ Team Section One ^^^^^^^^^^^^^^^^^^^^^^^^*/

.team-section-one {
    z-index: 1;
}

.team-section-one .shape-one {
    left: 0;
    top: -5%;
}

.team-section-one .shape-two {
    right: 0;
    bottom: 5%;
}

.team-block-one .img-meta:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 34%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
}

.team-block-one .img-meta .info {
    left: 0;
    right: 0;
    bottom: 30px;
    z-index: 1;
}


/*^^^^^^^^^^^^^^^^^^^^^ Fancy Short Banner Six ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-short-banner-six {
    background: #fff;
}

.fancy-short-banner-six .block-wrapper {
    width: 50%;
}

.fancy-short-banner-six .block-wrapper .inner-wrapper {
    width: 660px;
}

.fancy-short-banner-six .block-wrapper .main-title {
    font-size: 70px;
}

.fancy-short-banner-six .left-side {
    background: url(../images/media/img_38.jpg) no-repeat center;
    background-size: cover;
    height: 850px;
}

.fancy-short-banner-six .block-wrapper .btn-nine {
    font-size: 14px;
}

.fancy-short-banner-six .form-title {
    font-size: 42px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Blog Section Two ^^^^^^^^^^^^^^^^^^^^^^^^*/

.blog-meta-two {
    background: #fff;
    border-radius: 5px;
    padding: 45px 45px;
}

.blog-meta-two .tag {
    font-size: 13px;
    letter-spacing: 1px;
    line-height: 25px;
    padding: 0 10px;
    border-radius: 3px;
    color: #000;
}

.blog-meta-two .blog-title h4:hover {
    color: var(--prime-four);
}


/*^^^^^^^^^^^^^^^^^^^^^ Partner Section Three ^^^^^^^^^^^^^^^^^^^^^^^^*/

.partner-section-three .title {
    font-size: 20px;
    letter-spacing: 2px;
}

.partner-section-three .title span {
    font-family: 'Noteworthy Light';
    font-size: 32px;
    letter-spacing: 3.2px;
    color: #FF3636;
    text-decoration: underline;
    text-decoration-thickness: 1px;
}

.partner_slider_one .item {
    margin: 0 12px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Fancy Short Banner Seven ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-short-banner-seven {
    background: url(../images/media/img_39.jpg) no-repeat center;
    background-size: cover;
    z-index: 1;
}

.fancy-short-banner-seven:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    z-index: -1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 80%);
}

.fancy-short-banner-seven .subscribe-form {
    max-width: 620px;
}

.fancy-short-banner-seven .subscribe-form form {
    height: 70px;
}

.fancy-short-banner-seven .subscribe-form input {
    width: 100%;
    height: 100%;
    border: 2px solid #E3E3E3;
    background: transparent;
    border-radius: 35px;
    padding: 0 180px 0 40px;
    color: #fff;
}

.fancy-short-banner-seven .subscribe-form button {
    width: 180px;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 0 35px 35px 0;
    background: var(--prime-four);
    color: #000;
}

.fancy-short-banner-seven .subscribe-form button:hover {
    background: var(--prime-two);
    color: #fff;
}

.fancy-short-banner-seven ::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
}

.fancy-short-banner-seven :-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
}

.fancy-short-banner-seven ::placeholder {
    color: rgba(255, 255, 255, 0.8);
}


/*^^^^^^^^^^^^^^^^^^^^^ Footer Five ^^^^^^^^^^^^^^^^^^^^^^^^*/

.footer-style-five {
    background: #000;
    padding: 90px 0 35px;
}

.footer-style-five .social-icon a {
    width: 34px;
    height: 34px;
    color: #fff;
    border: 1px solid #5A5A5A;
    border-radius: 7px;
    text-align: center;
    line-height: 32px;
    margin-right: 10px;
    transition: all 0.3s ease-in-out;
}

.footer-style-five .social-icon a:hover {
    background: rgba(255, 255, 255, 0.14);
    border-color: #000;
}

.theme-basic-footer[class*="-five"] .footer-nav-link a,
.theme-basic-footer[class*="-five"] {
    color: rgba(255, 255, 255, 0.7);
}

.theme-basic-footer[class*="-five"] .footer-nav-link a:hover,
.theme-basic-footer[class*="-five"] .email {
    color: #fff;
}

.theme-basic-footer[class*="-five"] .mobile,
.theme-basic-footer[class*="-five"] .email:hover,
.footer-style-five .bottom-footer .footer-nav a:hover {
    color: var(--prime-four);
}

.footer-style-five .bottom-footer {
    padding-top: 70px;
}

.footer-style-five .bottom-footer .footer-nav a {
    color: #fff;
    margin-left: 35px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner Six ^^^^^^^^^^^^^^^^^^^^^^^^*/

.hero-banner-six .hero-heading {
    font-size: 81px;
    line-height: 1.14em;
    letter-spacing: -2px;
}

.hero-banner-six .hero-heading span:before {
    content: '';
    position: absolute;
    width: 96%;
    height: 14px;
    bottom: 15px;
    left: 9px;
    background: rgba(255, 139, 37, 0.2);
    z-index: -1;
}

.hero-banner-six .subscribe-form {
    max-width: 600px;
}

.hero-banner-six .subscribe-form form {
    height: 70px;
}

.hero-banner-six .subscribe-form input {
    width: 100%;
    height: 100%;
    border: none;
    background: #F2F2F2;
    border-radius: 35px;
    padding: 0 180px 0 40px;
}

.hero-banner-six .subscribe-form button {
    width: 170px;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 0 35px 35px 0;
    background: var(--prime-two);
    color: #fff;
}

.hero-banner-six .subscribe-form button:hover {
    background: var(--prime-five);
}

.hero-banner-six .subscribe-form p a {
    color: var(--prime-two);
}

.hero-banner-six .shape-one {
    width: 36px;
    height: 36px;
    left: 10%;
    top: 14%;
    background: #74E2CD;
    animation: jumpTwo 6s infinite linear;
}

.hero-banner-six .shape-two {
    left: 3%;
    top: 25%;
    animation: jumpThree 6s infinite linear;
}

.hero-banner-six .shape-three {
    width: 20px;
    height: 20px;
    left: 19%;
    top: 28%;
    transform: rotate(45deg);
    background: #BCD5FF;
}

.hero-banner-six .shape-four {
    left: 7%;
    top: 41%;
}

.hero-banner-six .shape-five {
    right: 12%;
    top: 13%;
    animation: rotated 25s infinite linear;
}

.hero-banner-six .shape-six {
    width: 12px;
    height: 12px;
    right: 5%;
    top: 15%;
    background: #74E2CD;
}

.hero-banner-six .shape-seven {
    width: 36px;
    height: 36px;
    right: 18%;
    top: 29%;
    background: #FF5C33;
    animation: jumpThree 5s infinite linear;
}

.hero-banner-six .shape-eight {
    right: 4%;
    top: 27%;
}

.hero-banner-six .shape-nine {
    right: 8%;
    top: 43%;
    animation: jumpTwo 6s infinite linear;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Nineteen ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-nineteen {
    background: #F5F5F5;
    z-index: 1;
}

.fancy-feature-nineteen:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 40px;
    background: url(../images/shape/shape_86.svg) no-repeat center bottom;
    background-size: cover;
    left: 0;
    bottom: -38px;
    z-index: -1;
}

.fancy-feature-nineteen .shape-one {
    width: 36px;
    height: 36px;
    right: 6%;
    top: -18px;
    background: #00D8AF;
}

.fancy-feature-nineteen .shape-two {
    width: 10px;
    height: 10px;
    left: 3%;
    top: 60%;
    background: #288AFF;
    animation: jumpThree 5s infinite linear;
}

.fancy-feature-nineteen .shape-three {
    width: 36px;
    height: 36px;
    left: 26%;
    bottom: -45px;
    background: #FF7228;
}

.card-style-ten {
    border-radius: 10px;
    width: 100%;
}

.card-style-ten .icon {
    height: 65px;
}

.card-style-ten .icon img {
    max-height: 100%;
}

.card-style-ten .read-btn:hover {
    transform: translateX(3px);
}

.counter-block-three .main-count {
    font-size: 60px;
    margin-bottom: -9px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Twenty One ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-twentyOne {
    background: #F5F5F5;
    z-index: 1;
}

.fancy-feature-twentyOne:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 40px;
    background: url(../images/shape/shape_86.svg) no-repeat center bottom;
    background-size: cover;
    left: 0;
    bottom: -38px;
    z-index: -2;
}

.fancy-feature-twentyOne:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 26px;
    background: url(../images/shape/shape_87.svg) no-repeat center top;
    background-size: cover;
    left: 0;
    top: -25px;
    z-index: -1;
}

.fancy-feature-twentyOne .block h4 {
    font-size: 26px;
}

.fancy-feature-twentyOne .shape-one {
    width: 36px;
    height: 36px;
    right: 21%;
    top: -40px;
    background: #007BFF;
}

.fancy-feature-twentyOne .shape-two {
    width: 36px;
    height: 36px;
    left: 30%;
    bottom: -40px;
    background: #FF7228;
}

.fancy-feature-twentyOne .shape-three {
    width: 10px;
    height: 10px;
    left: 4%;
    top: 43%;
    background: #00D8AF;
    animation: jumpThree 5s infinite linear;
}

.fancy-feature-twentyOne .shape-four {
    left: 0;
    bottom: 6%;
}

.fancy-feature-twentyOne .shape-five {
    right: 0;
    top: 6%;
}


/*^^^^^^^^^^^^^^^^^^^^^ Pricing Section Three ^^^^^^^^^^^^^^^^^^^^^^^^*/

.pricing-nav-three .nav-item .nav-link {
    font-size: 18px;
    line-height: 60px;
    border: 1px solid #E6E6E6;
    padding: 0 15px;
    width: 150px;
    text-align: center;
    color: #000;
    transition: all 0.3s ease-in-out;
}

.pricing-nav-three .nav-item:nth-child(1) .nav-link {
    border-right: none;
    border-radius: 45px 0 0 45px;
}

.pricing-nav-three .nav-item:nth-child(2) .nav-link {
    border-left: none;
    border-radius: 0 45px 45px 0;
}

.pricing-nav-three .nav-item .nav-link.active {
    background: var(--prime-two);
    border-color: var(--prime-two);
    color: #fff;
}

.pricing-table-area-three .pr-column {
    padding: 60px 10px 50px 45px;
    border: 1px solid #E3E3E3;
    position: relative;
}

.pricing-table-area-three .tab-content .pr-one {
    border-radius: 15px 0 0 15px;
    border-right: none;
}

.pricing-table-area-three .tab-content .pr-two {
    border-left: none;
}

.pricing-table-area-three .tab-content .pr-three {
    border-radius: 0 15px 15px 0;
    border-left: none;
}

.pricing-table-area-three .tab-content .pr-column.active {
    border-radius: 15px;
    border: none;
    background: #FFFFFF;
    box-shadow: 0px 25px 60px rgba(0, 13, 48, 0.0480786);
}

.pricing-table-area-three .tab-content .pr-column.active:before {
    content: url(../images/shape/popular-tag.svg);
    position: absolute;
    top: -80px;
    right: -75px;
}

.pricing-table-area-three .pr-column .icon {
    height: 25px;
}

.pricing-table-area-three .pr-column .plan {
    font-size: 32px;
    margin: 26px 0 22px;
}

.pricing-table-area-three .pr-body li {
    font-size: 18px;
    padding-left: 28px;
    margin-bottom: 15px;
    position: relative;
}

.pricing-table-area-three .pr-body li:before {
    content: "\f272";
    font-family: bootstrap-icons;
    font-weight: 700;
    position: absolute;
    left: 0;
    top: 0;
    color: var(--prime-two);
}

.pricing-table-area-three .pr-column .price {
    font-size: 28px;
}

.pricing-table-area-three .tab-content .pr-column.active .btn-twelve {
    background: var(--prime-two);
    color: #fff;
}

.pricing-section-three .shape-one {
    width: 12px;
    height: 12px;
    left: 12%;
    top: 18%;
    background: #74E2CD;
    animation: jumpThree 5s infinite linear;
}

.pricing-section-three .shape-two {
    width: 27px;
    height: 27px;
    right: 10%;
    top: 29%;
    background: #007BFF;
    animation: jumpTwo 5s infinite linear;
}


/*^^^^^^^^^^^^^^^^^^^^^ Fancy Short Banner Eight ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-twentyTwo {
    background: #F5F5F5;
    z-index: 1;
}

.fancy-feature-twentyTwo:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 26px;
    background: url(../images/shape/shape_87.svg) no-repeat center top;
    background-size: cover;
    left: 0;
    top: -25px;
    z-index: -1;
}

.fancy-feature-twentyTwo:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 40px;
    background: url(../images/shape/shape_86.svg) no-repeat center bottom;
    background-size: cover;
    left: 0;
    bottom: -38px;
    z-index: -2;
}

.fancy-feature-twentyTwo .shape-three {
    width: 30px;
    height: 30px;
    left: 43%;
    top: -32px;
    background: #007BFF;
}

.fancy-feature-twentyTwo .shape-four {
    width: 14px;
    height: 14px;
    right: 7%;
    top: 24%;
    background: #FF9548;
    animation: jumpThree 5s infinite linear;
}

.fancy-feature-twentyTwo .shape-five {
    width: 8px;
    height: 8px;
    left: 39%;
    top: 47%;
    background: #00DFEF;
    animation: jumpTwo 5s infinite linear;
}

.fancy-feature-twentyTwo .shape-six {
    width: 11px;
    height: 11px;
    left: 8%;
    bottom: 23%;
    background: #007BFF;
    animation: jumpThree 5s infinite linear;
}

.fancy-feature-twentyTwo .shape-seven {
    right: 0;
    top: -90px;
}

.fancy-feature-twentyTwo .shape-eight {
    left: 0;
    bottom: -90px;
}

.fancy-short-banner-eight .ur-text,
.fancy-short-banner-eight .call-btn a:hover {
    color: var(--prime-two);
}

.fancy-short-banner-eight .call-btn a {
    font-size: 26px;
}

.form-style-two .input-group-meta input,
.form-style-two .input-group-meta textarea {
    width: 100%;
    max-width: 100%;
    border: 2px solid rgba(221, 221, 221, 0.8);
    border-radius: 5px;
    color: #000;
    font-size: 15px;
}

.form-style-two .input-group-meta input {
    height: 60px;
    padding: 0 20px;
}

.form-style-two .input-group-meta textarea {
    height: 120px;
    max-height: 120px;
    padding: 15px 20px;
}

.form-style-two .btn-thirteen {
    font-size: 14px;
    letter-spacing: 0.88px;
}

.fancy-feature-twentyTwo .shape-one {
    left: -35px;
    top: -10px;
    z-index: 1;
}

.fancy-feature-twentyTwo .shape-two {
    left: -60px;
    bottom: -60px;
}

.fancy-feature-twentyTwo .shape-text {
    line-height: 24px;
    left: -80px;
    top: -62px;
    transform: rotate(-15deg);
}


/*^^^^^^^^^^^^^^^^^^^^^ Feedback Section Five ^^^^^^^^^^^^^^^^^^^^^^^^*/

.feedback-section-five .slider-wrapper {
    width: calc(1320px + 30vw);
    margin: 0 -20px;
}

.feedback_slider_five .item {
    margin: 0 20px;
}

.feedback-block-five {
    border-radius: 10px;
    padding: 40px 45px;
}

.feedback-block-five .icon {
    width: 60px;
    height: 60px;
}

.feedback-block-five p {
    font-size: 20px;
}

.feedback-block-five .name span {
    font-size: 16px;
    color: #C1C1C1;
    margin-top: 3px;
}

.feedback-block-five .avatar {
    width: 45px;
    height: 45px;
}

.feedback-section-five .slick-arrow-three {
    position: absolute;
    right: 0;
    top: 48px;
}

.slick-arrow-three li {
    width: 50px;
    height: 50px;
    line-height: 48px;
    border: 1px solid #D2D2D2;
    color: #D2D2D2;
    margin-left: 15px;
    cursor: pointer;
}

.slick-arrow-three li:hover {
    background: #FFFFFF;
    border-color: #fff;
    color: #000;
}


/*^^^^^^^^^^^^^^^^^^^^^ Blog Section Three ^^^^^^^^^^^^^^^^^^^^^^^^*/

.blog-section-three .btn-twentyTwo {
    position: absolute;
    right: 0;
    top: 8px;
}

.blog-meta-three .post-img {
    border-radius: 10px;
    overflow: hidden;
}

.blog-meta-three .post-date {
    font-size: 14px;
    letter-spacing: 1px;
}

.blog-meta-three .blog-title {
    font-size: 26px;
    line-height: 1.53em;
}

.blog-meta-three .blog-title.xl {
    font-size: 32px;
}

.blog-meta-three .blog-title:hover {
    color: var(--prime-five);
}

.blog-meta-three .read-btn {
    line-height: 45px;
    border: 1px solid #DBDBDB;
    border-radius: 23px;
    padding: 0 32px;
    color: var(--prime-two);
}

.blog-meta-three .read-btn:hover {
    border-color: var(--prime-two);
    background: var(--prime-two);
    color: #fff;
}

.blog-meta-three .read-btn-two {
    line-height: 45px;
    border: 1px solid var(--prime-ten);
    border-radius: 23px;
    padding: 0 32px;
    color: var(--prime-ten);
}

.blog-meta-three .read-btn-two:hover {
    background: var(--prime-ten);
    color: #fff;
}

.blog-meta-three.text-style {
    padding: 35px 50px 40px;
    border: 2px solid #000;
    border-radius: 8px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Footer Six ^^^^^^^^^^^^^^^^^^^^^^^^*/

.footer-style-six {
    background: url(../images/assets/footerbg.png) no-repeat top center;
    padding-top: 150px;
}

.theme-basic-footer.footer-style-six .footer-title {
    color: rgba(11, 11, 11, 0.35);
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.theme-basic-footer.footer-style-six .footer-nav-link a {
    line-height: 40px;
}

.footer-style-six .social-icon a {
    width: 35px;
    height: 35px;
    border: 1px solid #D7D7D7;
    border-radius: 5px;
    text-align: center;
    padding: 5px 7px 5px 7px;
    margin-top: 5px;
    line-height: 33px;
    color: rgba(0, 0, 0, 0.5);
    margin-right: 10px;
    transition: all 0.3s ease-in-out;
}

.footer-style-six .social-icon a:hover {
    background: var(--prime-five);
    border-color: var(--prime-five);
    color: #fff;
}

.footer-style-six .bottom-footer {
    margin-top: 40px;
    padding: 25px 0;
}


/*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner Seven ^^^^^^^^^^^^^^^^^^^^^^^^*/

.hero-banner-seven .bg-wrapper {
    background: url(../images/shape/shape_94.svg) no-repeat center bottom;
    background-size: cover;
    z-index: 5;
}

.hero-banner-seven .slogan {
    line-height: initial;
    border-radius: 20px;
    padding: 5px 10px 5px 22px;
    background: #fff;
    color: #000;
}

.hero-banner-seven .slogan i {
    font-size: 12px;
    width: 30px;
    line-height: 22px;
    background: #000;
    color: #fff;
    text-align: center;
    border-radius: 20px;
    display: inline-block;
    vertical-align: 2px;
    margin-left: 16px;
    transition: all 0.3s ease-in-out;
}

.hero-banner-seven .slogan:hover i {
    background: var(--prime-six);
    color: #fff;
}

.hero-banner-seven .hero-heading {
    line-height: 1.25em;
    letter-spacing: -3px;
}

.hero-banner-seven .hero-heading span img {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: -1;
    max-width: 132%;
    transform: translate(-50%, -50%);
}

.hero-banner-seven .illustration-holder {
    position: absolute;
    right: 0;
    top: 20%;
    width: 46%;
}


/*^^^^^^^^^^^^^^^^^^^^^ Partner Section Four ^^^^^^^^^^^^^^^^^^^^^^^^*/

.partner-section-four .wrapper {
    max-width: 1450px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Pricing Section Four ^^^^^^^^^^^^^^^^^^^^^^^^*/

.pricing-section-four .wrapper {
    max-width: 1744px;
    padding-left: 12px;
    padding-right: 12px;
}

.pricing-table-area-four .pr-table-wrapper {
    border: 1px solid #E3E3E3;
    padding: 30px 35px 40px;
    margin-top: 40px;
    position: relative;
    z-index: 1;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.pricing-table-area-four .pr-table-wrapper:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 2px solid #000;
    z-index: -1;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.pricing-table-area-four .pr-table-wrapper .pack-name {
    font-size: 28px;
}

.pricing-table-area-four .pr-table-wrapper .pack-details {
    color: #878787;
    letter-spacing: 2px;
    padding: 5px 0 35px;
}

.pricing-table-area-four .pr-table-wrapper.full-width .pack-details {
    padding-bottom: 20px;
}

.pricing-table-area-four .pr-table-wrapper .top-banner {
    padding: 9px 5px 10px 25px;
    color: #000;
    text-align: left;
}

.pricing-table-area-four .pr-table-wrapper .price {
    font-size: 40px;
    padding: 0 22px 0 0;
}

.pricing-table-area-four .pr-table-wrapper .price sup {
    font-size: 20px;
    top: -13px;
    left: -4px;
}

.pricing-table-area-four .pr-table-wrapper .top-banner span {
    font-size: 22px;
}

.pricing-table-area-four .pr-table-wrapper .top-banner em {
    font-size: 14px;
    font-style: normal;
    margin-top: -2px;
}

.pricing-table-area-four .pr-table-wrapper .pr-feature {
    text-align: left;
    padding: 50px 0 57px 23px;
}

.pricing-table-area-four .pr-table-wrapper .pr-feature li {
    font-size: 18px;
    position: relative;
    line-height: 42px;
    color: rgba(0, 0, 0, 0.7);
    padding-left: 30px;
}

.pricing-table-area-four .pr-table-wrapper .pr-feature li:before {
    content: "\f633";
    font-family: bootstrap-icons!important;
    font-size: 13px;
    position: absolute;
    left: 0;
    top: 3px;
    color: #000;
}

.pricing-table-area-four .pr-table-wrapper .trial-text {
    color: rgba(0, 0, 0, 0.5);
}

.pricing-table-area-four .pr-table-wrapper .trial-button {
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    line-height: 52px;
    border: 2px solid #000;
    transition: all 0.3s ease-in-out;
}

.pricing-table-area-four .pr-table-wrapper .btn-fourteen {
    line-height: 56px;
    font-size: 16px;
}

.pricing-table-area-four .pr-table-wrapper:hover:before,
.pricing-table-area-four .pr-table-wrapper.active:before {
    opacity: 1;
}

.pricing-table-area-four .pr-table-wrapper .trial-button:hover,
.pricing-table-area-four .pr-table-wrapper.active .trial-button {
    background: #000;
    color: #fff;
}

.pricing-section-four .consult-text {
    background: #F2F2F2;
    border-radius: 30px;
    padding: 5px 25px;
}

.pricing-table-area-four .pr-table-wrapper.full-width .btn-fifteen {
    font-size: 16px;
}

.pricing-table-area-four .pr-table-wrapper.full-width {
    border: 2px solid #000;
    border-top-width: 5px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Twenty Three ^^^^^^^^^^^^^^^^^^^^^^^^*/

.card-style-eleven .icon {
    width: 65px;
    height: 65px;
    border: 3px solid #000;
}

.card-style-eleven .icon:before {
    content: '';
    position: absolute;
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    top: -3px;
    left: 5px;
    border-radius: 50%;
    background: var(--prime-six);
    z-index: -1;
}

.card-style-eleven .text {
    width: calc(100% - 65px);
    padding-left: 30px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Fancy Short Banner Nine ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-short-banner-nine {
    padding: 0 60px 0 40px;
}

.fancy-short-banner-nine .bg-wrapper {
    background: var(--prime-six);
}

.fancy-short-banner-nine .bg-wrapper:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url(../images/assets/bg-7.png) no-repeat center;
    background-size: cover;
}

.fancy-short-banner-nine .bg-wrapper:after {
    content: '';
    position: absolute;
    top: 30px;
    right: -22px;
    bottom: -22px;
    left: 55px;
    background: url(../images/assets/bg-11.svg) no-repeat center top;
    background-size: cover;
    z-index: -1;
}

.fancy-short-banner-nine .title h6 {
    font-size: 13px;
    letter-spacing: 3px;
}

.fancy-short-banner-nine .title h2 {
    font-size: 52px;
    line-height: 1.35em;
    letter-spacing: -1px;
}

.fancy-short-banner-nine .input-wrapper {
    width: calc(100% - 250px);
    margin-right: 30px;
}

.fancy-short-banner-nine .input-wrapper input {
    width: 100%;
    height: 80px;
    background: #fff;
    border: none;
    padding: 0 140px 0 25px;
}

.fancy-short-banner-nine .input-wrapper .select-wrapper {
    position: absolute;
    right: 0;
    top: 22px;
    width: 135px;
    text-align: center;
    z-index: 1;
}

.fancy-short-banner-nine .input-wrapper .select-wrapper:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 40px;
    background: #C4C4C4;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.fancy-short-banner-nine .input-wrapper .select-wrapper select {
    font-weight: 500;
    font-size: 20px;
    outline: none;
    color: #000;
    border: none;
}

.fancy-short-banner-nine .domain-search-form .search-btn {
    background: #000;
    width: 250px;
    height: 80px;
}

.fancy-short-banner-nine .domain-search-form .search-btn:hover {
    background: var(--prime-five);
}


/*^^^^^^^^^^^^^^^^^^^^^ Feedback Section Six ^^^^^^^^^^^^^^^^^^^^^^^^*/

.feedback-section-six .bg-wrapper {
    background: #141414;
}

.feedback-section-six .bg-wrapper:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url(../images/assets/bg-9.png) no-repeat center;
    background-size: cover;
}

.feedback-section-six .bg-wrapper:after {
    content: '';
    position: absolute;
    top: -70px;
    right: 70px;
    bottom: -35px;
    left: 60px;
    background: url(../images/assets/bg-10.svg) no-repeat center top;
    background-size: cover;
    z-index: -1;
}

.feedback-section-six .inner-wrapper {
    z-index: 1;
}

.feedback-block-six p {
    font-size: 36px;
    line-height: 1.88em;
}

.feedback-block-six h4:before {
    content: '';
    position: absolute;
    width: 30px;
    height: 2px;
    background: #fff;
    left: -45px;
    top: 21px;
}

.slick-arrow-four li {
    position: absolute;
    bottom: 34%;
    width: 56px;
    height: 56px;
    color: #000;
    text-align: center;
    line-height: 56px;
    font-size: 28px;
    cursor: pointer;
    background: var(--prime-six);
}

.slick-arrow-four li:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid #fff;
    top: 6px;
    left: 6px;
    z-index: -1;
    transition: all 0.3s ease-in-out;
}

.slick-arrow-four li.prev_s2 {
    left: -40px;
}

.slick-arrow-four li.next_s2 {
    right: -40px;
}

.slick-arrow-four li:hover {
    background: #fff;
}

.slick-arrow-four li:hover:after {
    top: 0;
    left: 0;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Twenty Five ^^^^^^^^^^^^^^^^^^^^^^^^*/

.accordion-style-two {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion-style-two .accordion-item {
    background: #FFFFFF;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0;
}

.accordion-style-two .accordion-button {
    font-weight: 500;
    font-size: 22px;
    line-height: 1.62em;
    padding: 32px 0;
    box-shadow: none;
    border-radius: 0;
    color: #000;
    background: transparent;
    transition: all 0.3s ease-in-out;
}

.accordion-style-two .accordion-item .accordion-button:not(.collapsed) {
    padding-bottom: 12px;
}

.accordion-style-two .accordion-body {
    padding: 0 40px 20px 0;
}

.accordion-style-two .accordion-body p {
    font-size: 18px;
    line-height: 34px;
}

.accordion-style-two .accordion-button::after {
    content: url(../images/icon/icon_74.svg);
    background-image: none;
    width: auto;
    height: auto;
}

.bg-white .accordion-style-two {
    border: none;
}

.bg-white .accordion-style-two .accordion-item:first-child {
    border-top: none;
}


/*^^^^^^^^^^^^^^^^^^^^^ Fancy Short Banner Ten ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-short-banner-ten {
    z-index: 5;
    margin-bottom: -200px;
}

.fancy-short-banner-ten .bg-wrapper {
    background: #141414;
}

.fancy-short-banner-ten .bg-wrapper:after {
    content: '';
    position: absolute;
    top: 12px;
    right: -12px;
    bottom: -12px;
    left: 12px;
    background: var(--prime-six);
    z-index: -1;
}

.fancy-short-banner-ten h2 {
    font-size: 48px;
    line-height: 1.20em;
    letter-spacing: -1px;
}

.fancy-short-banner-ten .btn-solid {
    line-height: 60px;
    font-size: 18px;
    color: #0C0C0C;
    padding: 0 50px;
    background: var(--prime-six);
}

.fancy-short-banner-ten .btn-solid:hover {
    background: #fff;
}

.fancy-short-banner-ten .btn-line {
    font-size: 18px;
    padding-bottom: 5px;
    color: var(--prime-six);
}

.fancy-short-banner-ten .btn-line:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: var(--prime-six);
}

.fancy-short-banner-ten .btn-line:hover {
    color: #fff;
}


/*^^^^^^^^^^^^^^^^^^^^^ Footer Seven ^^^^^^^^^^^^^^^^^^^^^^^^*/

.footer-style-seven .bg-wrapper {
    background: #EEFBFF;
    padding: 350px 0 85px;
    overflow: hidden;
}

.footer-style-seven .bg-wrapper:before {
    content: '';
    position: absolute;
    height: 200px;
    width: 120%;
    top: -110px;
    left: -2%;
    background: #fff;
    transform: rotate(-5deg);
}

.theme-basic-footer.footer-style-seven .footer-title {
    color: rgba(11, 11, 11, 0.35);
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.footer-style-seven .bottom-footer {
    padding: 15px 0 0;
}

.footer-style-seven .bottom-footer .footer-nav a {
    font-size: 15px;
    font-weight: 500;
    color: #000;
    margin-right: 22px;
}

.footer-style-seven .bottom-footer .footer-nav a:hover {
    text-decoration: underline;
}

.footer-style-seven .bottom-footer .copyright {
    font-size: 15px;
}

.footer-style-seven .bottom-footer .social-icon a {
    font-size: 19px;
    margin-left: 19px;
    color: #333333;
    transition: all 0.3s ease-in-out;
}

.footer-style-seven .bottom-footer .social-icon a:hover {
    color: var(--prime-six);
}


/*-------------------------- Home Crypto Shape -------------------------*/

.oval-shape-one {
    right: 0;
    top: 0;
    z-index: 0;
}

.oval-shape-two {
    left: 0;
    top: 6%;
    z-index: 0;
}

.oval-shape-three {
    left: 0;
    top: 18%;
    z-index: 0;
}

.oval-shape-four {
    right: 0;
    top: 34%;
    z-index: 0;
}

.oval-shape-five {
    left: 0;
    top: 47%;
    z-index: 0;
}

.oval-shape-six {
    right: 0;
    top: 59%;
    z-index: 0;
}

.oval-shape-seven {
    left: 0;
    bottom: 2%;
    z-index: 0;
}

.oval-shape-eight {
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: 0;
    width: 100%;
}


/*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner Eight ^^^^^^^^^^^^^^^^^^^^^^^^*/

.hero-banner-eight {
    z-index: 2;
}

.hero-banner-eight .hero-heading {
    font-size: 90px;
    line-height: 1.16em;
    letter-spacing: -2px;
}

.hero-banner-eight .hero-heading span {
    background: linear-gradient(270.78deg, #FFA7A9 4.19%, #FF5EA3 96.11%);
    -webkit-text-fill-color: transparent;
}

.hero-banner-eight .video-icon .icon {
    width: 55px;
    height: 55px;
    background: rgba(255, 255, 255, 0.1);
    line-height: 55px;
    border-radius: 50%;
    text-align: center;
    font-size: 36px;
    padding-left: 5px;
    transition: all 0.3s ease-in-out;
}

.hero-banner-eight .video-icon .icon i {
    background: linear-gradient(270.78deg, #FFA7A9 4.19%, #FF5EA3 96.11%);
    -webkit-text-fill-color: transparent;
}

.hero-banner-eight .video-icon .tx1 {
    color: rgba(255, 255, 255, 0.3);
    margin-bottom: 4px;
}

.hero-banner-eight .video-icon:hover .icon {
    background: rgba(255, 255, 255, 0.15);
}

.hero-banner-eight h2 {
    font-size: 70px;
    letter-spacing: 0px;
}

.hero-banner-eight .line-shape {
    right: 0;
    top: 0;
    animation: fade-in 2s infinite linear;
}

.hero-banner-eight .illustration-holder {
    position: absolute;
    right: 5%;
    top: 24%;
    width: 37%;
}

.hero-banner-eight .illustration-holder .shape-one {
    width: 36px;
    height: 36px;
    background: linear-gradient(180deg, #C275FD 0%, #FF91F5 100%);
    bottom: 25%;
    left: -14%;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Twenty Six ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-twentySix .shape-one {
    width: 40px;
    height: 40px;
    background: linear-gradient(180deg, #FF56F8 0%, #FF438B 100%);
    top: 57%;
    left: 4%;
}

.fancy-feature-twentySix .shape-two {
    width: 15px;
    height: 15px;
    top: 38%;
    right: 4%;
    background: linear-gradient(180deg, #FF88EB 0%, #FF3B7F 100%);
}

.fancy-feature-twentySix .wrapper-xl {
    max-width: 1494px;
    padding: 0 12px;
}

.fancy-feature-twentySix .wrapper-xl .bg {
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.04);
}

.fancy-feature-twentySix .trade-meta-block {
    padding: 50px 48px 30px;
    border-right: 1px solid rgba(255, 255, 255, 0.12);
}

.fancy-feature-twentySix .trade-meta-block .icon {
    width: 60px;
    height: 60px;
    background: rgba(255, 255, 255, 0.08);
}

.fancy-feature-twentySix .trade-meta-block .cr-name {
    font-size: 13px;
    color: rgba(134, 151, 168, 0.5);
}

.fancy-feature-twentySix .trade-meta-block .arrow-up {
    color: #01ECC2;
}

.fancy-feature-twentySix .trade-meta-block .arrow-down {
    color: #FF4C82;
}

.fancy-feature-twentySix .trade-meta-block .cr-value {
    font-size: 10px;
    color: #8996A6;
    margin-top: -5px;
}

.fancy-feature-twentySix .trade-meta-block .btc-balance {
    font-size: 20px;
    letter-spacing: -0.5px;
}

.card-style-twelve {
    padding: 22px 35px 45px;
    border-radius: 30px;
    transition: all 0.3s ease-in-out;
    z-index: 1;
}

.card-style-twelve .icon {
    height: 230px;
}

.card-style-twelve .icon img {
    max-height: 100%;
}

.card-style-twelve .arrow {
    opacity: 0.6;
}

.card-style-twelve .arrow:hover {
    opacity: 1;
}

.card-style-twelve.active,
.card-style-twelve:hover {
    background: rgba(255, 255, 255, 0.04);
}

.card-style-twelve.active:before,
.card-style-twelve:hover:before {
    opacity: 0;
}

.card-style-twelve:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    border: 1px solid transparent;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0)) border-box;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    z-index: -1;
    transition: all 0.3s ease-in-out;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Twenty Seven ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-twentySeven .r-shape-one {
    width: 100px;
    height: 200px;
    right: 0;
    top: -5%;
    background: linear-gradient(180deg, #FFACE6 0%, #9075F3 100%);
    border-radius: 100px 0px 0px 100px;
}

.fancy-feature-twentySeven .wrapper {
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 30px;
}

.card-style-thirteen {
    padding: 50px 58px 30px;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.card-style-thirteen .icon {
    height: 36px;
}

.card-style-thirteen p {
    color: rgba(255, 255, 255, 0.6);
}

.card-style-thirteen .arrow {
    width: 50px;
    height: 50px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: #151938;
    color: #fff;
    font-size: 26px;
    top: 50%;
    right: -25px;
    transform: translateY(-50%);
    z-index: 1;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Twenty Eight ^^^^^^^^^^^^^^^^^^^^^^^^*/

.block-style-five .list-item li {
    position: relative;
    padding: 25px 40px 25px 100px;
    font-size: 22px;
    line-height: 35px;
    letter-spacing: -0.2px;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 35px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.053) 0.94%, rgba(255, 255, 255, 0.019) 98.3%);
    border-radius: 10px;
    overflow: hidden;
    z-index: 1;
    transition: all 0.3s ease-in-out;
}

.block-style-five .list-item li:after {
    content: "\f272";
    font-family: bootstrap-icons !important;
    width: 45px;
    height: 45px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    line-height: 45px;
    text-align: center;
    font-size: 22px;
    position: absolute;
    left: 25px;
    top: 28px;
    color: #FF4595;
    transition: all 0.3s ease-in-out;
}

.block-style-five .list-item li:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(90deg, #FF9DAB 1.51%, #FF4595 99.06%);
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.block-style-five .list-item li:hover:before {
    opacity: 1;
}

.block-style-five .list-item li:hover:after {
    background: rgba(0, 0, 0, 0.1);
    color: #fff;
}

.block-style-five .list-item li:hover {
    color: #fff;
}

.fancy-feature-twentyEight .main-img {
    animation: rotated 95s infinite linear;
}

.fancy-feature-twentyEight .illustration-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    max-width: 540px;
}

.counter-block-four .main-count {
    font-size: 120px;
    background: linear-gradient(104.25deg, #FF9CAB 13.66%, #FF4696 84.45%);
    -webkit-text-fill-color: transparent;
    margin-bottom: -10px;
}

.counter-block-four p {
    line-height: 30px;
    color: rgba(255, 255, 255, 0.5);
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Twenty Eight ^^^^^^^^^^^^^^^^^^^^^^^^*/

.feedback-section-seven .shape-one {
    width: 24px;
    height: 24px;
    background: linear-gradient(180deg, #FFACE6 0%, #9075F3 100%);
    top: 2%;
    left: 6%;
}

.feedback-section-seven .inner-content {
    width: 130vw;
    transform: translateX(-15vw);
}

.feedback-block-seven {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    position: relative;
    padding: 50px 70px 40px;
}

.feedback-block-seven .rating li {
    font-size: 16px;
    margin-right: 10px;
    color: #FFCC4A;
    line-height: initial;
}

.feedback-block-seven p {
    font-size: 26px;
    line-height: 1.78em;
    padding: 50px 0 30px;
}

.feedback_slider_seven .item {
    margin: 0 25px;
}

.feedback-section-seven .slick-dots {
    text-align: center;
    margin-top: 80px;
}

.feedback-section-seven .slick-dots li button {
    width: 10px;
    height: 10px;
    background: rgba(255, 255, 255, 0.4);
}

.feedback-section-seven .slick-dots li.slick-active button {
    background: #FF4A94;
    transform: scale(1);
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Twenty Nine ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-twentyNine .shape-one {
    width: 24px;
    height: 24px;
    background: linear-gradient(180deg, #FFACE6 0%, #9075F3 100%);
    bottom: 13%;
    left: 20%;
}

.accordion-style-three .accordion-item {
    background: transparent;
    position: relative;
    z-index: 1;
    border: none;
    border-radius: 0;
}

.accordion-style-three .accordion-item:before,
.accordion-style-three .accordion-item:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.05) 0.66%, rgba(255, 255, 255, 0.3) 52.69%, rgba(255, 255, 255, 0.05) 99.98%);
    left: 0;
}

.accordion-style-three .accordion-item:after {
    top: 0;
}

.accordion-style-three .accordion-item:before {
    bottom: 0;
    display: none;
}

.accordion-style-three .accordion-item:last-child:before {
    display: block;
}

.accordion-style-three .accordion-button {
    font-size: 24px;
    line-height: 1.62em;
    letter-spacing: -0.5px;
    padding: 25px 15px 25px 26px;
    box-shadow: none;
    border-radius: 0;
    color: #fff;
    background: transparent;
}

.accordion-style-three .accordion-body {
    padding: 0 40px 30px 26px;
}

.accordion-style-three .accordion-body p {
    color: rgba(255, 255, 255, 0.6);
}

.accordion-style-three .accordion-button::after {
    content: url(../images/icon/icon_86.svg);
    background-image: none;
    width: auto;
    height: auto;
}


/*^^^^^^^^^^^^^^^^^^^^^ Blog Section Four ^^^^^^^^^^^^^^^^^^^^^^^^*/

.blog-meta-four {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 30px;
    overflow: hidden;
    padding: 35px 45px 60px;
}

.blog-meta-four:hover {
    transform: translateY(-5px);
}

.blog-meta-four .logo {
    height: 35px;
}

.blog-meta-four .blog-title {
    font-size: 36px;
    letter-spacing: -0.5px;
    line-height: 1.38em;
}

.blog-section-four .learn-more-btn {
    letter-spacing: -1px;
}

.blog-section-four .learn-more-btn a {
    background: linear-gradient(270.78deg, #FFA7A9 4.19%, #FF5EA3 96.11%);
    -webkit-text-fill-color: transparent;
}

.blog-section-four .learn-more-btn:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: -2px;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.05) 0.66%, rgba(255, 255, 255, 0.3) 52.69%, rgba(255, 255, 255, 0.05) 99.98%);
}


/*^^^^^^^^^^^^^^^^^^^^^ Fancy Short Banner Eleven ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-short-banner-eleven .shape-one {
    width: 52px;
    height: 52px;
    background: linear-gradient(180deg, #FFACE6 0%, #9075F3 100%);
    top: 5%;
    left: 5%;
}

.fancy-short-banner-eleven .shape-two {
    width: 15px;
    height: 15px;
    background: linear-gradient(180deg, #FFACE6 0%, #9075F3 100%);
    top: 17%;
    right: 8%;
}

.platform-button-group-two a {
    width: 190px;
    height: 58px;
    padding: 0 5px 0 25px;
    border-radius: 5px;
    color: #fff;
    text-align: left;
    margin: 15px 10px 0;
    position: relative;
    z-index: 2;
    transition: all 0.3s ease-in-out;
}

.platform-button-group-two .ios-button:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 2px solid transparent;
    background: linear-gradient(0deg, #FE3B7D, #FF66C9) border-box;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    z-index: -1;
}

.platform-button-group-two .ios-button {
    background: linear-gradient(270.78deg, #FFA7A9 4.19%, #FF5EA3 96.11%);
    -webkit-text-fill-color: transparent;
}

.platform-button-group-two a:hover {
    transform: translateY(-5px);
    box-shadow: -5px 10px 30px rgba(0, 0, 0, 0.05);
}

.platform-button-group-two a .icon {
    margin-right: 14px;
}

.platform-button-group-two a span {
    font-size: 10px;
    color: rgba(153, 153, 153, 0.6);
    display: block;
    line-height: initial;
}

.platform-button-group-two a strong {
    font-weight: 500;
    font-size: 18px;
    color: #fff;
    letter-spacing: -0.3px;
    display: block;
}

.platform-button-group-two a.windows-button {
    background: rgba(255, 255, 255, 0.1);
}


/*^^^^^^^^^^^^^^^^^^^^^ Footer Eight ^^^^^^^^^^^^^^^^^^^^^^^^*/

.footer-style-eight {
    padding: 0 10%;
}

.footer-style-eight .top-footer {
    border-radius: 20px;
    padding: 100px 12px 60px;
}

.footer-style-eight .top-footer .shape-one {
    top: -80px;
    right: -70px;
    z-index: -2;
}

.footer-style-eight .top-footer:before {
    content: '';
    position: absolute;
    background: rgba(255, 255, 255, 0.05);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 30px;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
}

.footer-style-eight ::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.footer-style-eight :-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.footer-style-eight ::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.theme-basic-footer.footer-style-eight .form-widget form input {
    width: 100%;
    height: 100%;
    border: none;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    color: #fff;
    padding: 0 132px 0 25px;
}

.theme-basic-footer.footer-style-eight .form-widget form button {
    background: linear-gradient(103.39deg, #FFA0A9 15.17%, #FF60A4 59.61%);
    border-radius: 8px;
    color: #fff;
}

.theme-basic-footer.footer-style-eight .footer-nav-link a {
    color: rgba(255, 255, 255, 0.7);
}

.theme-basic-footer.footer-style-eight .footer-nav-link a:hover {
    color: #fff;
    text-decoration: underline;
}

.footer-style-eight .bottom-footer {
    padding: 40px 0 25px;
}

.footer-style-eight .bottom-footer .footer-nav a {
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    margin-right: 22px;
}

.footer-style-eight .bottom-footer .footer-nav a:hover {
    text-decoration: underline;
}

.footer-style-eight .bottom-footer .copyright {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.6);
}

.footer-style-eight .bottom-footer .social-icon a {
    font-size: 19px;
    margin-left: 19px;
    color: #fff;
    transition: all 0.3s ease-in-out;
}


/*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner Nine ^^^^^^^^^^^^^^^^^^^^^^^^*/

.hero-banner-nine {
    background: #1A1A1A;
}

.hero-banner-nine .hero-heading {
    font-size: 92px;
    line-height: 1.13em;
}

.hero-banner-nine .hero-heading span img {
    position: absolute;
    left: 0;
    bottom: 14px;
}

.hero-banner-nine .sub-text {
    font-size: 22px;
    color: rgba(255, 255, 255, .7);
    line-height: 1.72em;
    letter-spacing: -0.5px;
}

.hero-banner-nine .demo-btn {
    font-size: 18px;
    letter-spacing: -0.3px;
    color: var(--prime-eight);
    line-height: 55px;
    border: 2px solid var(--prime-eight);
    border-radius: 30px;
    padding: 0 25px;
}

.hero-banner-nine .demo-btn:hover {
    background: #000;
}

.hero-banner-nine .signIn-btn a {
    color: var(--prime-eight);
    text-decoration: underline;
}

.hero-banner-nine h2 {
    font-size: 54px;
}

.hero-banner-nine .image-gallery {
    position: absolute;
    right: 4%;
    top: 17%;
    width: 43%;
    max-width: 800px;
}

.hero-banner-nine .img-box-one .main-img {
    border-radius: 0 0 150px 150px;
}

.hero-banner-nine .img-box-two .main-img {
    border-radius: 150px;
}

.hero-banner-nine .img-box-three .main-img {
    border-radius: 200px 200px 0 0;
}

.hero-banner-nine .img-box-three:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border: 3px solid #fff;
    border-radius: 200px 200px 0 0;
    top: 17px;
    left: 14px;
    z-index: -1;
}

.hero-banner-nine .img-box .shape-one {
    top: -12%;
    left: -14%;
}

.hero-banner-nine .img-box .shape-two {
    top: -12%;
    left: -14%;
}

.hero-banner-nine .img-box .shape-three {
    top: 50%;
    left: -31%;
    transform: translateY(-50%);
}

.hero-banner-nine .img-box .shape-four {
    bottom: 0;
    right: 5%;
}

.partner-section-five {
    background: #E3FF75;
    transform: rotate(0.65deg);
    padding: 17px 0;
}

.partner-section-five .wrapper {
    width: 1660px;
    padding: 0 12px;
}

.partner-section-five .logo-wrapper {
    font-size: 30px;
    letter-spacing: -0.5px;
}

.partner-section-five .logo-wrapper .br-name {
    padding: 0 3%;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feedback Section Eight ^^^^^^^^^^^^^^^^^^^^^^^^*/

.feedback-block-eight p {
    font-size: 80px;
    line-height: 1.23em;
    letter-spacing: -1px;
    padding: 0 50px;
}

.feedback-block-eight p span {
    color: var(--prime-nine);
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-thickness: 4px;
}

.feedback-block-eight h6:before {
    content: '';
    position: absolute;
    width: 22px;
    height: 2px;
    background: #000;
    left: -8px;
    top: 14px;
}

.feedback-section-eight .shape-one {
    top: 20%;
    left: 4%;
    border-radius: 100px 0 0 100px;
}

.feedback-section-eight .shape-two {
    top: 20%;
    right: 9%;
    border-radius: 200px;
}

.feedback-section-eight .shape-three {
    bottom: 0;
    left: 12%;
    border-radius: 200px;
}

.feedback-section-eight .shape-four {
    bottom: 0;
    right: 3%;
    border-radius: 0 0 100px 100px;
}

.feedback-section-eight .shape-five {
    right: 0;
    top: 36%;
}

.feedback-section-eight .shape-six {
    left: 1%;
    bottom: 20%;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Thirty ^^^^^^^^^^^^^^^^^^^^^^^^*/

.block-style-four .list-item li {
    font-size: 24px;
    color: #000;
    margin-bottom: 20px;
    position: relative;
    padding-left: 40px;
}

.block-style-four .list-item li:before {
    content: url(../images/icon/icon_93.svg);
    position: absolute;
    left: 0;
    top: 0;
}

.block-style-four .rating li {
    font-size: 17px;
    margin-right: 6px;
    color: var(--prime-ten);
}

.fancy-feature-thirty .illustration-holder {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 45.5%;
    z-index: 1;
}

.fancy-feature-thirty .illustration-holder:before {
    content: '';
    position: absolute;
    width: 92%;
    left: 0;
    top: -5px;
    bottom: 0;
    border-top: 6px solid #000;
    border-right: 6px solid #000;
    border-radius: 0 20px 0 0;
    z-index: -1;
}

.fancy-feature-thirty .illustration-holder .main-img {
    border-radius: 0 20px 0 0;
}

.fancy-feature-thirty .shape-one {
    top: -75px;
    right: 2%;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Thirty One ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-thirtyOne {
    background: #FAFAFA;
}

.fancy-feature-thirtyOne .shape-one {
    right: 0;
    top: -100px;
}

.fancy-feature-thirtyOne .shape-two {
    left: 0;
    bottom: -50px;
}

.card-style-fourteen p {
    letter-spacing: 2px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Thirty Two ^^^^^^^^^^^^^^^^^^^^^^^^*/

.block-style-six .info {
    border-top: 1px solid #D9D9D9;
}

.block-style-six .info h4 {
    line-height: 32px;
}

.fancy-feature-thirtyTwo .card-style {
    position: absolute;
    width: 220px;
    height: 126px;
    background: #66EDFF;
    top: 41%;
    right: -8%;
}

.counter-block-five .main-count {
    font-size: 80px;
    color: #151515;
    margin-bottom: -12px;
}

.counter-block-five .cicrle {
    width: 8px;
    height: 8px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feedback Section Nine ^^^^^^^^^^^^^^^^^^^^^^^^*/

.feedback-section-nine .wrapper {
    background: url(../images/shape/shape_126.svg) no-repeat center;
    background-size: cover;
}

.feedback-block-nine p {
    font-size: 42px;
    line-height: 1.61em;
    color: #fff;
}

.feedback-block-nine p span {
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-thickness: 2px;
}

.feedback-block-nine .rating {
    width: 110px;
    height: 110px;
    background: #FF67D4;
}

.feedback-block-nine .rating-value {
    font-size: 42px;
    margin-bottom: -6px;
}

.feedback-block-nine .img-one {
    left: 0;
    bottom: 0;
    position: absolute;
}

.feedback-block-nine .img-two {
    right: 0;
    top: 0;
    position: absolute;
    border-radius: 300px 0 0 300px;
}

.feedback-block-nine .shape-two {
    top: -50px;
    right: -55px;
}

.feedback-section-nine .img-three {
    left: 0;
    bottom: -13%;
    position: absolute;
    border-radius: 0 0 250px 0;
}

.feedback-section-nine .shape-one {
    top: -70px;
    right: 0;
}

.feedback-section-nine .customer-data {
    width: 200px;
    height: 210px;
    background: #CFFF69;
    position: absolute;
    right: 20px;
    bottom: -90px;
}

.feedback-section-nine .customer-data .amount {
    font-size: 50px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feedback Section Ten ^^^^^^^^^^^^^^^^^^^^^^^^*/

.feedback-section-ten .slider-wrapper {
    width: calc(1320px + 25vw);
    margin: 0 -30px;
}

.feedback_slider_ten .item {
    margin: 0 30px;
}

.feedback-block-ten {
    padding: 40px 70px 35px;
}

.feedback-block-ten .cmp-name {
    font-size: 36px;
    letter-spacing: -0.5px;
}

.feedback-block-ten p {
    font-size: 28px;
    line-height: 1.78em;
}

.feedback-section-ten .slick-arrow-five {
    position: absolute;
    right: 0;
    top: 90px;
    border: 2px solid #000;
}

.slick-arrow-five li {
    font-size: 24px;
    width: 55px;
    height: 50px;
    line-height: 48px;
    color: #000;
    cursor: pointer;
}

.slick-arrow-five li:hover {
    background: #000;
    color: #fff;
}

.slick-arrow-five li:first-child {
    border-right: 1px solid #000;
}

.slick-arrow-five li:last-child {
    border-left: 1px solid #000;
}

.feedback-section-ten .shape-one {
    top: 9%;
    left: 51%;
    animation: jumpTwo 5s infinite linear;
}

.feedback-section-ten .shape-two {
    bottom: 0;
    left: 0;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Thirty Three ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-thirtyThree .bg-wrapper {
    background: #F6F6F6;
    padding: 10px 50px;
}

.accordion-style-four .accordion-item {
    background: transparent;
    border: none;
    border-radius: 0;
}

.accordion-style-four .accordion-item .accordion-button {
    background: transparent;
    font-size: 24px;
    font-weight: 500;
    color: #000;
    padding: 30px 45px 30px 40px;
    box-shadow: none;
    outline: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    transition: all 0.2s ease-in-out;
}

.accordion-style-four .accordion-item .accordion-button span {
    margin-right: 20px;
}

.accordion-style-four .accordion-item .accordion-button:not(.collapsed) {
    background: #0F0F0F;
    color: #fff;
    padding-bottom: 20px;
    padding-top: 40px;
}

.accordion-style-four .accordion-item:last-child .accordion-button {
    border: none;
}

.accordion-style-four .accordion-item .accordion-body {
    background: #0F0F0F;
    padding: 0 100px 40px 103px;
    color: rgba(255, 255, 255, 0.8);
}

.accordion-style-four .accordion-item .accordion-body p {
    font-size: 18px;
    margin: 0;
}

.accordion-style-four .accordion-button::after {
    content: '+';
    font-size: 24px;
    width: 30px;
    height: 30px;
    background: #fff;
    text-align: center;
    font-weight: normal;
    line-height: 30px;
}

.accordion-style-four .accordion-button:not(.collapsed)::after {
    content: '-';
    background: var(--prime-eight);
    color: #000;
    transform: none;
}

.fancy-feature-thirtyThree .shape-one {
    top: -64px;
    right: -64px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Fancy Short Banner Twelve ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-short-banner-twelve .shape-one {
    width: 35px;
    height: 35px;
    background: #00FCFC;
    top: 18%;
    right: 35%;
}


/*^^^^^^^^^^^^^^^^^^^^^ Footer Nine ^^^^^^^^^^^^^^^^^^^^^^^^*/

.footer-style-nine {
    background: #0F0F0F;
    padding: 120px 0 0;
}

.footer-style-nine.theme-basic-footer .footer-title {
    color: rgba(255, 255, 255, 0.35);
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.footer-style-nine .footer-nav-link a {
    color: rgba(255, 255, 255, 0.9);
}

.footer-style-nine .footer-nav-link a:hover,
.footer-style-nine .bottom-footer .footer-nav a:hover {
    color: var(--prime-eight);
}

.footer-style-nine .form-widget form input {
    border: 2px solid #fff;
    background: transparent;
    color: #fff;
}

.footer-style-nine ::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.footer-style-nine :-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.footer-style-nine ::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.footer-style-nine .form-widget form button {
    background: var(--prime-eight);
    color: #000;
}

.footer-style-nine .bottom-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: 60px;
    padding: 15px 0 30px;
}

.footer-style-nine .bottom-footer .footer-nav a {
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    margin-right: 22px;
    transition: all 0.3s ease-in-out;
}

.footer-style-nine .bottom-footer .copyright {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.6);
}

.footer-style-nine .bottom-footer .social-icon a {
    font-size: 19px;
    margin-left: 19px;
    color: #fff;
    transition: all 0.3s ease-in-out;
}

.footer-style-nine .bottom-footer .social-icon a:hover {
    color: var(--prime-eight);
}

.footer-style-nine .shape-one {
    width: 70px;
    height: 70px;
    left: 13%;
    top: -35px;
    background: #FF45AA;
}

.footer-style-nine .shape-two {
    right: 5%;
    top: -40px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner Ten ^^^^^^^^^^^^^^^^^^^^^^^^*/

.hero-banner-ten {
    padding: 230px 0 90px;
}

.hero-banner-ten .hero-heading {
    font-size: 90px;
    line-height: 1.22em;
}

.hero-banner-ten .hero-heading span {
    color: var(--prime-ten);
}

.hero-banner-ten .search-area .nice-select {
    width: 285px;
    height: 60px;
    border: 1px solid #DCDCDC;
    border-radius: 7px 0 0 7px;
    color: #000;
    padding: 0 0 0 20px;
    line-height: 60px;
    font-size: 16px;
    background: #FAFAFA;
}

.hero-banner-ten .search-area .nice-select:after {
    display: none;
}

.hero-banner-ten .search-area .nice-select .option:hover,
.hero-banner-ten .search-area .nice-select .option.focus,
.hero-banner-ten .search-area .nice-select .option.selected.focus {
    background: var(--prime-ten) !important;
    color: #fff;
}

.hero-banner-ten .search-area .zip-code-input {
    width: 170px;
    height: 60px;
    background: #FAFAFA;
    border: 1px solid #DCDCDC;
    border-right: none;
    border-left: none;
    color: #000;
    padding: 0 5px 0 25px;
}

.hero-banner-ten .search-area button {
    width: 110px;
    height: 60px;
    background: var(--prime-ten);
    border-radius: 0 7px 7px 0;
}

.hero-banner-ten .search-area button:hover {
    background: #000;
}

.hero-banner-ten .approval-info {
    color: var(--prime-ten);
}

.hero-banner-ten .illustration-one {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 25.44%;
    z-index: -1;
}

.hero-banner-ten .illustration-two {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 28.05%;
    z-index: -1;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Thirty Four ^^^^^^^^^^^^^^^^^^^^^^^^*/

.card-style-fifteen {
    border-radius: 10px;
    padding: 37px;
    height: 360px;
    border: 1px solid transparent;
}

.card-style-fifteen:hover {
    border-color: var(--prime-ten);
    border-radius: 15px;
    background: #fff !important;
}

.card-style-fifteen h4 {
    font-size: 28px;
    line-height: 1.35em;
}

.card-style-fifteen p {
    font-size: 18px;
    letter-spacing: -0.18px;
    color: rgba(0, 0, 0, 0.5);
}

.card-style-fifteen img {
    bottom: 15px;
    right: 35px;
    animation: jumpFour 4s infinite linear;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Thirty Five ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-thirtyFive .top-banner h2 {
    font-size: 62px;
    letter-spacing: -0.7px;
}

.fancy-feature-thirtyFive .top-banner .rating li {
    font-size: 17px;
    margin: 0 2px;
    color: var(--prime-ten);
}

.fancy-feature-thirtyFive .bg-wrapper {
    background: #F6F9FB;
}

.fancy-feature-thirtyFive .text-wrapper p {
    font-size: 42px;
    line-height: 1.57em;
}

.fancy-feature-thirtyFive .text-wrapper p span {
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-thickness: 3px;
    color: var(--prime-ten);
}

.fancy-feature-thirtyFive .text-wrapper h6:before {
    content: '';
    position: absolute;
    width: 22px;
    height: 2px;
    background: #000;
    left: -8px;
    top: 14px;
}

.fancy-feature-thirtyFive .img-holder .avatar-img {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.fancy-feature-thirtyFive .img-holder .card-style {
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 35px 70px rgba(0, 23, 103, 0.07);
    border-radius: 8px;
    padding: 30px 0;
}

.fancy-feature-thirtyFive .img-holder .card-style .icon {
    width: 40px;
    height: 40px;
    color: var(--prime-ten);
    font-size: 16px;
    border: 1px solid var(--prime-ten);
}

.fancy-feature-thirtyFive .img-holder .card-style h3 {
    color: var(--prime-ten);
    margin-bottom: -2px;
}

.fancy-feature-thirtyFive .img-holder .card-one {
    width: 275px;
    top: 32%;
    left: -16%;
    animation: jumpFour 10s infinite linear;
}

.fancy-feature-thirtyFive .img-holder .card-two {
    width: 250px;
    top: 2%;
    right: -9%;
    animation: jumpThree 10s infinite linear;
}

.fancy-feature-thirtyFive .img-holder .card-three {
    width: 330px;
    bottom: -6%;
    right: -5%;
    animation: jumpTwo 10s infinite linear;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Thirty Six ^^^^^^^^^^^^^^^^^^^^^^^^*/

.card-style-sixteen {
    padding: 75px 15px 90px;
    border: 1px solid #e9e9e9;
    border-radius: 10px;
    overflow: hidden;
}

.card-style-sixteen:hover {
    border-color: var(--prime-ten);
}

.card-style-sixteen .icon {
    height: 60px;
}

.card-style-sixteen p {
    color: #adadad;
}

.card-style-sixteen .read-more {
    width: 85px;
    height: 85px;
    font-size: 26px;
    color: #000;
    background: #F7F7F7;
    position: absolute;
    bottom: -30px;
    right: -30px;
    padding: 11px 0 0 19px;
}

.card-style-sixteen:hover .read-more {
    color: #fff;
    background: var(--prime-ten);
}

.fancy-feature-thirtySix .btn-twentyTwo {
    position: absolute;
    right: 0;
    top: 75px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Thirty Seven ^^^^^^^^^^^^^^^^^^^^^^^^*/

.accordion-style-five .accordion-item {
    background: #FFFFFF;
    border: none;
    background: #FFFFFF;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    margin-top: 20px;
}

.accordion-style-five .accordion-button {
    font-weight: 500;
    font-size: 22px;
    line-height: 1.62em;
    padding: 22px 25px;
    box-shadow: none;
    border-radius: 0;
    color: #000;
    background: transparent;
    transition: all 0.3s ease-in-out;
}

.accordion-style-five .accordion-button img {
    width: 25px;
}

.accordion-style-five .accordion-item .accordion-button:not(.collapsed) {
    padding-bottom: 12px;
}

.accordion-style-five .accordion-body {
    padding: 0 66px 13px;
}

.accordion-style-five .accordion-body p {
    font-size: 18px;
    line-height: 28px;
}

.accordion-style-five .accordion-button::after {
    content: url(../images/icon/icon_111.svg);
    background-image: none;
    width: auto;
    height: auto;
}

.fancy-feature-thirtySeven .block-one {
    padding: 35px 15px 30px 35px;
    border-radius: 45px 0 45px 0;
    background: #FFF3C9;
    border: 1.5px solid #FFAE10;
}

.fancy-feature-thirtySeven .block-two {
    padding: 62px 15px 50px 35px;
    border-radius: 45px 0 45px 0;
    background: rgba(162, 91, 251, 0.2);
    border: 1.5px solid #9650EF;
}

.fancy-feature-thirtySeven .block-three {
    padding: 22px 15px 15px 30px;
    border-radius: 0 30px 0 30px;
    background: rgba(0, 209, 255, 0.2);
    border: 1.5px solid #00BDE5;
}

.fancy-feature-thirtySeven .block-two .shape-one {
    bottom: -45px;
    left: -45px;
}

.fancy-feature-thirtySeven .block-wrapper h3 {
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 14px;
}

.fancy-feature-thirtySeven .block-wrapper h3 span {
    font-size: 0.77em;
}

.fancy-feature-thirtySeven .block-wrapper p {
    font-size: 18px;
    line-height: 29px;
    color: rgba(0, 0, 0, 0.7);
}


/*^^^^^^^^^^^^^^^^^^^^^ Feedback Section Eleven ^^^^^^^^^^^^^^^^^^^^^^^^*/

.feedback-section-eleven {
    background: #F6F9FB;
}

.feedback-section-eleven .inner-content,
.feedback-section-thirteen .inner-content {
    width: 124vw;
    transform: translateX(-12vw);
}

.feedback-block-eleven {
    background: #FFFFFF;
    box-shadow: 0px 30px 60px rgba(19, 45, 73, 0.05);
    border-radius: 10px;
    position: relative;
    padding: 50px 70px 40px;
    margin: 40px 0 70px;
}

.feedback-block-eleven .rating li {
    font-size: 16px;
    margin-right: 10px;
    color: #FFCC4A;
    line-height: initial;
}

.feedback-block-eleven p {
    font-size: 26px;
    line-height: 1.78em;
    padding: 50px 0 30px;
}

.feedback_slider_seven .item {
    margin: 0 25px;
}

.feedback-section-eleven .slick-dots {
    text-align: center;
    margin-top: 20px;
}

.feedback-section-eleven .slick-dots li button {
    width: 10px;
    height: 10px;
    background: rgba(0, 0, 0, 0.1);
}

.feedback-section-eleven .slick-dots li.slick-active button {
    background: var(--prime-ten);
    transform: scale(1);
}

.feedback-section-thirteen .feedback-block-eleven {
    border: 1px solid #000;
    box-shadow: none;
    margin-bottom: 50px;
}

.feedback-section-thirteen .feedback-block-eleven:before {
    content: '';
    position: absolute;
    top: 8px;
    left: 8px;
    width: 100%;
    height: 100%;
    background: #000;
    border-radius: 10px;
    z-index: -1;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.feedback-section-thirteen .slick-center .feedback-block-eleven:before {
    opacity: 1;
}

.feedback-section-thirteen:before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 455px;
    background: url(../images/assets/bg-15.svg);
    background-repeat: repeat;
    z-index: -1;
}

.feedback-section-thirteen .slick-dots {
    display: none !important;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Thirty Eight ^^^^^^^^^^^^^^^^^^^^^^^^*/

.block-style-seven .list-item li {
    font-size: 24px;
    color: #000;
    margin-bottom: 20px;
    position: relative;
    padding-left: 40px;
}

.block-style-seven .list-item li:before {
    content: url(../images/icon/icon_113.svg);
    position: absolute;
    left: 0;
    top: 0;
}

.platform-button-group-three a {
    width: 190px;
    height: 60px;
    padding: 0 5px 0 25px;
    margin: 10px 25px 0 0;
    background: #313131;
    border-radius: 6px;
    color: #fff;
    text-align: left;
    transition: all 0.3s ease-in-out;
}

.platform-button-group-three a:hover {
    transform: translateY(-5px);
    box-shadow: -5px 10px 30px rgba(0, 0, 0, 0.05);
}

.platform-button-group-three a .icon {
    margin-right: 14px;
}

.platform-button-group-three a span {
    font-size: 10px;
    color: rgba(255, 255, 255, 0.6);
    display: block;
    line-height: initial;
}

.platform-button-group-three a strong {
    font-weight: 500;
    font-size: 18px;
    display: block;
}

.platform-button-group-three a.ios-button {
    background: rgba(0, 0, 0, 0.09);
}

.platform-button-group-three a.ios-button span {
    color: rgba(49, 49, 49, 0.7);
}

.platform-button-group-three a.ios-button strong {
    color: #313131;
}

.fancy-feature-thirtyEight .illustration-holder .main-img {
    border-radius: 35px;
}

.fancy-feature-thirtyEight .illustration-holder .screen-two {
    position: absolute;
    right: 31%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.fancy-feature-thirtyEight .illustration-holder .shape-one {
    right: 5px;
    top: 1%;
    animation: jumpFour 5s infinite linear;
}

.fancy-feature-thirtyEight .illustration-holder .shape-two {
    left: -13%;
    bottom: 12%;
}


/*^^^^^^^^^^^^^^^^^^^^^ Fancy Short Banner Thirteen ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-short-banner-thirteen {
    background: #F6F9FB;
}

.fancy-short-banner-thirteen .bg-wrapper {
    border-radius: 10px;
    padding: 0 12px;
}

.fancy-short-banner-thirteen .btn-twentyTwo {
    font-size: 14px;
    letter-spacing: 0.88px;
}

.fancy-short-banner-thirteen .text-wrapper {
    border-left: 1px solid #E9E9E9;
    padding: 60px 0 80px 80px;
}

.fancy-short-banner-thirteen .bg-wrapper .shape-one {
    left: -35px;
    top: -10px;
    z-index: 1;
}

.fancy-short-banner-thirteen .bg-wrapper .shape-two {
    left: -60px;
    bottom: -60px;
}

.fancy-short-banner-thirteen .bg-wrapper .shape-text {
    line-height: 24px;
    left: -80px;
    top: -62px;
    transform: rotate(-15deg);
}


/*^^^^^^^^^^^^^^^^^^^^^ Footer Ten ^^^^^^^^^^^^^^^^^^^^^^^^*/

.footer-style-ten {
    background: #1E1E1E;
    padding: 110px 0 100px;
}

.footer-style-ten .footer-nav-link a {
    color: rgba(255, 255, 255, 0.7);
}

.footer-style-ten .footer-nav-link a:hover {
    color: var(--prime-ten);
}

.footer-style-ten .social-icon a {
    width: 40px;
    height: 40px;
    color: #fff;
    font-size: 17px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    background: rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease-in-out;
}

.footer-style-ten .social-icon a:hover {
    background: var(--prime-ten);
}

.footer-style-ten .illustration-one {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 22.35%;
}

.footer-style-ten .illustration-two {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 20.53%;
}


/*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner Eleven ^^^^^^^^^^^^^^^^^^^^^^^^*/

.hero-banner-eleven {
    padding: 0 60px;
}

.hero-banner-eleven .scroll-bar {
    width: 3px;
    height: 80px;
    background: #DDDDDD;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 9;
}

.hero-banner-eleven .scroll-bar:before {
    content: '';
    position: absolute;
    height: 80%;
    width: 100%;
    left: 0;
    bottom: 0;
    background: #000;
    animation: jumpTwo 3s infinite linear;
}

.hero-banner-eleven .social-elemnet {
    position: absolute;
    z-index: 9;
    right: -60px;
    top: 40%;
    transform: rotate(-90deg) translate(0, 0%);
}

.hero-banner-eleven .social-elemnet li {
    color: rgba(0, 0, 0, 0.5);
    margin: 0 2px;
}

.hero-banner-eleven .social-elemnet li a:hover {
    color: #000;
}

.hero-banner-eleven .hero-heading {
    font-size: 120px;
    line-height: 1.08em;
    position: relative;
}

.hero-banner-eleven .hero-heading:before {
    content: url(../images/shape/shape_141.svg);
    position: absolute;
    left: -22px;
    top: -80px;
}

.hero-banner-eleven .hero-heading span {
    color: #fff;
    position: relative;
    text-shadow: 0 0 1px #3c3c3c;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #3c3c3c;
}

.hero-banner-eleven .hero-heading .line-one:before {
    content: '';
    position: absolute;
    width: 95%;
    height: 20px;
    background: rgba(0, 255, 255, 0.45);
    left: 0;
    bottom: 34px;
}

.hero-banner-eleven .hero-heading .line-two:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 20px;
    background: rgba(225, 44, 255, 0.4);
    left: 0;
    bottom: 34px;
}

.hero-banner-eleven .ct-text {
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.4);
}

.hero-banner-eleven .mail-info {
    font-size: 28px;
    color: #000;
    text-decoration: underline;
}

.hero-banner-eleven .mail-info:hover {
    color: var(--orange-one);
}

.hero-banner-eleven .shape-one {
    right: 19%;
    bottom: 0;
}


/*^^^^^^^^^^^^^^^^^^^^^ Portfolio Gallery Two ^^^^^^^^^^^^^^^^^^^^^^^^*/

.isotop-item.item-xl {
    width: 100%;
}

.portfolio-block-two .img-holder {
    position: relative;
    overflow: hidden;
    z-index: 5;
}

.portfolio-block-two .img-holder .overlay-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.portfolio-block-two .img-holder .video-icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
    background: #000;
    border-radius: 50%;
    color: #fff;
    font-size: 38px;
    text-align: center;
    padding-left: 5px;
}

.portfolio-block-two .img-holder .video-icon:hover {
    background: var(--prime-ten);
}

.portfolio-block-two .img-holder .caption {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 40%;
    z-index: 1;
    padding: 15px 5px 40px 50px;
    background: linear-gradient(180deg, rgba(14, 14, 14, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
}

.portfolio-block-two.full-width .img-holder .caption {
    padding: 15px 5px 20px 30px;
}

.portfolio-block-two .img-holder .caption .tag {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 4px;
}

.portfolio-block-two .img-holder .caption .pj-title {
    font-size: 24px;
    letter-spacing: -0.5px;
    color: #fff;
}

.portfolio-block-two .img-holder .zoom-icon {
    display: block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    background: #fff;
    color: #000;
    font-size: 28px;
    text-align: center;
    border-radius: 50%;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.portfolio-block-two .img-holder .zoom-icon:hover {
    background: var(--prime-ten);
    color: #fff;
}

.portfolio-block-two:hover .zoom-icon {
    opacity: 1;
}

.portfolio-block-two .img-holder .caption .pj-title:hover {
    text-decoration: underline;
}

.portfolio-block-two:hover .img-holder .caption {
    height: 100%;
}

.portfolio-block-two:hover .img-holder .img-meta {
    transform: scale3d(1.1, 1.1, 1.0);
}


/*^^^^^^^^^^^^^^^^^^^^^ Feedback Section Twelve ^^^^^^^^^^^^^^^^^^^^^^^^*/

.feedback-block-twelve .icon {
    width: 60px;
    height: 60px;
    background: #000;
}

.feedback-block-twelve p {
    font-size: 36px;
    line-height: 1.88em;
}

.feedback-section-twelve .slider-wrapper {
    margin: 0 -50px;
}

.feedback_slider_nine .item {
    margin: 0 50px;
}

.feedback_slider_nine .slick-dots {
    text-align: center;
    margin-top: 85px;
    padding: 0;
}

.feedback_slider_nine .slick-dots li button {
    width: 10px;
    height: 10px;
    background: #fff;
    border: 1px solid #000;
}

.feedback_slider_nine .slick-dots li.slick-active button {
    background: #000;
    transform: scale(1);
}

.feedback-section-twelve .shape-one {
    left: 2%;
    bottom: 35%;
}

.feedback-section-twelve .shape-two {
    right: 3%;
    bottom: 15%;
}


/*^^^^^^^^^^^^^^^^^^^^^ Partner Section Six ^^^^^^^^^^^^^^^^^^^^^^^^*/

.partner-section-six .top-header {
    padding: 0 0 70px;
    border-bottom: 2px solid #000;
}

.partner-section-six .top-header .title {
    font-size: 16px;
    letter-spacing: 2px;
    padding: 0 10px;
    width: 100%;
}

.partner-section-six .top-header .lines {
    display: inline-block;
    height: 2px;
    width: 100%;
    background: #000;
    flex-grow: 1;
}

.partner-section-six .icon {
    height: 50px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Blog Section Five ^^^^^^^^^^^^^^^^^^^^^^^^*/

.blog-meta-five {
    padding: 75px 40px 50px 60px;
    border: 1px solid #000;
    width: 100%;
    transition: all 0.3s ease-in-out;
}

.blog-meta-five:before {
    content: '';
    position: absolute;
    top: 8px;
    left: 8px;
    width: 100%;
    height: 100%;
    border: 1px solid #000;
    z-index: -1;
    transition: all 0.3s ease-in-out;
}

.blog-meta-five:hover,
.blog-meta-five:hover:before {
    border-color: var(--prime-ten);
}

.blog-meta-five .tag {
    font-size: 12px;
    letter-spacing: 1px;
    border: 1px solid #000;
    padding: 3px 11px;
    color: #000;
}

.blog-meta-five .tag:hover {
    background: #000;
    color: #fff;
}

.blog-meta-five .blog-title {
    font-size: 36px;
    line-height: 1.416em;
}

.blog-meta-five .blog-date a:hover,
.blog-meta-five .blog-title:hover {
    color: #000;
    text-decoration: underline;
}

.blog-meta-five .read-more {
    width: 45px;
    height: 45px;
    text-align: center;
    line-height: 45px;
    background: #000;
    color: #fff;
    font-size: 24px;
}

.blog-meta-five .read-more:hover {
    background: var(--prime-ten);
}

.blog-section-five .btn-holder {
    position: absolute;
    right: 0;
    top: 35px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Blog Section Five ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-short-banner-fourteen .title {
    font-size: 72px;
    line-height: 1.25em;
    letter-spacing: 0;
}

.fancy-short-banner-fourteen .title:before {
    content: url(../images/shape/shape_145.svg);
    position: absolute;
    top: -54px;
    left: -20px;
}

.fancy-short-banner-fourteen .title span {
    color: #fff;
    position: relative;
    text-shadow: 0 0 1px #3c3c3c;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #3c3c3c;
}

.fancy-short-banner-fourteen .title span:before {
    content: '';
    position: absolute;
    width: 95%;
    height: 12px;
    background: rgba(0, 255, 255, 0.45);
    left: 0;
    bottom: 24px;
}

.fancy-short-banner-fourteen .wrapper {
    border-bottom: 2px solid #000;
}


/*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner Twelve ^^^^^^^^^^^^^^^^^^^^^^^^*/

.hero-banner-twelve {
    background: #FBF4EE;
}

.hero-banner-twelve .hero-heading {
    font-size: 90px;
    line-height: 1.05em;
    letter-spacing: -1px;
}

.hero-banner-twelve .media-img {
    position: absolute;
    top: 8.4%;
    right: 9.2%;
    left: 5.8%;
    z-index: 1;
}

.hero-banner-twelve .card-style h4 {
    font-size: 27px;
    letter-spacing: -0.27px;
    margin-bottom: -5px;
}

.hero-banner-twelve .card-style h5 {
    font-size: 20px;
    letter-spacing: -0.2px;
}

.hero-banner-twelve .card-style p {
    font-size: 17px;
    color: rgba(0, 0, 0, 0.5);
    margin: 0;
}

.hero-banner-twelve .card-style .avatar img {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 0 3px white;
    box-shadow: 0 0 0 3px white;
    position: relative;
}

.hero-banner-twelve .card-style .avatar img:nth-child(n+2) {
    z-index: 1;
    margin-left: -10px;
}

.hero-banner-twelve .card-style .avatar_fallback {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    background: #000;
    border: 3px solid #fff;
    line-height: 31px;
    color: #fff;
    font-size: 17px;
    z-index: 5;
    margin-left: -10px;
    text-align: center;
}

.hero-banner-twelve .card-one {
    position: absolute;
    width: 175px;
    height: 99px;
    background: #FFFFFF;
    top: 43%;
    left: -10%;
    z-index: 1;
}

.hero-banner-twelve .card-two {
    position: absolute;
    width: 147px;
    height: 90px;
    background: #FFFFFF;
    top: 16%;
    right: -10%;
    z-index: 1;
}

.hero-banner-twelve .card-three {
    position: absolute;
    width: 189px;
    height: 110px;
    background: #FFFFFF;
    bottom: 17%;
    right: -10%;
    z-index: 1;
}

.hero-banner-twelve .shape-one {
    bottom: -4%;
    right: -2%;
}

.hero-banner-twelve .shape-two {
    bottom: -1%;
    left: -32%;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Thirty Nine ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-thirtyNine {
    background-color: #0F0F0F;
    background-image: url(../images/assets/bg-13.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.fancy-feature-thirtyNine .shape-one {
    left: 0;
    bottom: -5px;
}

.fancy-feature-thirtyNine .shape-two {
    left: 5%;
    top: 15%;
    animation: jumpTwo 3.5s infinite linear;
}

.fancy-feature-thirtyNine .shape-three {
    top: 0;
    right: 0;
}

.fancy-feature-thirtyNine .shape-four {
    right: 5%;
    bottom: 15%;
    animation: jumpThree 3.5s infinite linear;
}

.card-style-seventeen .icon {
    width: 60px;
    height: 60px;
    border: 2px solid var(--prime-eleven);
}

.card-style-seventeen .text-wrapper {
    width: calc(100% - 60px);
}

.card-style-seventeen h4 {
    font-size: 22px;
}

.card-style-seventeen p {
    font-size: 22px;
    color: rgba(255, 255, 255, 0.7);
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Forty ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-forty .bg-wrapper {
    background: url(../images/assets/bg-14.svg);
    background-repeat: repeat-y;
    max-width: 1400px;
    padding: 40px 0 0;
}

.card-style-eighteen {
    padding: 38px 12px 16px;
    border: 1px solid #000;
    border-radius: 10px;
    background: #fff;
    width: 100%;
}

.card-style-eighteen .icon {
    height: 48px;
}

.card-style-eighteen h4 {
    font-size: 22px;
}

.card-style-eighteen h3 {
    font-size: 43px;
}

.card-style-eighteen p {
    color: #505050;
    font-size: 18px;
}

.card-style-eighteen.more-item p {
    font-size: 24px;
    color: #000;
}

.card-style-eighteen:hover {
    background: #fbf4ee;
    border-color: #fbf4ee;
}

.card-style-eighteen.more-item {
    background: var(--prime-eleven);
    border-color: var(--prime-eleven);
}

.card-style-eighteen.more-item:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    top: 6px;
    left: 6px;
    background: #000;
    z-index: -1;
    transition: all 0.3s ease-in-out;
}

.card-style-eighteen.more-item:hover:before {
    top: -6px;
    left: -6px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Forty One ^^^^^^^^^^^^^^^^^^^^^^^^*/

.accordion-style-six .accordion-item {
    background: #FFFFFF;
    border: none;
    border-bottom: 2px solid #000;
    background: #FFFFFF;
    border-radius: 0;
}

.accordion-style-six .accordion-item:last-of-type {
    border: none;
}

.accordion-style-six .accordion-button {
    font-weight: 500;
    font-size: 28px;
    padding: 22px 0;
    box-shadow: none;
    border-radius: 0;
    color: #000;
    background: transparent;
    transition: all 0.3s ease-in-out;
}

.accordion-style-six .accordion-item .accordion-button:not(.collapsed) {
    padding-bottom: 20px;
}

.accordion-style-six .accordion-body {
    padding: 0 40px 13px 0;
}

.accordion-style-six .accordion-body p {
    font-size: 18px;
    line-height: 31px;
}

.accordion-style-six .accordion-button::after {
    content: url(../images/icon/icon_135.svg);
    background-image: none;
    width: auto;
    height: auto;
}

.card-style-nineteen {
    padding: 15px 25px 25px;
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 10px;
    width: 100%;
    min-height: 260px;
}

.card-style-nineteen:before {
    content: '';
    position: absolute;
    top: 6px;
    left: 6px;
    width: 100%;
    height: 100%;
    background: #000;
    border-radius: 10px;
    z-index: -1;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.card-style-nineteen:hover:before {
    opacity: 1;
}

.card-style-nineteen .tag {
    line-height: 22px;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 0 10px;
    background: #000;
    width: fit-content;
}

.card-style-nineteen ul li {
    position: relative;
    font-weight: 500;
    font-size: 15px;
    color: #000;
    margin-top: 12px;
    padding-left: 20px;
}

.card-style-nineteen ul li:before {
    content: url(../images/icon/icon_136.svg);
    position: absolute;
    left: 0;
    top: 0;
}

.card-style-nineteen .tag2 {
    line-height: 20px;
    border: 1px solid #000;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 0 10px;
    width: fit-content;
}

.fancy-feature-fortyOne .wrapper .space-fix {
    transform: translateY(120px);
}

.fancy-feature-fortyOne .wrapper .shape-one {
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.fancy-feature-fortyOne .wrapper .shape-two {
    right: -7%;
    bottom: -3%;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Forty Two ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-fortyTwo {
    background: #FBF4EE;
}

.fancy-feature-fortyTwo .rating-box {
    position: absolute;
    z-index: 1;
    right: 16%;
    top: -8%;
    text-align: center;
    width: 9.5%;
    min-width: 130px;
}

.fancy-feature-fortyTwo .rating-box img {
    animation: rotated 70s infinite linear;
}

.fancy-feature-fortyTwo .rating-box .rate {
    color: #000;
    font-size: 36px;
    line-height: 0.72em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.fancy-feature-fortyTwo .rating-box .rate span {
    font-size: 0.44em;
}

.fancy-feature-fortyTwo .nav-tabs .nav-link {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    border: 1px solid #fff;
    color: #000;
    text-align: center;
    min-width: 60px;
    line-height: 33px;
    padding: 0 20px;
    border-radius: 30px;
    background: #fff;
    margin-right: 10px;
}

.fancy-feature-fortyTwo .nav-tabs .nav-link.active {
    border-color: #000;
}

.fancy-feature-fortyTwo .tab-content>.tab-pane {
    display: block;
    opacity: 0;
    visibility: hidden;
    height: 0;
}

.fancy-feature-fortyTwo .tab-content>.active {
    opacity: 1;
    visibility: visible;
    height: auto;
}

.fancy-feature-fortyTwo .tab-content:before {
    content: '';
    position: absolute;
    top: -50px;
    right: 0;
    left: -33px;
    height: 305px;
    background: url(../images/assets/bg-15.svg);
    background-repeat: repeat-x;
    z-index: -1;
}

.fancy-feature-fortyTwo .slider-wrapper {
    width: calc(1320px + 22vw);
    margin: 0 -20px;
}

.course_slider_one .item {
    margin: 0 20px;
}

.card-style-twenty {
    padding: 16px;
    border: 1px solid #000;
    border-radius: 10px;
    background: #fff;
}

.card-style-twenty:hover {
    background: #fbf1ea;
}

.card-style-twenty .img-meta {
    border: 1px solid #000;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 18px;
}

.card-style-twenty .course-title {
    font-size: 22px;
    color: #000;
    letter-spacing: -1px;
    max-width: 94%;
    margin: 7px 0 15px;
}

.card-style-twenty .course-title:hover {
    text-decoration: underline;
}

.card-style-twenty .rating li {
    font-size: 14px;
    color: #C4C4C4;
    margin-right: 7px;
}

.card-style-twenty .rating li.active {
    color: #FFCE4C;
}

.card-style-twenty .bookmark {
    font-size: 24px;
    color: #000;
}


/*.fancy-feature-fortyTwo .slick-arrow-six {
  position: absolute; 
  right: 0; 
  top: 90px;
}
.slick-arrow-six li {
  font-size: 24px;
  width: 70px;
  height: 70px;
  line-height: 70px;
  color: #000;
  cursor: pointer;
  text-align: center;
  border-radius: 50%;
}
.slick-arrow-six li:hover {
  background: #000;
  color: #fff;
}*/


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Forty Five ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-fortyFive .img-holder .img-one {
    border-radius: 0 0 150px 150px;
}

.fancy-feature-fortyFive .img-holder .img-two {
    border-radius: 150px;
}

.fancy-feature-fortyFive .img-holder .img-three {
    border-radius: 200px 200px 200px 0;
}

.fancy-feature-fortyFive .img-holder .shape-one {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.fancy-feature-fortyFive .shape-two {
    left: 7%;
    bottom: 22%;
}

.fancy-feature-fortyFive .shape-three {
    right: 0;
    bottom: 0;
}


/*^^^^^^^^^^^^^^^^^^^^^ Footer Eleven ^^^^^^^^^^^^^^^^^^^^^^^^*/

.footer-style-eleven {
    padding: 150px 0 0;
}

.theme-basic-footer.footer-style-eleven .footer-title {
    color: rgba(11, 11, 11, 0.35);
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.footer-style-eleven .footer-nav-link a:hover {
    color: var(--prime-two);
}

.footer-style-eleven .form-widget form input {
    border: 1px solid #000;
    border-radius: 6px;
}

.footer-style-eleven .form-widget form button {
    border-radius: 6px;
}

.footer-style-eleven .bottom-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    padding: 20px 0 35px;
    margin-top: 60px;
}

.footer-style-eleven .bottom-footer .footer-nav a {
    font-size: 15px;
    font-weight: 500;
    color: #000;
    margin-right: 22px;
}

.footer-style-eleven .bottom-footer .footer-nav a:hover {
    text-decoration: underline;
}

.footer-style-eleven .bottom-footer .copyright {
    font-size: 15px;
}

.footer-style-eleven .bottom-footer .social-icon a {
    font-size: 19px;
    margin-left: 19px;
    color: #333333;
    transition: all 0.3s ease-in-out;
}

.footer-style-eleven .bottom-footer .social-icon a:hover {
    color: var(--prime-two);
}

.footer-style-eleven .shape-one {
    left: 0;
    top: 45%;
}


/*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner Thirteen ^^^^^^^^^^^^^^^^^^^^^^^^*/

.hero-banner-thirteen {
    background: url(../images/media/img_72.jpg) no-repeat left bottom;
    background-size: cover;
}

.hero-banner-thirteen .hero-heading {
    font-size: 90px;
    line-height: 1.22em;
    letter-spacing: -2px;
}

.hero-banner-thirteen .search-form-bg {
    background: #fff;
    padding: 25px 0;
}

.hero-banner-thirteen .input-block {
    border-right: 1px solid #EEECEC;
    padding: 7px 30px;
}

.hero-banner-thirteen .input-block .title {
    font-size: 14px;
    color: #9098AD;
}

.hero-banner-thirteen .input-block .nice-select {
    width: 100%;
    height: 100%;
    color: #000;
    padding: 0;
    font-size: 20px;
    background: transparent;
    border: none;
}

.hero-banner-thirteen .input-block .nice-select .option {
    font-size: 0.7em;
}

.hero-banner-thirteen .input-block .nice-select:after {
    content: url(../images/icon/icon_137.svg);
    display: inline-block;
    transform: none;
    top: 0;
    border: none;
}

.hero-banner-thirteen .input-block .nice-select .option:hover,
.hero-banner-thirteen .input-block .nice-select .option.focus,
.hero-banner-thirteen .input-block .nice-select .option.selected.focus {
    background: var(--prime-twelve) !important;
    color: #000;
}

.hero-banner-thirteen .input-block button {
    font-size: 20px;
    color: #fff;
    height: 70px;
    background: #252525;
}

.hero-banner-thirteen .input-block button:hover {
    background: var(--prime-twelve);
    color: #000;
}

.hero-banner-thirteen .shape-one {
    bottom: 48%;
    left: 3%;
}

.hero-banner-thirteen .shape-two {
    bottom: -65px;
    left: -85px;
}

.hero-banner-thirteen .text-shape {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Forty Six ^^^^^^^^^^^^^^^^^^^^^^^^*/

.line-bg-wrapper {
    border-left: 1px solid rgba(0, 0, 0, 0.07);
    border-right: 1px solid rgba(0, 0, 0, 0.07);
}

.line-bg-wrapper:before {
    content: '';
    position: absolute;
    width: 25%;
    height: 100%;
    top: 0;
    left: 25%;
    border-left: 1px solid rgba(0, 0, 0, 0.07);
    border-right: 1px solid rgba(0, 0, 0, 0.07);
    z-index: -1;
}

.line-bg-wrapper:after {
    content: '';
    position: absolute;
    width: 25%;
    height: 100%;
    top: 0;
    right: 25%;
    border-right: 1px solid rgba(0, 0, 0, 0.07);
    z-index: -1;
}

.card-style-twentyOne .hover-overlay {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 40px 0;
}

.card-style-twentyOne .hover-overlay:hover {
    background: rgba(0, 0, 0, 0.3);
}

.card-style-twentyOne .hover-overlay [class*="btn"] {
    opacity: 0;
    transform: translateY(20px);
}

.card-style-twentyOne .hover-overlay:hover [class*="btn"] {
    opacity: 1;
    transform: translateY(0);
}

.fancy-feature-fortySix .quote-wrapper .icon {
    width: 105px;
    height: 105px;
    background: #303030;
}

.fancy-feature-fortySix .quote-wrapper p {
    font-size: 64px;
    line-height: 1.421em;
}

.fancy-feature-fortySix .quote-wrapper p span {
    color: var(--prime-ten);
}

.fancy-feature-fortySix .shape-one {
    top: -85px;
    right: 5%;
    z-index: 2;
}

.fancy-feature-fortySix .shape-two {
    width: 36px;
    height: 36px;
    left: -50px;
    top: 25%;
    background: #000000;
}

.fancy-feature-fortySix .shape-three {
    bottom: 28%;
    left: -14%;
}

.fancy-feature-fortySix .shape-four {
    bottom: 7%;
    left: -85px;
    z-index: 2;
}

.fancy-feature-fortySix .shape-five {
    width: 105px;
    height: 105px;
    right: 0;
    bottom: 7%;
    background: #1A1A1A;
}

.fancy-feature-fortySix .shape-six {
    width: 40px;
    height: 40px;
    right: -41px;
    bottom: calc(7% - 40px);
    background: #FFF500;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Forty Seven ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-fortySeven {
    background: #F5F7FC;
}

.fancy-feature-fortySeven .nav-tabs .nav-link {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 3px;
    text-transform: uppercase;
    border: none;
    color: #000;
    line-height: 25px;
    padding: 0 12px;
    border-radius: 0;
    background: transparent;
    margin-left: 40px;
}

.fancy-feature-fortySeven .nav-tabs .nav-link.active {
    background: #000;
    color: #fff;
}

.card-style-twentyTwo .text-meta {
    padding: 32px 35px 25px;
    background: #fff;
}

.card-style-twentyTwo .property-name {
    letter-spacing: -0.48px;
    color: #000;
}

.card-style-twentyTwo p {
    color: rgba(0, 0, 0, 0.4);
}

.card-style-twentyTwo .price {
    font-size: 36px;
    letter-spacing: -0.72px;
}

.card-style-twentyTwo .read-more {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background: #000;
    color: #fff;
    font-size: 22px;
}

.card-style-twentyTwo .read-more:hover {
    background: var(--prime-twelve);
    color: #000;
}

.fancy-feature-fortySeven .shape-one {
    bottom: -45px;
    right: 20%;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Forty Eight ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-fortyEight .img-gallery .video-icon {
    position: absolute;
    width: 100px;
    height: 100px;
    background: #000;
    color: #fff;
    font-size: 40px;
    padding-left: 8px;
    position: absolute;
    top: 57%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.fancy-feature-fortyEight .img-gallery .video-icon:hover {
    background: var(--prime-twelve);
    color: #000;
}

.fancy-feature-fortyEight .img-gallery .shape-one {
    top: 5%;
    right: 10%;
}

.fancy-feature-fortyEight .img-gallery .shape-two {
    bottom: -5%;
    right: 10%;
}

.fancy-feature-fortyEight .img-gallery .shape-three {
    width: 26px;
    height: 26px;
    right: -35px;
    bottom: 16%;
    background: #FFF95F;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Forty Nine ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-fortyNine {
    background: rgba(0, 0, 0, 0.9);
    overflow: hidden;
    margin: 0 30px;
}

.fancy-feature-fortyNine:before {
    content: '';
    position: absolute;
    width: 52%;
    height: 100%;
    top: 0;
    right: 0;
    background: url(../images/media/img_82.jpg) no-repeat center;
    background-size: cover;
    opacity: 0.06;
    z-index: -1;
}

.fancy-feature-fortyNine .title {
    font-size: 100px;
    line-height: 1.3em;
    letter-spacing: -2px;
}

.fancy-feature-fortyNine .title span {
    color: #191919;
    position: relative;
    text-shadow: 0 0 1px #FFF95F;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #FFF95F;
}

.fancy-feature-fortyNine .clip-text {
    position: absolute;
    left: -8%;
    top: -25px;
    font-size: 1060px;
    line-height: 780px;
    font-weight: 900;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    background-image: url(../images/media/img_83.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
}

.fancy-feature-fortyNine .shape-one {
    top: 4%;
    right: 1%;
}

.fancy-feature-fortyNine .more-btn {
    position: absolute;
    right: -18px;
    top: 39%;
}

.fancy-feature-fortyNine .more-btn .arrow {
    position: absolute;
    top: 46%;
    left: 28px;
    transform: translateY(-50%);
}

.fancy-feature-fortyNine .more-btn:hover {
    transform: translateX(8px);
}

.fancy-feature-fortyNine .clip-text-two {
    font-size: 100px;
    letter-spacing: -2px;
    color: #191919;
    position: relative;
    text-shadow: 0 0 1px #fff;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
    position: absolute;
    bottom: -50px;
    right: 10%;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feedback Section Fourteen ^^^^^^^^^^^^^^^^^^^^^^^^*/

.feedback_slider_eleven .slick-dots {
    margin-top: 45px;
    padding: 0;
}

.feedback_slider_eleven .slick-dots li button {
    width: 10px;
    height: 10px;
    background: #fff;
    border: 1px solid #000;
}

.feedback_slider_eleven .slick-dots li.slick-active button {
    background: #000;
    transform: scale(1);
}

.feedback-section-fourteen .rating-box {
    width: 160px;
    height: 160px;
    font-size: 20px;
    line-height: 50px;
    transform: translate(-30px, 15px);
}

.feedback-section-fourteen .rating-box strong {
    font-size: 58px;
    letter-spacing: -1px;
}

.feedback-section-fourteen .img-holder .shape-one {
    top: -62px;
    right: -62px;
    z-index: 1;
}

.feedback-section-fourteen .img-holder .shape-two {
    bottom: 0;
    right: 18%;
}

.feedback-section-fourteen .shape-three {
    bottom: 32%;
    left: 6%;
}

.feedback-section-fourteen .shape-four {
    top: -98px;
    left: 46%;
}


/*^^^^^^^^^^^^^^^^^^^^^ Blog Section Six ^^^^^^^^^^^^^^^^^^^^^^^^*/

.blog-section-six {
    background: #F5F7FC;
}

.blog-section-six .shape-one {
    bottom: 9%;
    left: 9%;
}

.blog-section-six .btn-holder {
    position: absolute;
    right: 0;
    top: 35px;
}

.blog-meta-six {
    padding: 75px 40px 42px 50px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 500px;
}

.blog-meta-six:before {
    content: '';
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.56) 100%);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.3s ease-in-out;
}

.blog-meta-six:hover:before {
    background: #fff;
}

.blog-meta-six .tag {
    font-size: 12px;
    letter-spacing: 1px;
    border: 1px solid #000;
    padding: 3px 11px;
    color: #fff;
    background: #000;
}

.blog-meta-six .blog-title {
    font-size: 32px;
    line-height: 1.56em;
    color: #fff;
}

.blog-meta-six .blog-date {
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
}

.blog-meta-six:hover .blog-date {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.blog-meta-six .blog-date a:hover,
.blog-meta-six .blog-title:hover {
    color: #000;
    text-decoration: underline;
}

.blog-meta-six .read-more {
    width: 45px;
    height: 45px;
    text-align: center;
    line-height: 45px;
    background: var(--prime-twelve);
    color: #000;
    font-size: 24px;
}

.blog-meta-six .read-more:hover {
    color: #fff;
    background: #000;
}

.blog-meta-six .blog-header {
    position: absolute;
    left: 0;
    bottom: -12px;
    width: 75%;
    transition: all 0.3s ease-in-out;
}

.blog-meta-six:hover .blog-header {
    transform: translateY(-100%);
}

.blog-meta-six:hover .blog-header .blog-title {
    color: #000;
}

.blog-sidebar .blog-sidebar-search form {
    height: 65px;
    position: relative;
}

.blog-sidebar .blog-sidebar-search input {
    width: 100%;
    height: 100%;
    border: none;
    font-size: 17px;
    background: #F4F4F4;
    border-radius: 5px;
    padding: 0 60px 0 20px;
}

.blog-sidebar .blog-sidebar-search button {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 60px;
    color: #000;
}

.blog-sidebar .sidebar-title {
    font-size: 28px;
    margin-bottom: 15px;
}

.blog-sidebar .blog-sidebar-category ul li a {
    font-size: 16px;
    line-height: 40px;
    display: block;
    color: #000;
    transition: all 0.3s ease-in-out;
}

.blog-sidebar .blog-sidebar-category ul li a:hover {
    color: var(--prime-ten);
}

.sidebar-recent-news .post {
    width: calc(100% - 80px);
}

.sidebar-recent-news img {
    width: 80px;
}

.sidebar-recent-news .post .title {
    font-size: 22px;
    line-height: 1.36em;
}

.sidebar-recent-news .post .title:hover {
    color: var(--prime-ten);
}

.blog-sidebar .sidebar-banner-add {
    background-size: cover;
    position: relative;
    padding: 20px;
}

.blog-sidebar .sidebar-banner-add .banner-content {
    background: rgba(255, 255, 255, 0.9);
    padding: 40px 50px;
    text-align: center;
}

.blog-sidebar .sidebar-banner-add .banner-content h4 {
    line-height: 31px;
}

.blog-sidebar .sidebar-banner-add .banner-content p {
    margin: 20px 0 15px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Fifty ^^^^^^^^^^^^^^^^^^^^^^^^*/

.accordion-style-seven .accordion-item {
    background: #FFFFFF;
    border: none;
    background: #FFFFFF;
    border-radius: 0;
}

.accordion-style-seven .accordion-item:last-of-type .accordion-button {
    border-bottom: 2px solid #000;
}

.accordion-style-seven .accordion-button {
    font-weight: 500;
    font-size: 24px;
    padding: 33px 20px 33px 35px;
    box-shadow: none;
    border-top: 2px solid #000;
    border-radius: 0 !important;
    color: #000;
    background: transparent;
    transition: all 0.3s ease-in-out;
}

.accordion-style-seven .accordion-item .accordion-button:not(.collapsed) {
    border-color: transparent !important;
    background: #F5F7FC;
    padding-bottom: 24px;
    padding-top: 45px;
}

.accordion-style-seven .accordion-body {
    padding: 0 40px 23px 35px;
    background: #F5F7FC;
}

.accordion-style-seven .accordion-body p {
    font-size: 20px;
    line-height: 38px;
}

.accordion-style-seven .accordion-button::after {
    content: '+';
    font-size: 24px;
    text-align: center;
    font-weight: normal;
    background: transparent;
    width: auto;
    height: auto;
}

.accordion-style-seven .accordion-button:not(.collapsed)::after {
    content: '-';
    transform: none;
}

.fancy-feature-fifty .shape-one {
    bottom: 0;
    left: 25%;
}


/*^^^^^^^^^^^^^^^^^^^^^ Fancy Short Banner Fifteen ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-short-banner-fifteen {
    background: url(../images/media/img_85.jpg) no-repeat center;
    background-size: cover;
}

.fancy-short-banner-fifteen:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}

.fancy-short-banner-fifteen .title {
    font-size: 72px;
    line-height: 1.26em;
}

.fancy-short-banner-fifteen p a {
    color: var(--prime-twelve);
}

.fancy-short-banner-fifteen .form-bg {
    background: var(--prime-twelve);
    padding: 65px 60px 55px;
}

.fancy-short-banner-fifteen form input {
    width: calc(100% - 140px);
    height: 55px;
    font-size: 20px;
    color: #000;
    border: none;
    border-bottom: 2px solid #000;
    background: transparent;
}

.fancy-short-banner-fifteen ::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 1);
}

.fancy-short-banner-fifteen :-ms-input-placeholder {
    color: rgba(0, 0, 0, 1);
}

.fancy-short-banner-fifteen ::placeholder {
    color: rgba(0, 0, 0, 1);
}

.fancy-short-banner-fifteen form button {
    width: 110px;
    height: 55px;
    letter-spacing: 4px;
    background: #000;
}


/*^^^^^^^^^^^^^^^^^^^^^ Footer Twelve ^^^^^^^^^^^^^^^^^^^^^^^^*/

.footer-style-twelve {
    background: #000;
}

.footer-style-twelve:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 80px;
    top: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}

.footer-style-twelve .line-bg-wrapper,
.footer-style-twelve .line-bg-wrapper:before {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-style-twelve .line-bg-wrapper:after {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-style-twelve .social-icon a {
    width: 38px;
    height: 38px;
    border: 1px solid #fff;
    text-align: center;
    line-height: 36px;
    font-size: 18px;
    color: #fff;
    margin-right: 12px;
    transition: all 0.3s ease-in-out;
}

.footer-style-twelve .social-icon a:hover {
    background: var(--prime-twelve);
    border-color: var(--prime-twelve);
    color: #000;
}

.footer-style-twelve .copyright {
    position: absolute;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.5);
    left: 0;
    bottom: 110px;
}

.theme-basic-footer.footer-style-twelve .footer-nav-link a,
.footer-style-twelve p {
    color: rgba(255, 255, 255, 0.7);
}

.theme-basic-footer.footer-style-twelve .footer-nav-link a:hover {
    color: var(--prime-twelve);
    text-decoration: underline;
}

.footer-style-twelve .shape-one {
    right: 3%;
    bottom: 3%;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Fifty One ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-fiftyOne h6 {
    font-size: 20px;
    color: var(--prime-ten);
}

.fancy-feature-fiftyOne .text-lg {
    line-height: 2.08em;
}

.fancy-feature-fiftyOne .shape-one {
    bottom: 0;
    left: 15%;
}

.fancy-feature-fiftyOne .shape-two {
    top: 4%;
    right: 17%;
}

.fancy-feature-fiftyOne .shape-three {
    bottom: -30%;
    left: 5%;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Fifty Two ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-fiftyTwo .video-banner {
    background: url(../images/media/img_86.jpg) no-repeat center;
    background-size: cover;
    border-radius: 30px;
    height: 620px;
}

.fancy-feature-fiftyTwo .video-banner .video-icon {
    width: 84px;
    height: 84px;
    background: #000;
    padding-left: 6px;
}

.fancy-feature-fiftyTwo .video-banner .video-icon:hover {
    background: var(--prime-ten);
}


/*^^^^^^^^^^^^^^^^^^^^^ Team Section Two ^^^^^^^^^^^^^^^^^^^^^^^^*/

.team-section-two .btn-twentyTwo {
    position: absolute;
    right: 0;
    top: 18px;
}

.team-block-two .team-img {
    border-radius: 15px 15px 0 0;
}

.team-block-two .info {
    padding: 15px 15px 18px;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.03);
    border-radius: 15px;
}

.team-section-two .cr-text span {
    color: var(--prime-ten);
}

.team-section-two .shape-one {
    top: 9%;
    right: 39%;
}

.partner-section-six .title {
    font-size: 34px;
    letter-spacing: -0.5px;
}

.partner-section-six .title span {
    color: var(--prime-ten);
}

.partner-section-six .br-name {
    font-size: 34px;
    font-weight: 500;
    letter-spacing: -0.5px;
    color: rgba(0, 28, 62, 0.3);
}


/*^^^^^^^^^^^^^^^^^^^^^ Fancy Short Banner Sixteen ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-short-banner-sixteen .bg-wrapper {
    background: #4E76A1;
    border-radius: 20px;
}

.fancy-short-banner-sixteen .main-title {
    font-size: 42px;
    line-height: 1.16em;
}

.fancy-short-banner-sixteen .sc-title {
    color: var(--prime-ten);
}


/*^^^^^^^^^^^^^^^^^^^^^ Theme Inner Banner One ^^^^^^^^^^^^^^^^^^^^^^^^*/

.inner-banner-one {
    background: url(../images/media/img_87.jpg) no-repeat center;
    background-size: cover;
    padding: 200px 0 135px;
}

.inner-banner-one .title {
    font-size: 72px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Team Section Three ^^^^^^^^^^^^^^^^^^^^^^^^*/

.team-block-three .overlay-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60%;
    padding: 0 0 30px 35px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.544058) 62%, rgba(0, 0, 0, 0.615498) 100%);
    z-index: 1;
}

.team-block-three .name {
    font-size: 24px;
}

.team-block-three .position {
    color: #fff;
    padding: 0 0 30px;
}

.team-block-three .social-icon a {
    width: 36px;
    height: 36px;
    line-height: 34px;
    text-align: center;
    border: 1px solid #fff;
    border-radius: 50%;
    color: #fff;
    font-size: 15px;
    margin-right: 15px;
    transition: all 0.3s ease-in-out;
    opacity: 0;
}

.team-block-three .social-icon a:hover {
    background: var(--prime-twelve);
    border-color: var(--prime-twelve);
    color: #000;
}

.team-block-three .hover-content {
    transform: translateY(34px);
}

.team-block-three:hover .hover-content {
    transform: translateY(0);
}

.team-block-three:hover .social-icon a {
    opacity: 1;
}

.team-section-three .line-bg-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1320px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Fifty Three ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-fiftyThree {
    background: #F5F7FC;
    z-index: 3;
}

.fancy-feature-fiftyThree .shape-one {
    bottom: 7%;
    left: 8%;
}

.fancy-feature-fiftyThree .shape-two {
    width: 105px;
    height: 105px;
    background: #1A1A1A;
    right: 16%;
    bottom: -16px;
}

.fancy-feature-fiftyThree .shape-three {
    width: 40px;
    height: 40px;
    right: 14%;
    bottom: -56px;
    background: #FFF500;
}

.card-style-twentyThree .icon {
    width: 65px;
    height: 65px;
    background: #161616;
    border-radius: 50%;
}

.card-style-twentyThree:hover .icon img {
    transform: rotate(360deg);
}

.card-style-twentyThree .title {
    padding: 26px 0 12px;
}

.card-style-twentyThree ul li {
    font-size: 18px;
    line-height: 39px;
    color: var(--heading);
}


/*^^^^^^^^^^^^^^^^^^^^^ Fancy Short Banner Seventeen ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-short-banner-seventeen .shape-one {
    top: 0;
    left: 9%;
}

.fancy-short-banner-seventeen .shape-two {
    width: 105px;
    height: 105px;
    background: #1A1A1A;
    right: 0;
    bottom: 22%;
}

.fancy-short-banner-seventeen .shape-three {
    width: 40px;
    height: 40px;
    right: -40px;
    bottom: calc(22% - 40px);
    background: #FFF500;
}

.fancy-short-banner-seventeen .shape-four {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 26%;
    top: 8%;
    background: #FFF500;
}


/*^^^^^^^^^^^^^^^^^^^^^ Portfolio Gallery Three ^^^^^^^^^^^^^^^^^^^^^^^^*/

.g-control-nav-two li {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 1px;
    color: #313131;
    display: inline-block;
    padding: 0 8px;
    line-height: 31px;
    border: 2px solid transparent;
    margin: 0 12px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.g-control-nav-two li.is-checked {
    border-color: #313131;
}

.portfolio-block-three .meta-data {
    position: absolute;
    background: #fff;
    padding: 16px 30px;
    bottom: -60px;
    left: 0;
    width: 79%;
}

.portfolio-block-three:hover .meta-data {
    width: 90%;
}

.portfolio-block-three .pj-name {
    font-size: 24px;
    color: #000;
}

.portfolio-block-three .tag {
    font-size: 17px;
    color: #C1C1C1;
}

.portfolio-block-three .expend-btn {
    font-size: 60px;
    font-weight: 300;
    color: #C1C1C1;
}

.portfolio-block-three .expend-btn:hover {
    color: #000;
}

.portfolio-block-three .pj-name:hover {
    text-decoration: underline;
}


/*^^^^^^^^^^^^^^^^^^^^^ Portfolio Gallery Four ^^^^^^^^^^^^^^^^^^^^^^^^*/

.portfolio-block-four .expend-btn {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 25px;
    color: #fff;
    background: rgba(21, 21, 21, 0.6);
    opacity: 0;
}

.portfolio-block-four:hover .expend-btn {
    opacity: 1;
}

.portfolio-block-four .title {
    background: #fff;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04);
    padding: 22px 5px;
}

.portfolio-block-four .pj-name {
    font-size: 22px;
    color: #000;
}

.portfolio-block-four .tag {
    font-size: 17px;
    color: #C1C1C1;
}

.portfolio-block-four:hover .pj-name {
    text-decoration: underline;
}


/*^^^^^^^^^^^^^^^^^^^^^ Portfolio Gallery Five ^^^^^^^^^^^^^^^^^^^^^^^^*/

.hvr-shutter-out .img-meta {
    overflow: hidden;
    z-index: 5;
}

.hvr-shutter-out .img-meta:before {
    content: '';
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    top: 50%;
    bottom: 50%;
    left: -45%;
    right: -45%;
    transform: rotate(45deg);
    transition: all .5s ease-in-out;
    z-index: 2;
}

.hvr-shutter-out .img-meta:hover:before {
    top: -45%;
    bottom: -45%;
}

.hvr-shutter-out .img-meta .caption {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition-delay: 0s;
    z-index: 3;
    transition: all .35s ease;
}

.hvr-shutter-out .img-meta .caption .tag {
    display: inline-block;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1.2px;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 5px;
}

.hvr-shutter-out .img-meta .caption .pj-title {
    font-size: 24px;
    letter-spacing: -0.5px;
    color: #fff;
}

.hvr-shutter-out .img-meta .caption .pj-title:hover {
    text-decoration: underline;
}

.hvr-shutter-out .img-meta:hover .caption {
    opacity: 1;
    transition-delay: 0.105s;
}

.hvr-shutter-out .img-meta .img-meta {
    position: relative;
    z-index: 1;
}

.hvr-shutter-out .img-meta:hover img {
    transform: scale(1.1) rotate(5deg);
}


/*^^^^^^^^^^^^^^^^^^^^^ Portfolio Gallery Seven ^^^^^^^^^^^^^^^^^^^^^^^^*/

.portfolio-block-six .img-meta {
    overflow: hidden;
}

.portfolio-block-six .img-meta .caption {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 40%;
    z-index: 1;
    padding: 15px 5px 40px 30px;
    background: linear-gradient(180deg, rgba(14, 14, 14, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    opacity: 0;
}

.portfolio-block-six .img-meta .caption .tag {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 4px;
}

.portfolio-block-six .img-meta .caption .pj-title {
    font-size: 24px;
    letter-spacing: -0.5px;
    color: #fff;
}

.portfolio-block-six .img-meta .zoom-icon {
    display: block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    background: #fff;
    color: #000;
    font-size: 28px;
    text-align: center;
    border-radius: 50%;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.portfolio-block-six .img-meta .zoom-icon:hover {
    background: var(--prime-ten);
    color: #fff;
}

.portfolio-block-six:hover .zoom-icon {
    opacity: 1;
}

.portfolio-block-six .img-meta .caption .pj-title:hover {
    text-decoration: underline;
}

.portfolio-block-six:hover .img-meta .caption {
    height: 100%;
    opacity: 1;
}

.portfolio-block-six:hover .img-meta img {
    transform: scale3d(1.1, 1.1, 1.0);
}

.g-control-nav-three li {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 1px;
    color: #313131;
    display: inline-block;
    padding: 0 8px;
    line-height: 35px;
    margin: 0 18px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.g-control-nav-three li.is-checked {
    background: #313131;
    color: #fff;
}


/*^^^^^^^^^^^^^^^^^^^^^ Portfolio Gallery Nine ^^^^^^^^^^^^^^^^^^^^^^^^*/

.portfolio-block-seven .img-meta {
    overflow: hidden;
}

.portfolio-block-seven .tag {
    font-size: 17px;
    color: var(--prime-ten);
    padding-left: 35px;
}

.portfolio-block-seven .tag:before {
    content: '';
    position: absolute;
    width: 24px;
    height: 1px;
    background: var(--prime-ten);
    left: 0;
    top: 12px;
}

.portfolio-block-seven .pj-title {
    font-size: 24px;
    color: #000;
    margin-top: 10px;
}

.portfolio-block-seven .pj-title:hover {
    text-decoration: underline;
}

.portfolio-block-seven:hover .img-meta img {
    transform: scale3d(1.1, 1.1, 1.0);
}


/*^^^^^^^^^^^^^^^^^^^^^ Theme Inner Banner Two ^^^^^^^^^^^^^^^^^^^^^^^^*/

.inner-banner-two {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 1;
    padding: 485px 0 95px;
}

.inner-banner-two:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 32%;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    transform: rotate(-180deg);
    z-index: -1;
}

.inner-banner-two:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 58%;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    z-index: -1;
}

.inner-banner-two .title {
    font-size: 90px;
    line-height: 1.22em;
}


/*^^^^^^^^^^^^^^^^^^^^^ Portfolio Details One ^^^^^^^^^^^^^^^^^^^^^^^^*/

.portfolio-details-one .project-info {
    background: #000;
    padding: 55px 0 30px;
}

.portfolio-details-one .project-info .pt-title {
    font-size: 16px;
    letter-spacing: 2px;
    margin-bottom: 6px;
}

.portfolio-details-one .project-info .pt-text {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.5);
}

.portfolio-details-one .project-desctiption p {
    font-size: 20px;
    margin-bottom: 45px;
}

.portfolio-details-one .project-pagination {
    max-width: 1700px;
    padding-left: 12px;
    padding-right: 12px;
}

.portfolio-details-one .project-pagination .hover-content {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(5px);
    opacity: 0;
    visibility: hidden;
}

.portfolio-details-one .project-pagination .arrow-block:hover .hover-content {
    opacity: 1;
    visibility: visible;
}

.portfolio-details-one .project-pagination .pg-title {
    font-size: 80px;
}

.portfolio-details-one .project-pagination .arrow {
    width: 70px;
    height: 70px;
    border: 2px solid #fff;
    color: #fff;
    font-size: 30px;
    line-height: 66px;
    margin-top: 20px;
}

.portfolio-details-one .project-pagination .arrow:hover {
    background: var(--prime-ten);
    border-color: var(--prime-ten);
}


/*^^^^^^^^^^^^^^^^^^^^^ Portfolio Details Two ^^^^^^^^^^^^^^^^^^^^^^^^*/

.portfolio-details-two #gallery-carousel [class*="carousel-control-"] {
    width: 45px;
    height: 45px;
    opacity: 1;
    background: #fff;
    color: #000;
    font-size: 20px;
    top: calc(50% - 22px);
}

.portfolio-details-two .project-desctiption h3 {
    font-size: 36px;
}

.portfolio-details-two .project-desctiption p {
    font-size: 20px;
}

.portfolio-details-two .pt-title {
    font-size: 15px;
    letter-spacing: 1px;
    margin-bottom: 6px;
}

.portfolio-details-two .pt-text {
    font-size: 18px;
    color: #999999;
}

.portfolio-details-two .circle-shape li {
    width: 35px;
    height: 35px;
    border: 2px solid #000;
    margin-right: 14px;
}

.pr-pagination-one .pr-dir {
    font-size: 13px;
    letter-spacing: 2.6px;
    color: rgba(62, 62, 62, 0.5);
}

.pr-pagination-one .pr-name {
    font-size: 22px;
    margin-top: 4px;
}

.pr-pagination-one i {
    font-size: 32px;
    transition: all 0.3s ease-in-out;
    line-height: 1em;
}

.pr-pagination-one a:hover i,
.pr-pagination-one a:hover .pr-name {
    color: var(--prime-ten);
}


/*^^^^^^^^^^^^^^^^^^^^^ Blog Details One ^^^^^^^^^^^^^^^^^^^^^^^^*/

.blog-pubish-date a {
    color: #000;
}

.blog-pubish-date a:hover {
    text-decoration: underline;
}

.blog-details-one .blog-details-content {
    border-bottom: 1px solid #E7E7E7;
    padding-bottom: 8px;
}

.blog-details-one .blog-details-content .image-meta {
    margin-bottom: 54px;
}

.blog-details-one .blog-details-content p {
    font-size: 17px;
    line-height: 32px;
    margin-bottom: 32px;
}

.blog-details-one .blog-details-content h4 {
    color: #303030;
    line-height: 35px;
    margin-bottom: 22px;
}

.blog-details-one .blog-details-content h2 {
    font-size: 36px;
    padding-top: 10px;
    letter-spacing: -1px;
    margin-bottom: 20px;
}

.blog-details-one .blog-details-content .tags li:first-child {
    font-weight: 500;
    color: #000;
    padding-right: 5px;
}

.blog-details-one .blog-details-content .tags li {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    margin-right: 3px;
}

.blog-details-one .blog-details-content .tags li a:hover {
    color: #000;
    text-decoration: underline;
}

.blog-details-one .blog-details-content .share-icon li:first-child {
    font-weight: 500;
    color: #000;
    font-size: 16px;
}

.blog-details-one .blog-details-content .share-icon li a {
    width: 38px;
    height: 38px;
    line-height: 38px;
    border-radius: 50%;
    background: #EEEDED;
    text-align: center;
    color: #000;
    font-size: 16px;
    margin-left: 10px;
    transition: all 0.3s ease-in-out;
}

.blog-details-one .blog-details-content .share-icon li a:hover {
    background: var(--prime-ten);
    color: #fff;
}

.blog-details-one .blog-details-content .bottom-widget {
    padding-top: 25px;
}

.blog-details-one .blog-inner-title {
    font-size: 42px;
    letter-spacing: -1px;
}

.blog-comment-area {
    margin: 100px 0 100px;
}

.blog-comment-area .comment .user-avatar {
    width: 60px;
    height: 60px;
}

.blog-comment-area .comment .comment-text {
    width: calc(100% - 60px);
    padding-left: 25px;
    position: relative;
}

.blog-comment-area .comment .name {
    font-size: 20px;
    letter-spacing: -1px;
}

.blog-comment-area .comment .date {
    font-size: 15px;
    color: #ADADAD;
}

.blog-comment-area .comment p {
    font-size: 16px;
    line-height: 28px;
    padding: 20px 50px 0 0;
}

.blog-comment-area .comment .reply-btn {
    font-size: 13px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0px;
    text-align: center;
    width: 65px;
    line-height: 27px;
    background: var(--prime-ten);
    border-radius: 3px;
    position: absolute;
    right: 0;
    top: 0;
}

.blog-comment-area .comment .reply-btn:hover {
    background: #313131;
}

.blog-comment-area .comment {
    margin-top: 45px;
}

.blog-comment-form {
    border: 2px solid #000;
    border-radius: 10px;
    padding: 65px 60px 70px;
}

.blog-comment-form form label {
    font-size: 14px;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.5);
    display: block;
    padding-bottom: 5px;
}

.blog-comment-form form input {
    display: block;
    font-size: 17px;
    width: 100%;
    height: 60px;
    border: none;
    border-radius: 8px;
    padding: 0 25px;
    background: rgba(0, 0, 0, 0.03);
}

.blog-comment-form form textarea {
    display: block;
    font-size: 17px;
    width: 100%;
    max-width: 100%;
    height: 145px;
    border: none;
    border-radius: 8px;
    padding: 20px 25px;
    background: rgba(0, 0, 0, 0.03);
}

.blog-comment-form.bg-color form textarea {
    height: 140px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Contact Section One ^^^^^^^^^^^^^^^^^^^^^^^^*/

.address-block-two .icon {
    width: 80px;
    height: 80px;
    background: #000;
}

.address-block-two .icon.border {
    border: 1px solid #E6E6E6;
    background: transparent;
}

.address-block-two .title {
    font-size: 24px;
    padding: 20px 0 0;
}

.address-block-two p {
    font-size: 19px;
    line-height: 1.65em;
    color: #878787;
}

.address-block-two p .call {
    font-size: 22px;
    color: var(--prime-ten);
}

.address-block-two p .call:hover {
    text-decoration: underline;
}

.address-block-two p .webaddress {
    color: var(--heading);
    text-decoration: underline;
}

.map-area-one .mapouter {
    height: 600px;
}

.mapouter .gmap_iframe,
.map-area-two .mapouter,
.mapouter .gmap_canvas {
    height: 100%;
    width: 100%;
}


/*^^^^^^^^^^^^^^^^^^^^^ Service Details ^^^^^^^^^^^^^^^^^^^^^^^^*/

.service-sidebar .service-category {
    border: 2px solid #000;
    padding: 25px 25px 20px;
    border-radius: 10px;
}

.service-sidebar .service-category a {
    font-size: 18px;
    display: block;
    line-height: 41px;
    color: var(--text-color);
    transition: all 0.3s ease-in-out;
}

.service-sidebar .service-category a:hover {
    color: var(--prime-ten);
}

.service-sidebar .service-category .current-page a {
    color: var(--prime-ten);
}

.sidebar-quote {
    padding: 22px 30px 28px;
    text-align: center;
    background: var(--prime-ten);
    border-radius: 10px;
}

.sidebar-quote .rating li {
    font-size: 15px;
    color: #FFCC4A;
    margin: 0 2px;
}

.sidebar-quote p {
    font-size: 18px;
    line-height: 29px;
    letter-spacing: -0.2px;
    color: #fff;
    margin: 15px 0 13px;
}

.sidebar-quote .name {
    font-size: 14px;
    color: #fff;
}

.service-sidebar .social-icon a {
    font-size: 18px;
    width: 100%;
    color: #cdcdcd;
    margin-right: 10px;
}

.service-sidebar .social-icon a:hover {
    color: #000;
}

.service-details-meta .main-img-meta {
    border-radius: 15px;
    margin: 55px 0 55px;
}

.service-details-meta ul.list-item li {
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 12px;
    color: #515151;
    position: relative;
    padding-left: 35px;
}

.service-details-meta ul.list-item li:before {
    content: "\f633";
    font-family: bootstrap-icons !important;
    font-size: 12px;
    position: absolute;
    left: 0;
    top: 1px;
    color: var(--prime-ten);
}

.block-style-eight {
    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    border-radius: 7px;
    padding: 22px 35px 18px;
}

.block-style-eight h6 {
    font-size: 20px;
    margin: 0;
}

.block-style-eight .text p {
    font-size: 15px;
    line-height: 24px;
    color: rgba(0, 40, 78, 0.6);
    margin: 5px 0 15px;
}

.block-style-eight .text .details-btn {
    font-size: 15px;
    color: #000;
}

.block-style-eight .text .details-btn:hover {
    text-decoration: underline;
}

.block-style-eight .text {
    width: calc(100% - 100px);
    padding-right: 20px;
}

.block-style-eight .circle_percent {
    width: 100px;
    height: 100px;
    position: relative;
    background: #EFF4F7;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
}

.block-style-eight .circle_inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
    height: 100px;
    clip: rect(0 100px 100px 50px);
}

.block-style-eight .round_per {
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
    height: 100px;
    background: var(--prime-ten);
    clip: rect(0 100px 100px 50px);
    transform: rotate(180deg);
    transition: 1.05s;
}

.block-style-eight .percent_more .circle_inner {
    clip: rect(0 50px 100px 0em);
}

.block-style-eight .percent_more:after {
    position: absolute;
    left: 50px;
    top: 0em;
    right: 0;
    bottom: 0;
    background: var(--prime-ten);
    content: "";
}

.block-style-eight .circle_inbox {
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    background: #fff;
    z-index: 3;
    border-radius: 50%;
}

.block-style-eight .percent_text {
    font-weight: 500;
    color: #000F5C;
    position: absolute;
    font-size: 20px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
}


/*^^^^^^^^^^^^^^^^^^^^^ User Data Page ^^^^^^^^^^^^^^^^^^^^^^^^*/

.user-data-section {
    background: #F6F9FB;
    min-height: 100vh;
    padding: 150px 12px 0;
    z-index: 1;
}

.user-data-section .illustration-one {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 25.44%;
    z-index: -1;
    opacity: 0.3;
}

.user-data-section .illustration-two {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 28.05%;
    z-index: -1;
    opacity: 0.3;
}

.user-data-section .form-wrapper {
    background: #fff;
    width: 700px;
    border-radius: 10px;
    padding: 50px 70px 60px;
    box-shadow: 0px 15px 30px rgba(0, 50, 108, 0.03);
}

.user-data-section .form-wrapper:before {
    content: url(../images/shape/shape_177.svg);
    position: absolute;
    right: -60px;
    top: -60px;
}

.user-data-section ::-webkit-input-placeholder {
    color: #000;
}

.user-data-section :-ms-input-placeholder {
    color: #000;
}

.user-data-section ::placeholder {
    color: #000;
}

.user-data-section .input-group-meta {
    position: relative;
}

.user-data-section .input-group-meta input {
    width: 100%;
    height: 60px;
    font-size: 17px;
    border: 2px solid #000;
    border-radius: 8px;
    padding: 0 52px 0 20px;
    color: #000;
    background: transparent;
}

.user-data-section .input-group-meta label {
    font-size: 14px;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.5);
    display: block;
    margin-bottom: 6px;
}

.user-data-section .input-group-meta .placeholder_icon {
    position: absolute;
    height: 60px;
    top: 30px;
    right: 0;
    bottom: 0;
    width: 50px;
    text-align: center;
    z-index: 1;
    color: rgba(0, 0, 0, 0.45);
    font-size: 17px;
}

.user-data-section .input-group-meta .valid-sign img {
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.user-data-section .input-group-meta input:valid+.valid-sign img {
    opacity: 1;
}

.user-data-section .input-group-meta .placeholder_icon img {
    position: relative;
    top: 50%;
    margin: 0 auto;
    transform: translateY(-50%);
}

.user-data-section .input-group-meta .placeholder_icon span {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: block;
    position: relative;
}

.user-data-section .input-group-meta .placeholder_icon span:before {
    content: '';
    width: 2px;
    height: 26px;
    background: #000;
    position: absolute;
    top: 17px;
    left: 24px;
    transform: rotate(45deg);
    z-index: 5;
    transition: all 0.2s ease-in-out;
}

.user-data-section .input-group-meta .placeholder_icon span.eye-slash:before {
    opacity: 0;
}

.user-data-section .agreement-checkbox label {
    position: relative;
    font-size: 15px;
    color: var(--heading);
    cursor: pointer;
    padding-left: 22px;
    line-height: 18px;
    transition: all 0.1s ease-in-out;
}

.user-data-section .agreement-checkbox label a {
    color: var(--p-color);
    text-decoration: underline;
}

.user-data-section .agreement-checkbox input[type="checkbox"] {
    display: none;
}

.user-data-section .agreement-checkbox label:before {
    content: '';
    width: 13px;
    height: 13px;
    line-height: 11px;
    border-radius: 2px;
    border: 2px solid rgba(0, 0, 0, 0.3);
    font-size: 10px;
    text-align: center;
    position: absolute;
    left: 0;
    top: 3px;
    transition: all 0.1s ease-in-out;
}

.user-data-section .agreement-checkbox input[type="checkbox"]:checked+label:before {
    content: "\f272";
    font-family: bootstrap-icons !important;
    background: #000;
    color: #fff;
    border-color: #000;
}

.user-data-section .agreement-checkbox a {
    position: relative;
    font-size: 15px;
    color: #1E1E1E;
}

.user-data-section .agreement-checkbox a:hover {
    text-decoration: underline;
    color: var(--p-color);
}


/*^^^^^^^^^^^^^^^^^^^^^ Error Page ^^^^^^^^^^^^^^^^^^^^^^^^*/

.error-page-content {
    min-height: 100vh;
    text-align: center;
    position: relative;
    z-index: 5;
    padding: 180px 0 15px;
}

.error-page-content .shape-one {
    bottom: 0;
    right: 0;
}


/*^^^^^^^^^^^^^^^^^^^^^ Theme Inner Banner Three ^^^^^^^^^^^^^^^^^^^^^^^^*/

.inner-banner-three .bg-wrapper {
    background: url(../images/assets/bg-16.svg) no-repeat top center;
    background-size: cover;
    padding: 150px 0 108px;
    position: relative;
    z-index: 1;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Fifty Four ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-fiftyFour .bg-wrapper {
    background: #E8FAF3;
}

.fancy-feature-fiftyFour .shape-one {
    top: 15%;
    left: 9%;
}

.fancy-feature-fiftyFour .shape-two {
    top: 5%;
    right: 35%;
}

.fancy-feature-fiftyFour .shape-three {
    top: 50%;
    right: 4%;
}

.fancy-feature-fiftyFour .shape-four {
    bottom: 20%;
    left: 30%;
}

.card-style-twentyFour {
    padding: 50px 30px 10px;
    border-radius: 7px;
}

.card-style-twentyFour .icon {
    height: 42px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Team Section Four ^^^^^^^^^^^^^^^^^^^^^^^^*/

.team-block-three .team-img {
    border-radius: 15px
}

.team-block-three .info {
    padding: 38px 15px 5px;
}

.team-block-three:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 2px;
    background: #000;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.team-block-three:hover:before {
    opacity: 1;
}


/*^^^^^^^^^^^^^^^^^^^^^ Contact Section Two ^^^^^^^^^^^^^^^^^^^^^^^^*/

.contact-section-two {
    background: #a2d2ff;
    border-radius: 20px;
    padding: 60px 80px 85px;
    box-shadow: 0px 35px 70px rgba(0, 104, 31, 0.05);
}

.form-style-three .input-group-meta label {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 7px;
}

.form-style-three .input-group-meta input {
    width: 100%;
    height: 60px;
    border-radius: 8px;
    border: 2px solid #000;
    padding: 0 5px 0 20px;
    color: #000;
    font-size: 17px;
}

.form-style-three .input-group-meta textarea {
    width: 100%;
    max-width: 100%;
    height: 188px;
    border-radius: 8px;
    border: 2px solid #000;
    padding: 15px 5px 15px 20px;
    color: #000;
    font-size: 17px;
}

.form-style-three ::-webkit-input-placeholder {
    color: #000;
}

.form-style-three :-ms-input-placeholder {
    color: #000;
}

.form-style-three ::placeholder {
    color: #000;
}

.address-block-three .icon {
    width: 35px;
    margin-top: 7px;
}

.address-block-three .icon img {
    margin-left: auto;
}

.address-block-three .text {
    width: calc(100% - 35px);
    padding-left: 30px;
}

.address-block-three .title {
    font-size: 26px;
    margin-bottom: 8px;
}

.address-block-three p {
    font-size: 18px;
    line-height: 1.65em;
    color: #878787;
    margin-bottom: 0;
}

.address-block-three p .call {
    font-size: 22px;
    color: var(--prime-two);
    margin-top: 3px;
}

.address-block-three p .call:hover {
    text-decoration: underline;
}

.address-block-three p .webaddress {
    color: #000;
    text-decoration: underline;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Fifty Five ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-fiftyFive {
    background: #F3F7F8;
}

.fancy-feature-fiftyFive .title-line .count {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #000;
    font-size: 20px;
}

.fancy-feature-fiftyFive .title-line .solid-line {
    height: 2px;
    width: calc(100% - 70px);
    background: #000;
}

.fancy-feature-fiftyFive .shape-one {
    width: 126px;
    height: 126px;
    background: #FFC961;
    top: 14%;
    left: -80px;
    animation: jumpThree 3s infinite linear;
}

.fancy-feature-fiftyFive .shape-two {
    width: 300px;
    height: 300px;
    background: #FF35D9;
    bottom: 38%;
    right: -200px;
    animation: jumpTwo 3s infinite linear;
}

.fancy-feature-fiftyFive .shape-three {
    width: 126px;
    height: 126px;
    background: #57F2CF;
    bottom: 4%;
    left: -80px;
    animation: jumpTwo 3s infinite linear;
}


/*^^^^^^^^^^^^^^^^^^^^^ Blog Section Seven ^^^^^^^^^^^^^^^^^^^^^^^^*/

.blog-meta-seven {
    border: 1px solid #E7E7E7;
    border-radius: 10px;
    padding: 15px;
    width: 100%;
}

.blog-meta-seven .post-tag {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.5);
    letter-spacing: 1px;
}

.blog-meta-seven .blog-title {
    line-height: 1.5em;
}

.blog-meta-seven .blog-title:hover,
.blog-meta-seven .read-btn {
    color: var(--prime-one);
}

.blog-meta-seven .read-btn .bi {
    font-size: 22px;
}

.blog-meta-seven .read-btn:hover {
    color: var(--prime-two);
}


/*^^^^^^^^^^^^^^^^^^^^^ Hosting Compare Table ^^^^^^^^^^^^^^^^^^^^^^^^*/

.hosting-compare-table table thead th {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    border: 2px solid #000;
    padding: 22px 15px;
    width: 18%;
}

.hosting-compare-table table thead th:first-child {
    border: none;
}

.hosting-compare-table table tbody th {
    font-size: 17px;
    font-weight: normal;
    border: none;
    width: 28%;
    padding: 18px 0;
}

.hosting-compare-table table tbody td {
    border: none;
    border-left: 2px solid #000;
    border-right: 2px solid #000;
}

.hosting-compare-table table tbody tr:first-child th,
.hosting-compare-table table tbody tr:first-child td {
    padding-top: 85px;
}

.hosting-compare-table table td {
    vertical-align: middle;
    padding: 18px 10px;
}

.hosting-compare-table table tbody {
    border: none !important;
}

.hosting-compare-table table tbody tr:last-child td {
    padding-bottom: 85px;
    border-bottom: 2px solid #000;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Fifty Six ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-fiftySix .bg-wrapper {
    background: #F1FCFF;
}

.card-style-twentyFive {
    width: 100%;
    border: 2px solid #fff;
    padding: 52px 45px 40px 35px;
    background: #fff;
    transition: all 0.3s ease-in-out;
}

.card-style-twentyFive:hover {
    border-color: #000;
}

.card-style-twentyFive .icon {
    height: 45px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Fifty Seven ^^^^^^^^^^^^^^^^^^^^^^^^*/

@keyframes ripple {
    0% {
        box-shadow: 0 0 0 .1rem rgba(255, 197, 49, 0.1);
    }
    100% {
        box-shadow: 0 0 0 0.4rem rgba(255, 197, 49, 0.3);
    }
}

.fancy-feature-fiftySeven .location {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #FFC531;
    animation: ripple 3s linear infinite;
    cursor: pointer;
}

.fancy-feature-fiftySeven .location::before,
.fancy-feature-fiftySeven .location::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    animation: inherit;
    animation-delay: 1s;
}

.fancy-feature-fiftySeven .location::after {
    animation-delay: 3s;
}

.fancy-feature-fiftySeven .location span {
    width: 155px;
    font-size: 17px;
    line-height: 20px;
    position: absolute;
    font-weight: 500;
    color: #000;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.fancy-feature-fiftySeven .location:hover span {
    opacity: 1;
}

.fancy-feature-fiftySeven .location.one {
    top: 20%;
    left: 37%;
}

.fancy-feature-fiftySeven .location.two {
    top: 38%;
    left: 22%;
}

.fancy-feature-fiftySeven .location.three {
    top: 52%;
    left: 46%;
}

.fancy-feature-fiftySeven .location.four {
    top: 76%;
    left: 13%;
}

.fancy-feature-fiftySeven .location.five {
    top: 18%;
    left: 65%;
}

.fancy-feature-fiftySeven .location.six {
    top: 20%;
    left: 89%;
}

.fancy-feature-fiftySeven .location.seven {
    top: 65%;
    left: 72%;
}


/*^^^^^^^^^^^^^^^^^^^^^ Contact Section Three ^^^^^^^^^^^^^^^^^^^^^^^^*/

.contact-section-three .address-wrapper {
    background: #EEFBFF;
    overflow: hidden;
}

.contact-section-three .address-wrapper:before {
    content: '';
    position: absolute;
    height: 200px;
    width: 120%;
    bottom: -95px;
    left: -2%;
    background: #fff;
    z-index: -1;
    transform: rotate(-4deg);
}

.address-block-four {
    background: #fff;
    padding: 65px 10px 45px;
}

.address-block-four .icon {
    height: 40px;
}

.address-block-four h5 {
    font-size: 26px;
}

.address-block-four p {
    line-height: 32px;
    color: #878787;
}

.form-style-four .input-bg-group {
    border: 2px solid #000;
    background: #fff;
    padding: 45px 60px 50px;
    margin: 0 10px 0 0;
}

.form-style-four .input-bg-group:after {
    content: '';
    position: absolute;
    top: 8px;
    right: -10px;
    bottom: -10px;
    left: 8px;
    background: #000;
    z-index: -1;
}

.form-style-four .input-group-meta label {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.5);
}

.form-style-four .input-group-meta input {
    width: 100%;
    height: 48px;
    border: none;
    border-bottom: 2px solid #000;
    padding: 0 0 11px;
    color: #000;
    font-size: 17px;
}

.form-style-four .input-group-meta textarea {
    width: 100%;
    max-width: 100%;
    height: 160px;
    border: none;
    border-bottom: 2px solid #000;
    padding: 0;
    color: #000;
    font-size: 17px;
}

.form-style-four ::-webkit-input-placeholder {
    color: #000;
}

.form-style-four :-ms-input-placeholder {
    color: #000;
}

.form-style-four ::placeholder {
    color: #000;
}

.form-style-four .btn-one {
    line-height: 58px;
    color: #000;
    background: var(--prime-six);
}

.form-style-four .btn-one:hover {
    color: #fff;
    background: #000;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Fifty Eight ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-fiftyEight .shape-one {
    bottom: 0;
    left: 40px;
}

.feedback-block-thirteen .icon {
    width: 50px;
    height: 50px;
    background: #000;
}

.feedback-block-thirteen p {
    font-size: 36px;
    line-height: 1.58em;
}

.fancy-feature-fiftyEight .rating {
    width: 100px;
    height: 100px;
    background: #FF67D4;
    position: absolute;
    right: 15px;
    bottom: 15px;
}

.fancy-feature-fiftyEight .rating-value {
    font-size: 38px;
    margin-bottom: -10px;
}

.fancy-feature-fiftyEight .customer-data {
    width: 150px;
    height: 150px;
    background: #CFFF69;
    position: absolute;
    right: -70px;
    bottom: -70px;
}

.fancy-feature-fiftyEight .customer-data .amount {
    font-size: 45px;
    margin-bottom: -8px;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Fifty Nine ^^^^^^^^^^^^^^^^^^^^^^^^*/

.fancy-feature-fiftyNine .shape-one {
    left: 0;
    top: 4%;
}

.fancy-feature-fiftyNine .card-wrapper {
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
}

.card-style-twentySix .icon {
    width: 75px;
    height: 75px;
    background: #000;
}


/*^^^^^^^^^^^^^^^^^^^^^ Contact Section Four ^^^^^^^^^^^^^^^^^^^^^^^^*/

.contact-section-four .form-wrapper {
    background: #000;
}

.form-style-five {
    max-width: 660px;
    padding-left: 100px;
}

.form-style-five .form-title {
    font-size: 62px;
    color: #fff;
}

.form-style-five label {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 5px;
}

.form-style-five .input-group-meta input,
.form-style-five .input-group-meta textarea {
    display: block;
    width: 100% !important;
    max-width: 100%;
    border: none;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.15);
    color: #fff;
    padding: 0 20px;
    font-size: 16px;
}

.form-style-five .input-group-meta input {
    height: 60px;
}

.form-style-five .input-group-meta textarea {
    min-height: 165px;
    padding: 15px 20px;
}

.form-style-five ::-webkit-input-placeholder {
    color: #fff;
}

.form-style-five :-ms-input-placeholder {
    color: #fff;
}

.form-style-five ::placeholder {
    color: #fff;
}

.form-style-five [class*="btn-"] {
    font-size: 14px;
    letter-spacing: 0.88px;
}

.form-style-five [class*="btn-"]:hover {
    background: var(--prime-eight);
    color: #000;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    filter: invert(100%);
    /* color:red; */
}